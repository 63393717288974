import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import * as API from 'api/plans'
import {
  makeErrorMessage,
  UNAUTHORIZED_ERROR_STATUS_CODE,
  UNREACHABLE_ERROR_STATUS_CODE,
  NOT_FOUND_ERROR_STATUS_CODE,
} from 'api/utils'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import { validateToken } from 'slices/sessionSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import * as Spinner from 'slices/spinnerSlice'
import { commonParams, sleep } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type DailyPlanData = {
  scheduleTypeId: number
  scheduleTypeName: string
  plan: number
  target: number
}
export type DailyPlan = { data: DailyPlanData[]; workDate: string; workerCount: number }

type PlanListData = Omit<API.PlanListResponse, 'dailyPlans'> & {
  dailyPlans: DailyPlan[]
}

type PlanState = {
  isRequesting: boolean
  errorMessage: string
  plans?: API.PlanResponse
  planList?: PlanListData
  productivityAdjustments?: API.productivityAdjustments[]
  scheduleTruncated: boolean
}

export type UpdateProductivityPlanWithScheduleTypeId = API.UpdateProductivityPlan & {
  scheduleTypeId: number
}

const initialState: PlanState = {
  isRequesting: false,
  errorMessage: '',
  plans: undefined,
  planList: undefined,
  productivityAdjustments: undefined,
  scheduleTruncated: false,
}

export const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.errorMessage = action.payload.errorMessage
      state.plans = initialState.plans
      state.planList = initialState.planList
      state.productivityAdjustments = initialState.productivityAdjustments
    },
    apiFailureUnclearPlans: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.errorMessage = action.payload.errorMessage
    },
    getPlanListSuccess: (state, action: PayloadAction<API.PlanListResponse>) => {
      const dailyPlans = action.payload.dailyPlans
        .filter(dailyPlan => dailyPlan.workerCount > 0)
        .map(dailyPlan => {
          const data = action.payload.targetScheduleTypes.scheduleTypeIds.map((id, i) => ({
            scheduleTypeId: id,
            scheduleTypeName: action.payload.targetScheduleTypes.scheduleTypeNames[i],
            target: dailyPlan.targets[i],
            plan: dailyPlan.plans[i],
          }))
          return { ...dailyPlan, data }
        })
      state.planList = { ...action.payload, dailyPlans }
    },
    updatePlanSuccess: (state, action: PayloadAction<API.UpdatePlanResponse>) => {
      state.scheduleTruncated = !!action.payload.results?.some(w => w.truncated)
    },
    getPlanSuccess: (state, action: PayloadAction<API.PlanResponse>) => {
      const assignedGroups = _.sortBy(
        action.payload.groups.filter(group => group.groupName !== '未所属'),
        'groupName'
      )
      const unassignedGroup = action.payload.groups.find(group => group.groupName === '未所属')
      const sortedGroups = (unassignedGroup ? [...assignedGroups, unassignedGroup] : assignedGroups).map(group => ({
        ...group,
        workersPlan: _.sortBy(group.workersPlan, 'workerName'),
      }))
      state.plans = {
        ...action.payload,
        groups: sortedGroups,
      }
    },
    updatePlanAsyncSuccess: (state, action: PayloadAction<API.PlanCreateStatusResponse>) => {
      state.scheduleTruncated = !!action.payload.results?.some(w => w.truncated)
    },
    getProductivityPlanSuccess: (state, action: PayloadAction<API.ProductivityPlanResponse>) => {
      state.productivityAdjustments = action.payload.productivityAdjustments
    },
    requestFinished: state => {
      state.isRequesting = false
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  apiFailureUnclearPlans,
  getPlanListSuccess,
  getPlanSuccess,
  updatePlanSuccess,
  updatePlanAsyncSuccess,
  getProductivityPlanSuccess,
  requestFinished,
} = plansSlice.actions

// Planデータを日付指定で取得
// GET /tenants/{tenantId}/workspaces/{workspaceId}/work-date/{workDate}/plan
export const getPlanByDate =
  (workspaceId: number, workDate: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getPlanByDate(commonParams(getState), workspaceId, workDate)
      .then((res: API.PlanResponse) => dispatch(getPlanSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => {
        dispatch(requestFinished())
        dispatch(Spinner.stop())
      })
  }

// 取得したPlanデータでシフトがあるかのチェックをする
export const getPlanByDateWithShiftCheck =
  (workspaceId: number, workDate: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getPlanByDate(commonParams(getState), workspaceId, workDate)
      .then((res: API.PlanResponse) => {
        const workersPlan = res.groups.flatMap(group => group.workersPlan)
        if (workersPlan.some(plan => plan.workShifts.some(shift => shift && shift > 0))) {
          dispatch(getPlanSuccess(res))
          return
        }

        // shiftのデータが存在しない場合
        dispatch(apiFailureUnclearPlans({ errorMessage: NOT_FOUND_ERROR_STATUS_CODE }))
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        // 作業計画画面から別の日付に移動する際に選択先の日付が存在しない場合､ エラー処理が実行される
        // plans が空になると作業計画画面が nodata になるのでここでは getPlanByDate がエラーでも plans を空にしない
        dispatch(apiFailureUnclearPlans({ errorMessage: errorCode }))
      })
      .finally(() => {
        dispatch(requestFinished())
        dispatch(Spinner.stop())
      })
  }

// Planデータを日付、workerId指定で取得
// GET /tenants/{tenantId}/workspaces/{workspaceId}/work-date/{workDate}/workers/{workerId}/plan
export const getPlanByWorkerId =
  (workspaceId: number, workDate: string, workerId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getPlanByWorkerId(commonParams(getState), workspaceId, workDate, workerId)
      .then((res: API.PlanResponse) => dispatch(getPlanSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => {
        dispatch(requestFinished())
        dispatch(Spinner.stop())
      })
  }

// Planデータの一覧取得
// GET /tenants/{tenantId}/workspaces/{workspaceId}/plan-list{QueryString}
export const getPlanList =
  (workspaceId: number, from?: string, to?: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getPlanList(commonParams(getState), workspaceId, from, to)
      .then((res: API.PlanListResponse) => dispatch(getPlanListSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => {
        dispatch(requestFinished())
        dispatch(Spinner.stop())
      })
  }

// Planデータ作成、更新
// PUT /tenants/{tenantId}/workspaces/{workspaceId}/work-date/{workDate}/workers/{workerId}/plan
export const updatePlan =
  (workspaceId: number, workDate: string, workId: number, data: API.UpdatePlan): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updatePlan(commonParams(getState), workspaceId, workDate, workId, data)
      .then((res: API.UpdatePlanResponse) => dispatch(updatePlanSuccess(res)))
      .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
      .finally(() => {
        dispatch(requestFinished())
        dispatch(Spinner.stop())
      })
  }

// Planデータ一括作成、更新
// PUT /tenants/{tenantId}/workspaces/{workspaceId}/work-date/{workDate}/plan/bulk-create
// GET /tenants/{tenantId}/workspaces/{workspaceId}/work-date/{workDate}/plan/{requestId}/bulk-create-status
export const updatePlanBulkCreate =
  (workspaceId: number, workDate: string, data: API.UpdatePlanBulk): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }
    dispatch(Spinner.start())

    try {
      const { requestId } = await API.updatePlanAsync(commonParams(getState), workspaceId, workDate, data)
      if (!requestId) {
        return
      }

      const callGetPlanCreateStatus = async () => {
        const updateStatus = await API.getPlanCreateStatus(commonParams(getState), workspaceId, workDate, requestId)
        if (updateStatus.isCompleted) {
          dispatch(updatePlanAsyncSuccess(updateStatus))
          return
        }
        const retryInterval = updateStatus.retryInterval
        retryInterval > 0 && (await sleep(retryInterval))
        await callGetPlanCreateStatus()
      }

      await callGetPlanCreateStatus()
    } catch (err) {
      const errorCode = makeErrorMessage(err as AxiosError)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailureUnclearPlans({ errorMessage: errorCode }))
    } finally {
      dispatch(requestFinished())
      dispatch(Spinner.stop())
    }
  }

// ProductivityPlanデータの一覧取得
// GET /tenants/{tenantId}/workspaces/{workspaceId}/work-date/{workDate}/productivity-plan
export const getProductivityPlan =
  (workspaceId: number, workDate: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getProductivityPlan(commonParams(getState), workspaceId, workDate)
      .then((res: API.ProductivityPlanResponse) => dispatch(getProductivityPlanSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => {
        dispatch(requestFinished())
        dispatch(Spinner.stop())
      })
  }

// ProductivityPlanデータ作成、更新
// PUT /tenants/{tenantId}/workspaces/{workspaceId}/work-date/{workDate}/schedule-types/{scheduleTypeId}/productivity-plan
export const updateProductivityPlan =
  (
    workspaceId: number,
    workDate: string,
    scheduleTypeId: number,
    data: API.UpdateProductivityPlan,
    execRequestFinished: boolean = true
  ): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    await API.updateProductivityPlan(commonParams(getState), workspaceId, workDate, scheduleTypeId, data)
      .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
      .finally(() => {
        if (execRequestFinished) {
          dispatch(requestFinished())
        }
        dispatch(Spinner.stop())
      })
  }

// PlanとProductivityPlanを更新
export const updateProductivityPlanAndPlanBulkCreate =
  (
    workspaceId: number,
    workDate: string,
    planData: API.UpdatePlanBulk | undefined,
    productivityPlanData: UpdateProductivityPlanWithScheduleTypeId[] | undefined
  ): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    // productivity-plan
    if (productivityPlanData) {
      await Promise.all(
        productivityPlanData.map(async data =>
          dispatch(
            updateProductivityPlan(
              workspaceId,
              workDate,
              data.scheduleTypeId,
              {
                productivityAdjustment: data.productivityAdjustment,
              },
              false
            )
          )
        )
      )
    }

    if (!planData) {
      dispatch(requestFinished())
      return
    }

    dispatch(updatePlanBulkCreate(workspaceId, workDate, planData))
  }

// 作業種別の目標数作成、更新
// PUT /tenants/{tenantId}/workspaces/{workspaceId}/target-value
export const updateTargetValue =
  (workspaceId: number, data: API.UpdateTargetValue): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateTargetValue(commonParams(getState), workspaceId, data)

      .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
      .finally(() => {
        dispatch(requestFinished())
        dispatch(Spinner.stop())
      })
  }

export const importShift =
  (fileName: string, csvContent: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    try {
      const response = await API.shiftUploadUrl(commonParams(getState), fileName)
      await API.putUploadUrl(response.uploadUrl, csvContent)
    } catch (error) {
      const errorCode = makeErrorMessage(error as AxiosError)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    } finally {
      dispatch(requestFinished())
      dispatch(Spinner.stop())
    }
  }
export const selectPlansStatus = (state: RootState) => ({ ...state.plans })

export default plansSlice.reducer
