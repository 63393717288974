import axios from 'axios'

import type { UserData } from 'api/users'
import type { AuthToken } from 'api/utils'

import type { CommonParams } from 'slices/utils'

export type LoginParams = {
  email: string
  password: string
  application?: string
  version?: string
}

type ActivateParams = {
  email: string
  password: string
  name: string
  session: string
}

type RefreshParams = {
  refreshToken: string
  application?: string
  version?: string
}

export type SessionResponse = {
  newPasswordRequired: boolean
  session: string | null
  idToken: string | null
  accessToken: string | null
  refreshToken: string | null
  expirationDate: string | null
  shouldUpdate: boolean
  user: UserData
}

export type RequestPasswordResetParams = {
  email: string
}

export type ConfirmPasswordResetParams = {
  password: string
  email: string
  code: string
}

export type UpdateUserPasswordParams = {
  oldPassword: string
  newPassword: string
}

export const login = (data: LoginParams): Promise<SessionResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/session`
    axios
      .post(url, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const logout = (token: AuthToken) =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/session`
    const headers = {
      Authorization: token.idToken,
      'X-Access-Authorization': token.accessToken,
    }
    axios
      .delete(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const activate = (data: ActivateParams): Promise<SessionResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/session/activate`
    axios
      .post(url, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const refresh = (data: RefreshParams): Promise<SessionResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/session/refresh`
    axios
      .post(url, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const requestPasswordReset = (data: RequestPasswordResetParams): Promise<void> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/session/request-password-reset`
    axios
      .post(url, data)
      .then(() => resolve())
      .catch(error => reject(error))
  })

export const confirmPasswordReset = (data: ConfirmPasswordResetParams): Promise<void> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/session/confirm-password-reset`
    axios
      .post(url, data)
      .then(() => resolve())
      .catch(error => reject(error))
  })

export const updateUserPassword = (params: CommonParams, data: UpdateUserPasswordParams): Promise<SessionResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/session/password`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .put(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
