import dayjs from 'dayjs'
import utcPlugin from 'dayjs/plugin/utc'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ModalBody, CardBody, CardText, Label, Col } from 'reactstrap'

import { PlanUpdateModeTypes } from 'api/plans'
import type { UpdatePlanBulk } from 'api/plans'

import { updatePlanBulkCreate, selectPlansStatus } from 'slices/plansSlice'

import { TimeSelect, SelectBoxFormat, CustomModal } from 'components/common'
import { roundedMoment, ColumnSizes } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'
import usePlans from 'hooks/usePlans'

dayjs.extend(utcPlugin)

type AdjustWorkerPlan = {
  workerId: number
  workShift: (number | null)[]
  workType: number[]
}

type Props = {
  isOpen: boolean
  workspaceId: number
  workerIds: number[]
  workersPlan: AdjustWorkerPlan[]
  workDate: string
  onCancel: () => void
  onSuccess: () => void
  onError: () => void
}

const ADJUST_START_AT_ID = 1
const ADJUST_END_AT_ID = 2
const adjustPatternList = [
  { key: ADJUST_START_AT_ID, value: '開始時間を指定時間にする' },
  { key: ADJUST_END_AT_ID, value: '終了時間を指定時間にする' },
]

const ShiftAdjustment: React.FC<Props> = props => {
  const { isOpen, workspaceId, workerIds, onCancel, onSuccess, onError, /*schedules, */ workersPlan, workDate } = props
  const [submitted, setSubmitted] = React.useState(false)
  const [selectedAdjustPatternId, setSelectedAdjustPatternId] = React.useState<number>(ADJUST_END_AT_ID)
  const [selectedHour, setSelectedHour] = React.useState('00')
  const [selectedMinute, setSelectedMinute] = React.useState('00')

  const { isRequesting, errorMessage } = useSelector(selectPlansStatus, shallowEqual)

  const dispatch = useDispatch()
  const { planStartDateTime } = usePlans()

  const { businessStartTime, businessEndTime } = useBusinessTime()

  React.useEffect(() => {
    setSelectedAdjustPatternId(ADJUST_END_AT_ID)
    const { hour, minute } = roundedMoment(true)
    setSelectedHour(hour)
    setSelectedMinute(minute)
  }, [isOpen])

  const requestPlans = React.useMemo(() => {
    const targetPlans = workersPlan.filter(w => workerIds.includes(w.workerId))
    const startDateTime = new Date(workDate)
    startDateTime.setHours(Number(selectedHour))
    startDateTime.setMinutes(Number(selectedMinute))
    const diffTimeMin = (startDateTime.getTime() - planStartDateTime.getTime()) / (60 * 1000) // 差分を分単位にする
    const timeIndex = diffTimeMin / 5 // 5分区切りに計算

    // 設定済みのシフト時間の最後Indexを検索して返す
    // 開始時間を設定する場合は配列の最初から、終了時間を設定する場合は配列の最後から検索する
    // 開始時間がシフトの最後の時間より遅い場合はシフトの最後、終了時間がシフトの最初の時間よりも早い場合はシフトの最初のIndexを返す
    const getLastShiftIndex = (startIndex: number, adjustPatternId: number, workShift: (number | null)[]) => {
      if (adjustPatternId === ADJUST_START_AT_ID) {
        for (let i = startIndex; i < workShift.length; i += 3) {
          if (i + 3 < workShift.length && workShift[i] && !workShift[i + 3]) {
            return i + 3
          }
        }

        // 設定済みシフト時間が見つからなかった場合、最後のシフト時間のIndexを検索する
        for (let i = startIndex; i >= 0; i -= 3) {
          if (workShift[i]) {
            return i
          }
        }
        return workShift.length - 1
      }

      for (let i = startIndex; i >= 0; i -= 3) {
        if (i - 3 >= 0 && workShift[i] && !workShift[i - 3]) {
          return i
        }
      }

      // 設定済みシフト時間が見つからなかった場合、最初のシフト時間のIndexを検索する
      for (let i = startIndex; i < workShift.length; i += 3) {
        if (workShift[i]) {
          return i
        }
      }

      return 0
    }

    const updatePlanData = targetPlans.map(plan => {
      const lastIndex = getLastShiftIndex(timeIndex, selectedAdjustPatternId, plan.workShift)

      const workShifts =
        selectedAdjustPatternId === ADJUST_START_AT_ID
          ? plan.workShift.map((shift, i) => {
              // 開始時間が設定された場合
              // startIndexは開始時間が設定されたIndex
              // endIndexは設定済みシフトの最後の時間のIndex
              const startIndex = timeIndex < lastIndex ? timeIndex : lastIndex
              const endIndex = timeIndex < lastIndex ? lastIndex : lastIndex + 3
              if (i < startIndex) {
                // 開始時間設定(startIndex)以前の場合
                return 0
              } else if (i >= endIndex) {
                // 設定済みのシフト時間(endIndex)より以降の場合
                return shift
              } else if (shift === 0) {
                // 開始時間設定(startIndex)と設定済みのシフト時間(endIndex)の間の場合
                return workspaceId
              }
              return shift
            })
          : plan.workShift.reduceRight(
              (acc, shift, i) => {
                // 終了時間が設定された場合
                // workShift配列の最後のindexからチェックする
                // startIndexは終了時間が設定されたIndexとなりendIndexよりも大きい値となる
                // endIndexは設定済みシフトの最後の時間
                const startIndex = timeIndex > lastIndex ? timeIndex : lastIndex + 3
                const endIndex = lastIndex

                if (i >= startIndex) {
                  // 終了時間設定(startIndex)以降の場合
                  acc[i] = 0
                } else if (i < endIndex) {
                  // 設定済みのシフト時間(endIndex)より以前の場合
                  acc[i] = shift
                } else if (shift === 0) {
                  // 終了時間設定(startIndex)と設定済みのシフト時間(endIndex)の間の場合
                  acc[i] = workspaceId
                } else {
                  // その他
                  acc[i] = shift
                }
                return acc
              },
              [...plan.workShift]
            )

      return {
        workerId: plan.workerId,
        workShifts,
        workScheduleTypes: plan.workType,
      }
    })

    return updatePlanData
  }, [
    workersPlan,
    workDate,
    selectedHour,
    selectedMinute,
    planStartDateTime,
    workerIds,
    selectedAdjustPatternId,
    workspaceId,
  ])

  const onSubmit = () => {
    if (typeof workDate === 'undefined') {
      return
    }

    const updatePlanDate: UpdatePlanBulk = {
      updateMode: PlanUpdateModeTypes.ShiftPlan,
      workersPlan: requestPlans,
    }

    setSubmitted(true)
    dispatch(updatePlanBulkCreate(workspaceId, workDate, updatePlanDate))
  }

  React.useEffect(() => {
    if (isRequesting || !submitted) {
      return
    }
    if (errorMessage === '') {
      onSuccess()
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, onSuccess, onError])

  return (
    <CustomModal isOpen={isOpen} title="シフト調整" approveLabel="保存" onCancel={onCancel} onApprove={onSubmit}>
      <ModalBody className="font-small px-4">
        <CardBody>
          <CardText>
            選択されているメンバーのシフトを調整します。
            <br />
            <span className="text-danger">
              保存時に作業計画画面は自動更新されます。未保存の作業予定がある場合は、シフト調整をキャンセルして先に作業計画を保存してください。
            </span>
          </CardText>
        </CardBody>
        <CardBody className="py-3">
          <SelectBoxFormat
            label="シフト調整"
            value={selectedAdjustPatternId?.toString()}
            size={ColumnSizes.middle}
            items={adjustPatternList}
            onChange={event => {
              setSelectedAdjustPatternId(Number(event.key))
            }}
          />
        </CardBody>
        <CardBody className="py-0">
          <div className="row">
            <Label md={4}>指定時間</Label>
            <Col md={6}>
              <div>
                <TimeSelect
                  hour={selectedHour}
                  minute={selectedMinute}
                  label=""
                  start={businessStartTime}
                  end={businessEndTime}
                  onChange={(hour, minute) => {
                    setSelectedHour(hour)
                    setSelectedMinute(minute)
                  }}
                />
              </div>
            </Col>
          </div>
        </CardBody>
      </ModalBody>
    </CustomModal>
  )
}

export default ShiftAdjustment
