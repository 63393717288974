import { encode } from 'iconv-lite'
import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'

import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'

import { selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectWorkResultsStatus, clearErrorMessage, csvImportWorkResults } from 'slices/workResultsSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import { CustomModal, FileInput, SelectBoxFormat } from 'components/common'
import type { CharacterCodeType } from 'components/common/types'
import { CharacterCodeTypes, exportData, ColumnSizes, connectionTypes } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'

type Props = {
  isOpen: boolean
  onSuccess: () => void
  onCancel: () => void
  date: string // yyyy-MM-DD
}
type CharacterCodeItemType = { key: CharacterCodeType; value: string }

const characterCodeItem: CharacterCodeItemType[] = [
  { key: CharacterCodeTypes.shiftJis, value: CharacterCodeTypes.shiftJis },
  { key: CharacterCodeTypes.utf8, value: CharacterCodeTypes.utf8 },
]
const CSVManualRecordsInputDialog: React.FC<Props> = ({ isOpen, onSuccess, onCancel, date }) => {
  const [file, setFile] = React.useState<File | null>(null)
  const [characterCode, setCharacterCode] = React.useState(characterCodeItem[0])
  const [submitted, setSubmitted] = React.useState(false)
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string>()
  const { isRequesting, errorMessage } = useSelector(selectWorkResultsStatus, shallowEqual)
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { allScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const { businessHourBlocks } = useBusinessTime()
  const dispatch = useDispatch()

  const handleCancel = () => {
    setCharacterCode(characterCodeItem[0])
    setFile(null)
    setModalErrorMessage(undefined)
    onCancel()
  }

  const handleDownloadFormat = () => {
    const header = ['ワークスペース', '作業', ...businessHourBlocks.map(b => `${b.toString().padStart(2, '0')}:00`)]
    const body = _.chain(allScheduleTypes)
      .filter(s => s.connectionType === connectionTypes.Manual)
      .map(s => {
        const workspace = partialWorkspaces.find(w => w.id === s.workspaceId)
        return {
          ...s,
          workspaceName: workspace?.name || '',
        }
      })
      .sortBy(['workspaceName', 'scheduleTypeId'])
      .map(s => [s.workspaceName, s.name, ...businessHourBlocks.map(() => '')])
      .value()

    const csvData = [header, ...body].map(r => r.join(',')).join('\n')
    const csvDataShiftJIS = encode(csvData, 'Shift_JIS')
    const blob = new Blob([csvDataShiftJIS], { type: 'text/csv' })
    exportData(blob, '実績一括入力フォーマット.csv')
  }

  const handleApprove = () => {
    if (!file) {
      return
    }

    const encoding = characterCode.value === CharacterCodeTypes.shiftJis ? 'SJIS' : 'UTF8'
    const formData = new FormData()
    formData.append('fileData', file)
    formData.append('encoding', encoding)
    dispatch(csvImportWorkResults(date, formData))
    setSubmitted(true)
  }

  const disabled = React.useMemo(() => !(file && /\.csv$/i.test(file.name)), [file])

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      setModalErrorMessage(undefined)
      onSuccess()
    } else {
      if (ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
        setModalErrorMessage(undefined)
      } else {
        setModalErrorMessage('保存できませんでした。')
      }
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, onSuccess, dispatch])

  return (
    <CustomModal
      isOpen={isOpen}
      title={`実績CSV一括入力 : ${date.replace(/-/g, '/')}`}
      onCancel={handleCancel}
      onApprove={handleApprove}
      approveDisabled={disabled}
      errorMessage={modalErrorMessage}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <div className="mb-3">
        下記実績時間別集計データフォーマットをダウンロードして、同じフォーマットでCSVアップロードをしてください。
      </div>
      <SelectBoxFormat
        label="文字コード選択"
        value={characterCode.key.toString()}
        size={ColumnSizes.middle}
        items={characterCodeItem}
        onChange={item => setCharacterCode(item as CharacterCodeItemType)}
        className="mb-3"
      />
      <ul>
        <li>対象の作業は実績入力方法が｢手動アップロード｣の作業のみです。</li>
        <li>
          1列目にはワークスペース名、2列目には作業名を入れてください。登録されているワークスペース名、作業名と完全一致することで、実績の一括入力が可能です。
        </li>
      </ul>
      <Button color="link" className="ps-0 mb-3 shadow-none text-decoration-none" onClick={handleDownloadFormat}>
        実績時間別集計データフォーマットをダウンロード
      </Button>
      <FileInput id="memberFile" accept=".csv" onChange={f => setFile(f)} />
    </CustomModal>
  )
}

export default CSVManualRecordsInputDialog
