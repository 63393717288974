import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import * as API from 'api/users'
import { UNREACHABLE_ERROR_STATUS_CODE, UNAUTHORIZED_ERROR_STATUS_CODE, makeErrorMessage } from 'api/utils'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import { validateToken } from 'slices/sessionSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import * as Spinner from 'slices/spinnerSlice'
import { commonParams } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type ExtendedUser = Omit<API.UserData, 'userHasTenants'> & {
  userHasTenants: (Omit<API.UserHasTenantsType, 'isActive'> & {
    isUserActive: boolean
    tenantName: string
    salesOfficeName: string
    isTenantActive: boolean
  })[]
}
type UserState = {
  isRequesting: boolean
  errorMessage: string
  user?: ExtendedUser
  userHasTenant?: API.UserHasTenantsType
  allTenantUsers: API.PartialUserData[]
  selectedTenantUsers: API.PartialUserData[]
  displayFilter?: API.DisplayFilterResponse
}

const initialState: UserState = {
  isRequesting: false,
  errorMessage: '',
  allTenantUsers: [],
  selectedTenantUsers: [],
  user: undefined,
  userHasTenant: undefined,
  displayFilter: undefined,
}

const createExtendedUser = (data: API.UserResponse): ExtendedUser => {
  const { user, tenants } = data
  const extendedUserHasTenants = user.userHasTenants.map(userTenant => {
    const tenant = tenants.find(t => t.id === userTenant.id)
    return {
      ..._.omit(userTenant, 'isActive'),
      isUserActive: userTenant.isActive,
      tenantName: tenant?.name || '',
      salesOfficeName: tenant?.salesOfficeName || '',
      isTenantActive: tenant?.isActive || false,
    }
  })

  return {
    ...user,
    userHasTenants: extendedUserHasTenants,
  }
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getUserListSuccess: (state, action: PayloadAction<API.UserListResponse>) => {
      state.isRequesting = false
      state.allTenantUsers = action.payload.partialUsers
    },
    getTenantUserListSuccess: (state, action: PayloadAction<API.UserListResponse>) => {
      state.isRequesting = false
      state.selectedTenantUsers = action.payload.partialUsers
    },
    createUserSuccess: state => {
      state.isRequesting = false
    },
    getUserSuccess: (state, action: PayloadAction<API.UserResponse>) => {
      state.isRequesting = false
      state.user = createExtendedUser(action.payload)
    },
    updateUserSuccess: (state, action: PayloadAction<API.UserResponse>) => {
      state.isRequesting = false
      state.user = createExtendedUser(action.payload)
    },
    deleteUserSuccess: state => {
      state.isRequesting = false
    },
    deleteTenantUserSuccess: state => {
      state.isRequesting = false
    },
    updateUserTenantSuccess: (state, action: PayloadAction<API.UserResponse>) => {
      state.isRequesting = false
      state.user = createExtendedUser(action.payload)
    },
    updateUserPermissionSuccess: (state, action: PayloadAction<API.UserResponse>) => {
      state.isRequesting = false
      state.user = createExtendedUser(action.payload)
    },
    displayFilterSuccess: (state, action: PayloadAction<API.DisplayFilterResponse>) => {
      state.isRequesting = false
      state.displayFilter = action.payload
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getUserListSuccess,
  createUserSuccess,
  getUserSuccess,
  updateUserSuccess,
  deleteUserSuccess,
  getTenantUserListSuccess,
  updateUserTenantSuccess,
  deleteTenantUserSuccess,
  updateUserPermissionSuccess,
  displayFilterSuccess,
} = usersSlice.actions

export const getUserList = (): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }
  dispatch(Spinner.start())
  API.getUserList(commonParams(getState))
    .then((res: API.UserListResponse) => dispatch(getUserListSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const createUser =
  (data: API.CreateUserProps): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.createUser(commonParams(getState), data)
      .then(() => {
        dispatch(createUserSuccess())
        dispatch(getUserList())
      })
      .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
      .finally(() => dispatch(Spinner.stop()))
  }

export const getUser =
  (userId: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getUser(commonParams(getState), userId)
      .then((res: API.UserResponse) => dispatch(getUserSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateUser =
  (data: API.UpdateUserProps): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateUser(commonParams(getState), data)
      .then((res: API.UserResponse) => {
        dispatch(updateUserSuccess(res))
        dispatch(getUserList())
      })
      .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
      .finally(() => dispatch(Spinner.stop()))
  }

export const deleteUser =
  (userId: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    const params = commonParams(getState)
    dispatch(Spinner.start())
    API.deleteUser(commonParams(getState), userId)
      .then(() => {
        dispatch(deleteUserSuccess())
        if (userId === params.userId) {
          return
        }
        dispatch(getUserList())
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateUserTenant =
  (userId: string, tenantId: number, data: API.UpdateUserTenantProps): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateUserTenant(commonParams(getState), userId, tenantId, data)
      .then((res: API.UserResponse) => {
        dispatch(updateUserTenantSuccess(res))
        dispatch(getTenantUserList(tenantId))
      })
      .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
      .finally(() => dispatch(Spinner.stop()))
  }

export const getTenantUserList =
  (tenantId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getTenantUserList(commonParams(getState), tenantId)
      .then((res: API.UserListResponse) => dispatch(getTenantUserListSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const createTenantUser =
  (tenantId: number, data: API.CreateTenantUserProps): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.createTenantUser(commonParams(getState), tenantId, data)
      .then(() => {
        dispatch(createUserSuccess())
        dispatch(getTenantUserList(tenantId))
      })
      .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
      .finally(() => dispatch(Spinner.stop()))
  }

export const getTenantUser =
  (userId: string, tenantId?: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    const params = tenantId ? _.merge(commonParams(getState), { tenantId }) : commonParams(getState)

    API.getTenantUser(params, userId)
      .then((res: API.UserResponse) => dispatch(getUserSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateTenantUser =
  (data: API.UpdateTenantUserProps): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }
    const params = commonParams(getState)
    dispatch(Spinner.start())
    API.updateTenantUser(params, data)
      .then((res: API.UserResponse) => {
        dispatch(updateUserSuccess(res))
        dispatch(getTenantUserList(params.tenantId))
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const deleteTenantUser =
  (userId: string, tenantId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }
    const params = commonParams(getState)
    dispatch(Spinner.start())
    API.deleteTenantUser(params, userId, tenantId)
      .then(() => {
        dispatch(deleteTenantUserSuccess())
        if (userId === params.userId) {
          return
        }
        dispatch(getTenantUserList(tenantId))
      })
      .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateTenantUserPermission =
  (userId: string, data: API.UpdateUserPermissionProps): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateTenantUserPermission(commonParams(getState), userId, data)
      .then((res: API.UserResponse) => dispatch(updateUserPermissionSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getDisplayFilter = (): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.getDisplayFilter(commonParams(getState))
    .then((res: API.DisplayFilterResponse) => dispatch(displayFilterSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const updateDisplayFilter =
  (data: API.UpdateDisplayFilterProps): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateDisplayFilter(commonParams(getState), data)
      .then((res: API.DisplayFilterResponse) => dispatch(displayFilterSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const selectUsersStatus = (state: RootState) => ({ ...state.users })

export default usersSlice.reducer
