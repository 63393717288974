import { isEqual } from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardTitle, CardText, Input, Label } from 'reactstrap'

import type { TenantApplicationSettingType } from 'api/tenants'
import type { PartialWorkspaceData } from 'api/workspaces'

import { showError } from 'slices/notificationSlice'
import { healthCheck, selectOptimizationStatus } from 'slices/optimizationSlice'

import { CardSubmitFooter, CheckBoxFormat, InputFormat } from 'components/common'

type Props = {
  application: TenantApplicationSettingType
  partialWorkspaces: PartialWorkspaceData[]
  updateTenant: (application: TenantApplicationSettingType) => void
  deleteApplication: (applicationId: number) => void
}
const OptEngineSetting: React.FC<Props> = ({ application, partialWorkspaces, updateTenant, deleteApplication }) => {
  const [apiKey, setApiKey] = React.useState('')
  const [magiQannealTenant, setMagiQannealTenant] = React.useState('')
  const [related, setRelated] = React.useState(false)
  const [relatedWorkspaceData, setRelatedWorkspaceData] = React.useState<
    { relatedWorkspaceId: number; location: string }[]
  >([])
  const [submitted, setSubmitted] = React.useState(false)

  const dispatch = useDispatch()
  const { errorMessage, isRequesting } = useSelector(selectOptimizationStatus, shallowEqual)
  const updateData = React.useMemo(
    () => ({
      ...application,
      options: { apiKey, tenant: magiQannealTenant, relatedWorkspaceData: relatedWorkspaceData },
    }),
    [apiKey, magiQannealTenant, application, relatedWorkspaceData]
  )

  React.useEffect(() => {
    setApiKey(application.options.apiKey)
    setMagiQannealTenant(application?.options.tenant ?? '')
    setRelated(!!application.options.apiKey)
    setRelatedWorkspaceData(application?.options.relatedWorkspaceData ?? [])
  }, [application.options])

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage) {
      // healthcheck に失敗した場合は NotificationError を表示
      dispatch(showError({ errorMessage }))
      setSubmitted(false)
      return
    }

    if (related) {
      updateTenant(updateData)
    } else {
      deleteApplication(application.applicationId)
    }
    setSubmitted(false)
  }, [
    dispatch,
    errorMessage,
    isRequesting,
    submitted,
    updateTenant,
    updateData,
    related,
    deleteApplication,
    application.applicationId,
  ])

  const onChangeRelated = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApiKey('')
    setMagiQannealTenant('')
    setRelatedWorkspaceData([])
    setRelated(event.target.checked)
  }
  const workspaceItems = React.useMemo(
    () =>
      partialWorkspaces.map(w => {
        const target = relatedWorkspaceData.find(rw => rw.relatedWorkspaceId === w.id)
        return {
          workspaceId: w.id,
          label: w.name,
          checked: !!target,
          magiQannealLocation: target?.location ?? '',
        }
      }),
    [relatedWorkspaceData, partialWorkspaces]
  )
  const onClickCardCheckbox = (location: string, checked: boolean, workspaceId: number) => {
    if (!checked) {
      const newData = relatedWorkspaceData.filter(rw => rw.relatedWorkspaceId !== workspaceId)
      setRelatedWorkspaceData(newData)
      return
    }
    const newData = relatedWorkspaceData.some(rw => rw.relatedWorkspaceId === workspaceId)
      ? relatedWorkspaceData.map(rw =>
          rw.relatedWorkspaceId === workspaceId ? { relatedWorkspaceId: workspaceId, location } : rw
        )
      : relatedWorkspaceData.concat({ relatedWorkspaceId: workspaceId, location })
    setRelatedWorkspaceData(newData)
  }

  const onCancel = () => {
    setApiKey(application.options.apiKey)
    setMagiQannealTenant(application?.options.tenant ?? '')
    setRelated(!!application?.options.apiKey)
    setRelatedWorkspaceData(application?.options.relatedWorkspaceData ?? [])
  }
  const onSubmit = () => {
    setSubmitted(true)
    if (apiKey) {
      dispatch(
        healthCheck(
          apiKey,
          magiQannealTenant,
          relatedWorkspaceData.map(rw => rw.location)
        )
      )
    }
  }
  const disabled = React.useMemo(
    () => related && (!apiKey || relatedWorkspaceData.length === 0),
    [apiKey, related, relatedWorkspaceData.length]
  )
  const unchanged = React.useMemo(
    () =>
      isEqual(application?.options, { apiKey, tenant: magiQannealTenant, relatedWorkspaceData }) &&
      // "OptEngine連携" を "なし" から "あり" に変えたときにキャンセルボタンを有効にするための判定
      related === !!application?.options.apiKey,
    [application?.options, apiKey, relatedWorkspaceData, related, magiQannealTenant]
  )

  return (
    <>
      <CardBody>
        <CardTitle className="font-large flex-grow-1 fw-bold">{application.applicationName}</CardTitle>
        <CardText className="py-2">量子コンピュータとAIを使って、最適配置を提案するサービスです。</CardText>
        <CheckBoxFormat label="magiQanneal連携" checkboxLabel="あり" checked={related} onChange={onChangeRelated} />

        {related && (
          <>
            <CardTitle>連携の設定</CardTitle>
            <Card>
              <CardBody>
                テナントの設定をしてください｡
                <InputFormat
                  className="mt-3"
                  label="認証キー"
                  placeholder="認証キーを入力"
                  value={apiKey}
                  onChange={setApiKey}
                />
                <InputFormat
                  className="mt-3"
                  label="パスパラメータ"
                  placeholder="パスパラメータを入力"
                  value={magiQannealTenant}
                  onChange={setMagiQannealTenant}
                />
              </CardBody>
              <CardBody className="border-top">
                <CardText>
                  magiQannealと連携する対象のワークスペースを選んで設定をしてください。作業計画画面に「最適配置」ボタンが表示されます。
                </CardText>
                {workspaceItems.map(({ workspaceId, label, checked, magiQannealLocation }) => (
                  <Card className="mb-3" key={`card-${workspaceId}-${label}`}>
                    <CardBody>
                      <div className="form-check">
                        <Input
                          className="form-check-input"
                          id={label}
                          checked={checked}
                          type="checkbox"
                          onChange={e => onClickCardCheckbox(magiQannealLocation, e.target.checked, workspaceId)}
                        />
                        <Label className="form-check-label mb-0" for={label}>
                          {label}
                        </Label>
                      </div>
                    </CardBody>
                    {checked && (
                      <CardBody className="border-top">
                        <InputFormat
                          className="mb-0 ms-2"
                          label="パスパラメータ"
                          placeholder="パスパラメータを入力"
                          value={magiQannealLocation}
                          onChange={inputLocation => onClickCardCheckbox(inputLocation, checked, workspaceId)}
                        />
                      </CardBody>
                    )}
                  </Card>
                ))}
              </CardBody>
            </Card>
          </>
        )}
      </CardBody>
      <CardSubmitFooter
        onCancel={onCancel}
        onSubmit={onSubmit}
        cancelDisabled={unchanged}
        submitDisabled={disabled || unchanged}
      />
    </>
  )
}

export default OptEngineSetting
