import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'

import { Suggestion } from 'components/common'
import type { SuggestionItem } from 'components/common/types'

import styles from './RelatedWorkspaceEdit.module.scss'

type Props = {
  workspaceId?: number
  relatedWorkspaces: { id: number; value: string }[]
  onChange: (relatedWorkspaces: { id: number; value: string }[]) => void
}

const RelatedWorkspaceEdit: React.FC<Props> = props => {
  const { workspaceId, relatedWorkspaces, onChange } = props

  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])
  const workspaceStatus = useSelector(selectWorkspacesStatus, shallowEqual)
  const { partialWorkspaces } = workspaceStatus

  const onSelectWorkspace = (item: SuggestionItem) => {
    const newRelatedWorkspaces = [...relatedWorkspaces, { id: Number(item.id), value: item.value }]
    onChange(newRelatedWorkspaces)
  }

  const removeWorkspace = (item: SuggestionItem) => {
    const newRelatedWorkspaces = relatedWorkspaces.filter(workspace => workspace.id !== Number(item.id))
    onChange(newRelatedWorkspaces)
  }

  const remainingWorkspaces = React.useMemo(
    () =>
      partialWorkspaces
        .filter(workspace => workspace.id !== workspaceId)
        .map(workspace => ({ id: workspace.id, value: workspace.name })),
    [workspaceId, partialWorkspaces]
  )

  return (
    <>
      <div className={styles.badgeContainer}>
        {relatedWorkspaces.map(workspace => (
          <div
            key={workspace.id}
            className="badge rounded-pill bg-primary fw-normal m-2 font-small"
            style={{ maxWidth: '90%' }}
          >
            <div className="d-flex align-items-center">
              <div className="text-truncate">{workspace.value}</div>
              <i className="icf-close ms-1" role="button" onClick={() => removeWorkspace(workspace)} />
            </div>
          </div>
        ))}
      </div>
      <div className="mt-3 text-muted">連携するワークスペースを追加</div>
      <div className="d-flex">
        <Suggestion
          className="w-50"
          suggestionList={remainingWorkspaces}
          onSelect={onSelectWorkspace}
          placeholder="ワークスペース名で検索"
        />
      </div>
    </>
  )
}

export default RelatedWorkspaceEdit
