import { createSlice } from '@reduxjs/toolkit'

import * as API from 'api/official_duties'
import { makeErrorMessage, UNAUTHORIZED_ERROR_STATUS_CODE, UNREACHABLE_ERROR_STATUS_CODE } from 'api/utils'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import { validateToken } from 'slices/sessionSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import * as Spinner from 'slices/spinnerSlice'
import { commonParams } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type OfficialDutiesState = {
  partialOfficialDutiesList: API.PartialOfficialDutiesListData[]
  officialDuties?: API.OfficialDutiesData
  isRequesting: boolean
  errorMessage: string
}

const initialState: OfficialDutiesState = {
  isRequesting: false,
  errorMessage: '',
  officialDuties: undefined,
  partialOfficialDutiesList: [],
}

export const officialDutiesSlice = createSlice({
  name: 'duties',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getOfficialDutiesListSuccess: (state, action: PayloadAction<API.OfficialDutiesListResponse>) => {
      state.isRequesting = false
      state.partialOfficialDutiesList = action.payload.partialOfficialDutiesList
    },
    getOfficialDutiesSuccess: (state, action: PayloadAction<API.OfficialDutiesDetailResponse>) => {
      state.isRequesting = false
      state.officialDuties = action.payload.officialDuties
    },
    createOfficialDutiesSuccess: state => {
      state.isRequesting = false
    },
    updateOfficialDutiesSuccess: state => {
      state.isRequesting = false
    },
    deleteOfficialDutiesSuccess: state => {
      state.isRequesting = false
    },
  },
})

export const {
  startRequest,
  apiFailure,
  getOfficialDutiesListSuccess,
  getOfficialDutiesSuccess,
  createOfficialDutiesSuccess,
  updateOfficialDutiesSuccess,
  deleteOfficialDutiesSuccess,
} = officialDutiesSlice.actions

export const getOfficialDutiesList = (): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.getOfficialDutiesList(commonParams(getState))
    .then((res: API.OfficialDutiesListResponse) => dispatch(getOfficialDutiesListSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const getOfficialDuties =
  (id: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getOfficialDuties(commonParams(getState), id)
      .then((res: API.OfficialDutiesDetailResponse) => dispatch(getOfficialDutiesSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const createOfficialDuties =
  (data: API.OfficialDutiesEditData): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.createOfficialDuties(commonParams(getState), data)
      .then(res => {
        dispatch(createOfficialDutiesSuccess())
        dispatch(getOfficialDutiesList())
        dispatch(getOfficialDuties(res.id))
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateOfficialDuties =
  (id: number, data: API.OfficialDutiesEditData): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateOfficialDuties(commonParams(getState), id, data)
      .then(() => {
        dispatch(updateOfficialDutiesSuccess())
        dispatch(getOfficialDutiesList())
        dispatch(getOfficialDuties(id))
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const deleteOfficialDuties =
  (id: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.deleteOfficialDuties(commonParams(getState), id)
      .then(() => {
        dispatch(deleteOfficialDutiesSuccess())
        dispatch(getOfficialDutiesList())
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const selectOfficialDutiesStatus = (state: RootState) => ({ ...state.officialDuties })

export default officialDutiesSlice.reducer
