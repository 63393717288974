import axios from 'axios'

import type { CommonParams } from 'slices/utils'

import type { ColorType } from 'components/common/types'

import type { PartialTenantData, TenantData } from './tenants'
import type { CreateResponse, UpdateResponse, DeleteResponse } from './types'
import type { PartialWorkspaceData, WorkspaceData } from './workspaces'

export type GroupData = {
  id: number
  workDate: string
  revision: number
  name: string
  color: ColorType
  updatedAt: string
  updatedByName: string | null
}

export type GroupListResponse = {
  partialTenant: PartialTenantData
  partialWorkspace: PartialWorkspaceData
  workGroups: GroupData[]
}

export type GroupResponse = {
  workDate: string
  tenant: TenantData
  workspace: WorkspaceData
  workGroup: GroupData
}

export const getGroupList = (params: CommonParams, workspaceId: number): Promise<GroupListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces/${workspaceId}/groups`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getGroup = (params: CommonParams, workspaceId: number, groupId: number): Promise<GroupResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces/${workspaceId}/groups/${groupId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createGroup = (
  params: CommonParams,
  workspaceId: number,
  name: string,
  color: ColorType
): Promise<CreateResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces/${workspaceId}/groups`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, { name, color }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const updateGroup = (
  params: CommonParams,
  workspaceId: number,
  groupId: number,
  name: string,
  color: ColorType
): Promise<UpdateResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces/${workspaceId}/groups/${groupId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, { name, color }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const deleteGroup = (params: CommonParams, workspaceId: number, groupId: number): Promise<DeleteResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces/${workspaceId}/groups/${groupId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .delete(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
