import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Card, CardText, CardBody } from 'reactstrap'

import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'

import { CustomButton, NavMenu } from 'components/common'

import useAuthority from 'hooks/useAuthority'

import placeholder from 'images/allEmpty.svg'

import styles from './Workspaces.module.scss'

const onDetailClick = () => window.open('https://help.smileboard.jp/create_workspace', '_blank')

const Workspaces: React.FC = () => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  const { isReadOnlyWorkspace } = useAuthority()

  const navigate = useNavigate()

  const onWorkspaceCreateClick = () => navigate('/workspace-create')

  const moveToDetail = (id: number) => navigate(`/workspaces/${id}`)

  return (
    <NavMenu>
      <div className="mt-3 mx-3">
        <div className="d-flex justify-content-between pb-3">
          <div className="font-x-large fw-bold align-self-center">ワークスペース一覧</div>
          <CustomButton icon="plus" disabled={isReadOnlyWorkspace} onClick={onWorkspaceCreateClick}>
            ワークスペースを追加
          </CustomButton>
        </div>
        <Card className={`position-sticky ${styles.list}`}>
          {partialWorkspaces.length > 0 ? (
            partialWorkspaces.map((workspace, index) => (
              <Card
                key={`workspace-card-${index}`}
                className="py-2 px-3 mb-2"
                role="button"
                onClick={() => moveToDetail(workspace.id)}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="overflow-hidden">
                    <CardText className="m-0 text-truncate">{workspace.name}</CardText>
                    <CardText className="font-x-small text-muted text-truncate">
                      {workspace.scheduleTypeNames.join('、')}
                    </CardText>
                  </div>
                  <i className="icf-chevron_right font-large" />
                </div>
              </Card>
            ))
          ) : (
            <CardBody className="d-flex align-items-center justify-content-center">
              <div className="text-center">
                <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholder} alt="" />
                <div className="font-middle fw-bold py-4">ワークスペースがまだ登録されていません</div>
                <div>ワークスペースはあなたの事務所に合わせてカスタマイズできます。</div>
                <div>まずは最初のワークスペースを登録してみましょう。</div>
                <Button className="mx-auto d-block m-4" size="sm" outline onClick={onDetailClick}>
                  ワークスペースについてもっと詳しく
                </Button>
              </div>
            </CardBody>
          )}
        </Card>
      </div>
    </NavMenu>
  )
}

export default Workspaces
