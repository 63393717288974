import _ from 'lodash'
import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { NavLink as ReactstrapNavLink } from 'reactstrap'

import useSideBar from 'hooks/useSideBar'

import styles from './styles.module.scss'

type INavMenuProps = {
  children: React.ReactElement
  isOpenSidebar?: boolean
}

const navLinkClassName = (isActive: boolean) => {
  const commonClass = `d-inline-block text-decoration-none ms-3 text-truncate ${styles.pathLabel} `
  const active = `text-primary ${styles.active} `
  const inactive = 'text-black'

  return commonClass + (isActive ? active : inactive)
}

const NavMenu: React.FC<INavMenuProps> = ({ children, isOpenSidebar = true }) => {
  const { sideBarProps, isPathMatching } = useSideBar()

  return isOpenSidebar ? (
    <div className={styles.container}>
      <div className={`py-2 ${styles.sidebar}`}>
        {sideBarProps.previousItem && (
          <NavLink
            end
            className={`text-decoration-none ms-3 py-2 d-flex align-items-center text-black ${styles.previousItem}`}
            to={sideBarProps.previousItem!.path}
          >
            <i className="icf-chevron_left" />
            {sideBarProps.previousItem.label}
          </NavLink>
        )}
        {!_.isEmpty(sideBarProps.items) &&
          sideBarProps.items!.map((item, index) => {
            return (
              <div key={item.paths[0] ? item.paths[0].path : item.label}>
                {item.label && <div className={`text-muted ${styles.itemLabel}`}>{item.label}</div>}
                {item.paths.map(path =>
                  path.onClick ? (
                    <div key={path.path} className={`py-2 ${styles.item}`}>
                      <ReactstrapNavLink onClick={path.onClick} className={navLinkClassName(isPathMatching(path.path))}>
                        {path.label}
                      </ReactstrapNavLink>
                    </div>
                  ) : (
                    <div key={path.path} className={`py-2 ${styles.item}`}>
                      <NavLink end className={({ isActive }) => navLinkClassName(isActive)} to={path.path}>
                        {path.label}
                      </NavLink>
                    </div>
                  )
                )}
                {index + 1 < sideBarProps.items!.length && <hr className="py-2 m-0" />}
              </div>
            )
          })}
      </div>
      <div className={styles.childContainer}>{children}</div>
    </div>
  ) : (
    <>{children}</>
  )
}
export default NavMenu
