import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Input, Label } from 'reactstrap'

import { selectWorkersStatus, exportWorkers } from 'slices/workersSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import { CustomModal, ItemEdit } from 'components/common'
import type { SuggestionItem } from 'components/common/types'

type Props = {
  isOpen: boolean
  onSuccess: () => void
  onCancel: () => void
}

const UNAFFILIATED_WORKER_ID = -1

const WorkersExportDialog: React.FC<Props> = ({ isOpen, onSuccess, onCancel }) => {
  const [submitted, setSubmitted] = React.useState(false)
  const [isReplaceAverageProductivity, setReplaceAverageProductivity] = React.useState(false)
  const [selectedWorkspaces, setSelectedWorkspaces] = React.useState<SuggestionItem[]>([])
  const dispatch = useDispatch()
  const { isRequesting, errorMessage } = useSelector(selectWorkersStatus, shallowEqual)
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  const disabled = React.useMemo(() => _.isEmpty(selectedWorkspaces), [selectedWorkspaces])

  const handleSaveClick = () => {
    const targetWorkspaces = selectedWorkspaces.filter(ws => Number(ws.id) > 0).map(ws => ws.id as number)
    const includeUnaffiliatedWorker = selectedWorkspaces.some(ws => ws.id === UNAFFILIATED_WORKER_ID)
    dispatch(exportWorkers(targetWorkspaces, isReplaceAverageProductivity, includeUnaffiliatedWorker))
    setSubmitted(true)
  }

  const handleCancelClick = () => {
    setSelectedWorkspaces([])
    setReplaceAverageProductivity(false)
    onCancel()
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      onSuccess()
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, onSuccess, dispatch])

  const workspaceItems = React.useMemo(
    () =>
      partialWorkspaces.map(w => ({ id: w.id, value: w.name })).concat({ id: UNAFFILIATED_WORKER_ID, value: '未所属' }),
    [partialWorkspaces]
  )

  return (
    <CustomModal
      isOpen={isOpen}
      title="メンバーエクスポート"
      approveLabel="CSVエクスポート"
      approveDisabled={disabled}
      onCancel={handleCancelClick}
      onApprove={handleSaveClick}
      submitName="member-export-submit"
    >
      <div className="pb-4">
        <div className="mb-4">
          <div className="my-2 fw-bold">ワークスペース選択</div>
          <div className="mb-2">メンバーエクスポートするワークスペースを選択してください。</div>
          <ItemEdit
            items={workspaceItems}
            selectedItems={selectedWorkspaces}
            label="ワークスペースを追加"
            itemName="ワークスペース"
            onChange={setSelectedWorkspaces}
          />
        </div>
        <div className="mb-4">
          <div className="my-2 fw-bold">その他の設定</div>
          <div className="mb-2">作業の人時生産性を過去実績最大30日の平均値と置き換えてエクスポート</div>
          <div className="form-check">
            <Input
              id="contain-productivity-adjustment"
              className="form-check-input"
              checked={isReplaceAverageProductivity}
              type="checkbox"
              onChange={e => setReplaceAverageProductivity(e.target.checked)}
            />
            <Label className="form-check-label" for="contain-productivity-adjustment">
              過去実績の平均値と置き換える
            </Label>
          </div>
        </div>
      </div>
    </CustomModal>
  )
}

export default WorkersExportDialog
