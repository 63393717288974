import axios from 'axios'

import type { BopWorkspaceDataUnit } from 'api/dashboard'

import type { CommonParams } from 'slices/utils'

export type BopReportsWorkspaceArray = {
  workspaceId: number
  workspaceName: string
  data: BopWorkspaceDataUnit
}

export type BopReportsProfitAndLossResponse = {
  estimates: BopReportsWorkspaceArray[]
  actuals: BopReportsWorkspaceArray[]
  conjecturedEstimates: BopReportsWorkspaceArray[]
  updatedAt: string
  updatedByName: string
}

export type BopReportsBopResponse = {
  estimate: BopData
  actual: BopData
  updatedAt: string
  updatedByName: string
}

export type BopTotalPeriodData = {
  totalSales: number
  sales: number
  otherSales: number
  totalCosts: number
  costOfGoodsSold: number
  fixedCosts: number
  otherCosts: number
  profit: number
  profitRatio: number
}

export type BopDateData = {
  date: string
  sales: number
  otherSales: number
  costOfGoodsSold: number
  fixedCosts: number
  otherCosts: number
  profitRatio: number
}

export type BopData = {
  totalPeriodData: BopTotalPeriodData
  data: BopDateData[]
}

export type BopMonitoringReportParam = {
  from?: string
  to?: string
  displayFilter?: boolean
  getWorkspaces?: string
}
export const BopExportDataTypes = {
  Summary: 'summary',
  Detail: 'detail',
} as const
export type BopExportDataType = (typeof BopExportDataTypes)[keyof typeof BopExportDataTypes]

export type CreateBopExportDataInfo = {
  startDate: string
  endDate: string
  targetWorkspaces: number[]
  exportDataType: BopExportDataType
}

export type CreateExportDataResponse = {
  requestId: string
}
export type CheckExportDataReadyResponse = {
  downloadUrl: string
}
export const getBopReportsProfitAndLoss = (
  params: CommonParams,
  from: string,
  to: string
): Promise<BopReportsProfitAndLossResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/bop-report-detail?from=${from}&to=${to}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getBopReportsBop = (
  params: CommonParams,
  data: BopMonitoringReportParam
): Promise<BopReportsBopResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/bop-report`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers, params: data })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createBopExportData = (
  params: CommonParams,
  data: CreateBopExportDataInfo
): Promise<CreateExportDataResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/bop-export/async`

    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getBopExportData = (params: CommonParams, requestId: string): Promise<CheckExportDataReadyResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/bop-export/update-status/${requestId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
