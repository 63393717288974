import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'

import { selectUsersStatus } from 'slices/usersSlice'

import { CustomModal } from 'components/common'

import useDeleteUser from 'hooks/useDeleteUser'

type Props = {
  isOpen: boolean
  tenantId: number
  onSuccess: () => void
  onCancel: () => void
  userId: string
}

const AccountDelete: React.FC<Props> = ({ isOpen, tenantId, onSuccess, onCancel }) => {
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = React.useState(false)
  const { isRequesting, errorMessage } = useSelector(selectUsersStatus, shallowEqual)
  const { deleteUser } = useDeleteUser(tenantId)
  const dispatch = useDispatch()

  const handleDeleteClick = () => {
    setSubmitted(true)
    deleteUser()
  }

  const handleCancelClick = () => {
    setModalErrorMessage(undefined)
    onCancel()
  }

  React.useEffect(() => {
    if (isRequesting || !submitted) {
      return
    }
    if (errorMessage === '') {
      onSuccess()
    } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
      // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
      setModalErrorMessage('削除できませんでした')
    }
    setSubmitted(false)
  }, [onSuccess, dispatch, submitted, isRequesting, errorMessage])

  return (
    <CustomModal
      isOpen={isOpen}
      title="アカウント削除"
      approveColor="danger"
      approveLabel="削除"
      errorMessage={modalErrorMessage}
      onCancel={handleCancelClick}
      onApprove={handleDeleteClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <div className="pb-4">アカウントを本当に削除しますか？この操作は取り消せません。</div>
    </CustomModal>
  )
}

export default AccountDelete
