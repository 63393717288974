import _ from 'lodash'
import * as React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'

import { Role } from 'api/users'
import type { PartialWorkspaceData } from 'api/workspaces'

import { selectDashboardStatus } from 'slices/dashboardSlice'
import { showError } from 'slices/notificationSlice'
import { getPlanByDateWithShiftCheck, selectPlansStatus } from 'slices/plansSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import useBusinessTime from 'hooks/useBusinessTime'

import useBopReportsQuery from './useBopReportsQuery'
import useDateQuery from './useDateQuery'

// onClickを指定する場合､画面遷移は自動で行われないため､手動で実装する
type MenuItemsType = {
  path: string
  label: string
  onClick?: () => void
}

type SideBarProps = {
  previousItem?: MenuItemsType
  items?: {
    label?: string
    paths: MenuItemsType[]
  }[]
}

type Page = {
  path: string
  sidebar: SideBarProps
}

const workersProps = {
  items: [
    {
      paths: [
        {
          label: 'メンバー一覧',
          path: '/workers',
        },
        {
          label: 'シフト管理',
          path: '/shifts',
        },
        {
          label: 'スキル管理',
          path: '/skills',
        },
      ],
    },
  ],
}
const tenantsProps = {
  items: [
    {
      paths: [
        {
          label: 'テナント一覧',
          path: '/tenants',
        },
      ],
    },
  ],
}
const userProps = {
  items: [
    {
      paths: [
        {
          label: 'ユーザー一覧',
          path: '/users',
        },
      ],
    },
  ],
}
const workspacesProps = {
  items: [
    {
      paths: [
        {
          label: 'ワークスペース一覧',
          path: '/workspaces',
        },
      ],
    },
  ],
}

const usePages = () => {
  const [submitted, setSubmitted] = React.useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { getWorkDate } = useBusinessTime()
  const params = useParams<'tenantId' | 'workspaceId' | 'workDate' | 'date'>()

  const { tenantId, workspaceId, workDate, date } = React.useMemo(
    () => ({
      tenantId: params.tenantId,
      workspaceId: params.workspaceId,
      workDate: getWorkDate(String(params.workDate)),
      date: getWorkDate(String(params.date)),
    }),
    [params, getWorkDate]
  )
  const formattedDateQuery = useDateQuery()
  const { queryStart, queryEnd } = useBopReportsQuery()

  const { user } = useSelector(selectSessionStatus, shallowEqual)
  const { tenantSummary } = useSelector(selectDashboardStatus, shallowEqual)
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { isRequesting, errorMessage, plans } = useSelector(selectPlansStatus, shallowEqual)

  // WorkPlan.tsxのDateChangeButtonと同様の処理
  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }

    setSubmitted(false)
    if (errorMessage === '') {
      navigate(`/schedules/${plans?.workspace?.id}/${plans?.workDate}`)
    } else {
      dispatch(showError({ errorMessage: '指定日に勤務時間が設定されていません。' }))
    }
  }, [isRequesting, errorMessage, submitted, navigate, plans?.workspace?.id, plans?.workDate, dispatch])

  // テナント詳細
  const createTenantsProps = React.useCallback(
    (pathEnd: string) => ({
      previousItem: {
        label: 'すべてのテナント',
        path: '/tenants',
      },
      items: [
        {
          paths: [
            {
              label: 'テナント詳細',
              path: `/tenants/${tenantId}/${pathEnd}`,
            },
          ],
        },
      ],
    }),
    [tenantId]
  )

  const tenantsDetailProps = React.useMemo(() => createTenantsProps('detail'), [createTenantsProps])
  const tenantsUsersProps = React.useMemo(() => createTenantsProps('users'), [createTenantsProps])
  const tenantsServicesProps = React.useMemo(() => createTenantsProps('services'), [createTenantsProps])

  //   ワークスペース
  const workspaceProps = React.useMemo(
    () => ({
      previousItem: {
        label: 'すべてのワークスペース',
        path: '/workspaces',
      },
      items: [
        {
          paths: [
            {
              label: '作業管理',
              path: `/workspaces/${workspaceId}`,
            },
            {
              label: 'グループ管理',
              path: `/workspaces/${workspaceId}/groups`,
            },
            {
              label: '予定テンプレート管理',
              path: `/workspaces/${workspaceId}/templates`,
            },
            {
              label: '作業間配賦管理',
              path: `/workspaces/${workspaceId}/allocation`,
            },
            {
              label: '設定',
              path: `/workspaces/${workspaceId}/edit`,
            },
          ],
        },
      ],
    }),
    [workspaceId]
  )

  const accountSettingsProps = React.useMemo(() => {
    const paths = [
      {
        label: 'アカウント情報',
        path: '/account/edit',
      },
    ]
    if (user.userHasTenants[0].role === Role.Admin) {
      paths.push({
        label: 'ご登録企業情報',
        path: '/company/edit',
      })
    }

    return {
      items: [
        {
          paths,
        },
      ],
    }
  }, [user.userHasTenants])

  const dashboardProps = React.useMemo(() => {
    const items = _.sortBy(tenantSummary?.workspaceData, 'workspaceName').map(data => {
      const targetPath = data.workspaceId === Number(workspaceId) ? pathname : `/dashboard/${data.workspaceId}`
      const queries = `?date=${formattedDateQuery}`
      return {
        path: targetPath + queries,
        label: data.workspaceName,
      }
    })
    const paths = [
      {
        path: `/dashboard?date=${formattedDateQuery}`,
        label: '業務モニタリング',
      },
    ]
    if (user.userHasTenants[0].canViewBOP || user.userHasTenants[0].canManageBOP) {
      paths.push({
        path: `/dashboard/balance-of-payments?date=${formattedDateQuery}`,
        label: '収支モニタリング',
      })
    }
    return {
      items: [
        {
          paths,
        },
        {
          label: 'ワークスペース別',
          paths: items,
        },
      ],
    }
  }, [tenantSummary?.workspaceData, formattedDateQuery, user.userHasTenants, workspaceId, pathname])

  const bopProps = React.useMemo(
    () => ({
      items: [
        {
          paths: [
            {
              label: 'ワークスペース収支管理',
              path: '/balance-of-payments',
            },
            {
              label: '職掌別時給管理',
              path: '/hourly-wage',
            },
          ],
        },
      ],
    }),

    []
  )

  const bopReportsProps = React.useMemo(
    () => ({
      items: [
        {
          paths: [
            {
              label: '収支',
              path: `/bop-reports/bop?start=${queryStart}&end=${queryEnd}`,
            },
            {
              label: '損益詳細',
              path: `/bop-reports/profit-and-loss?start=${queryStart}&end=${queryEnd}`,
            },
          ],
        },
      ],
    }),
    [queryStart, queryEnd]
  )

  const createPaths = React.useCallback(
    (path: string, targetWorkspace: PartialWorkspaceData, isWorkPlan = false) => {
      const targetWorkspaceId = String(targetWorkspace.id)
      const targetPath = path
        .replace(':workspaceId', String(targetWorkspaceId))
        .replace('/:workDate', workspaceId === targetWorkspaceId ? '/' + (workDate || '') : '')
        .replace('/:date', workspaceId === targetWorkspaceId ? '/' + (date || '') : '')
      if (isWorkPlan) {
        return {
          path: targetPath,
          label: targetWorkspace.name,
          onClick: () => {
            if (targetWorkspaceId && workDate) {
              dispatch(getPlanByDateWithShiftCheck(Number(targetWorkspaceId), workDate || ''))
              setSubmitted(true)
            }
          },
        }
      }
      return {
        path: targetPath,
        label: targetWorkspace.name,
      }
    },
    [workspaceId, workDate, date, dispatch]
  )

  const createProps = React.useCallback(
    (path: string, isWorkPlan = false) => {
      if (_.isEmpty(partialWorkspaces)) {
        return {}
      }

      if (user.userHasTenants[0].role !== Role.ProcessAdmin) {
        const paths = _.sortBy(partialWorkspaces, 'name').map(w => createPaths(path, w, isWorkPlan))
        return {
          items: [
            {
              paths,
            },
          ],
        }
      }

      const { managementWorkspaces, etcWorkspaces } = _.sortBy(partialWorkspaces, 'name').reduce(
        (acc, cur) => {
          const target = user.userHasTenants[0].managedWorkspaces.some(w => w.id === cur.id && w.isManagement)
            ? acc.managementWorkspaces
            : acc.etcWorkspaces
          target.push(createPaths(path, cur, isWorkPlan))
          return acc
        },
        { managementWorkspaces: [], etcWorkspaces: [] } as {
          managementWorkspaces: MenuItemsType[]
          etcWorkspaces: MenuItemsType[]
        }
      )

      return {
        items: [
          {
            label: '管理中',
            paths: managementWorkspaces,
          },
          {
            label: 'その他',
            paths: etcWorkspaces,
          },
        ],
      }
    },
    [createPaths, user.userHasTenants, partialWorkspaces]
  )

  const createWorkPlanProps = React.useCallback(
    (path: string) => ({
      previousItem: {
        label: '日付一覧へ',
        path: `/schedules/${workspaceId}`,
      },
      ...createProps(path, true),
    }),
    [createProps, workspaceId]
  )

  const isPathMatching = React.useCallback((path: string) => !!matchPath({ path }, pathname), [pathname])

  const sideBarProps = React.useMemo(() => {
    const pages: Page[] = [
      { path: '/tenants', sidebar: tenantsProps },
      { path: '/tenants/:tenantId/detail', sidebar: tenantsDetailProps },
      { path: '/tenants/:tenantId/users', sidebar: tenantsUsersProps },
      { path: '/tenants/:tenantId/services', sidebar: tenantsServicesProps },
      { path: '/users', sidebar: userProps },
      { path: '/dashboard', sidebar: dashboardProps },
      { path: '/dashboard/balance-of-payments', sidebar: dashboardProps },
      { path: '/dashboard/:workspaceId', sidebar: dashboardProps },
      { path: '/dashboard/:workspaceId/workspace', sidebar: dashboardProps },
      { path: '/dashboard/:workspaceId/performance-list', sidebar: dashboardProps },
      { path: '/dashboard/:workspaceId/performance-graph/workspace', sidebar: dashboardProps },
      {
        path: '/dashboard/:workspaceId/performance-graph/groups/:groupId',
        sidebar: dashboardProps,
      },
      { path: '/dashboard/:workspaceId/performance-graph/workers', sidebar: dashboardProps },
      {
        path: '/dashboard/:workspaceId/performance-graph/workers/:workerId',
        sidebar: dashboardProps,
      },
      { path: '/assignment', sidebar: createProps('/assignment/:workspaceId') },
      { path: '/assignment/:workspaceId', sidebar: createProps('/assignment/:workspaceId') },
      { path: '/workers', sidebar: workersProps },
      { path: '/shifts', sidebar: workersProps },
      { path: '/skills', sidebar: workersProps },
      { path: '/workspaces', sidebar: workspacesProps },
      { path: '/workspaces/:workspaceId', sidebar: workspaceProps },
      { path: '/workspaces/:workspaceId/edit', sidebar: workspaceProps },
      { path: '/workspaces/:workspaceId/groups', sidebar: workspaceProps },
      { path: '/workspaces/:workspaceId/allocation', sidebar: workspaceProps },
      { path: '/workspaces/:workspaceId/templates', sidebar: workspaceProps },
      { path: '/schedules', sidebar: createProps('/schedules/:workspaceId') },
      { path: '/schedules/:workspaceId', sidebar: createProps('/schedules/:workspaceId') },
      { path: '/schedules/:workspaceId/:workDate', sidebar: createWorkPlanProps('/schedules/:workspaceId/:workDate') },
      { path: '/reports', sidebar: createProps('/reports/:workspaceId') },
      { path: '/reports/:workspaceId', sidebar: createProps('/reports/:workspaceId') },
      {
        path: '/reports/:workspaceId/:date',
        sidebar: createProps('/reports/:workspaceId/:date'),
      },
      { path: '/account/edit', sidebar: accountSettingsProps },
      { path: '/company/edit', sidebar: accountSettingsProps },
      { path: '/balance-of-payments', sidebar: bopProps },
      { path: '/hourly-wage', sidebar: bopProps },
      { path: '/bop-reports/bop', sidebar: bopReportsProps },
      { path: '/bop-reports/profit-and-loss', sidebar: bopReportsProps },
    ]

    return pages.find(page => isPathMatching(page.path))?.sidebar || {}
  }, [
    tenantsDetailProps,
    tenantsUsersProps,
    tenantsServicesProps,
    dashboardProps,
    createProps,
    workspaceProps,
    createWorkPlanProps,
    accountSettingsProps,
    bopProps,
    bopReportsProps,
    isPathMatching,
  ])

  return { sideBarProps, isPathMatching }
}
export default usePages
