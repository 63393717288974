import axios from 'axios'

import type { CommonParams } from 'slices/utils'

import type { TenantData } from './tenants'
import type { WorkspaceData } from './workspaces'

export type CostArray = {
  name: string
  cost: number
}

export type UnitCostsArray = {
  scheduleTypeId: number
  scheduleTypeName: string
  unit: string
  unitCost: number
  materialCosts: CostArray[]
}

export type ScheduleTypesArray = {
  name: string
  color: string
}

export type BopData = {
  workDate: string
  revision: number
  unitCosts: UnitCostsArray[]
  otherSales: CostArray[]
  managementCosts: CostArray[]
  extraCostScheduleTypes: ScheduleTypesArray[]
  workspaceCosts: CostArray[]
  updatedAt: string
  updatedByName: string
}

export type BopResponse = {
  workDate: string
  tenant: TenantData
  workspace: WorkspaceData
  bop: BopData
}

type UnitCostsArrayForRequest = {
  scheduleTypeId: number
  unitCost: number
  materialCosts: CostArray[]
}

export type UpdateBopRequest = {
  workDate?: string
  unitCosts: UnitCostsArrayForRequest[]
  otherSales: CostArray[]
  managementCosts: CostArray[]
  workspaceCosts: CostArray[]
}

export type UpdateBopResponse = {
  revision: number
  workDate: string
  updatedAt: string
  updatedByName: string
}

export const getBop = (params: CommonParams, workspaceId: number): Promise<BopResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces/${workspaceId}/bop`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const updateBop = (
  params: CommonParams,
  workspaceId: number,
  requestBody: UpdateBopRequest
): Promise<UpdateBopResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces/${workspaceId}/bop`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, requestBody, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
