import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import * as API from 'api/template'
import {
  makeErrorMessage,
  UNREACHABLE_ERROR_STATUS_CODE,
  UNAUTHORIZED_ERROR_STATUS_CODE,
  NOT_FOUND_ERROR_STATUS_CODE,
  CONFLICT_ERROR_STATUS_CODE,
  BAD_REQUEST_ERROR_STATUS_CODE,
} from 'api/utils'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import { validateToken } from 'slices/sessionSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import * as Spinner from 'slices/spinnerSlice'
import { commonParams } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type TemplateDetail = {
  id: number
  name: string
  ids: ({ id: number; isSupport: boolean } | null)[]
  updatedAt: string
  updatedByName: string
}

type TemplateState = {
  isRequesting: boolean
  errorMessage: string
  template?: TemplateDetail
  templateList: API.TemplateDetails[]
  templateWorkspaceId: number | undefined
}

const initialState: TemplateState = {
  isRequesting: false,
  errorMessage: '',
  templateList: [],
  templateWorkspaceId: undefined,
}

export const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getTemplateListSuccess: (
      state,
      action: PayloadAction<{ response: API.TemplateListResponse; workspaceId: number }>
    ) => {
      state.isRequesting = false
      state.templateList = _.sortBy(action.payload.response.templates, 'name')
      state.templateWorkspaceId = action.payload.workspaceId
    },
    getTemplateDataSuccess: (state, action: PayloadAction<API.TemplateDataResponse & { workspaceId: number }>) => {
      state.isRequesting = false
      const ids = action.payload.schedules.map((scheduleId, i) => {
        const workspaceId = action.payload.workspaces[i]
        const id = scheduleId || workspaceId
        return id ? { id, isSupport: workspaceId !== action.payload.workspaceId } : null
      })
      state.template = { ...action.payload, ids }
    },
    updateTemplateDataSuccess: state => {
      state.isRequesting = false
    },
  },
})

export const { startRequest, apiFailure, getTemplateListSuccess, getTemplateDataSuccess, updateTemplateDataSuccess } =
  templateSlice.actions
export const getTemplateList =
  (workspaceId: number, callback?: (templateList: API.TemplateDetails[]) => void): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getTemplateList(commonParams(getState), workspaceId)
      .then((res: API.TemplateListResponse) => {
        dispatch(getTemplateListSuccess({ response: res, workspaceId }))
        callback?.(res.templates)
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }
export const getTemplateData =
  (workspaceId: number, templateId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getTemplateData(commonParams(getState), workspaceId, templateId)
      .then((res: API.TemplateDataResponse) => dispatch(getTemplateDataSuccess({ ...res, workspaceId })))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === NOT_FOUND_ERROR_STATUS_CODE) {
          return dispatch(apiFailure({ errorMessage: '存在しないテンプレートです｡' }))
        }
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

const SAME_TEMPLATE_NAME_ERROR = 'this name is already taken'
const TEMPLATE_NAME_LENGTH_ERROR = 'this name is incorrect'
const TEMPLATE_LIMIT_ERROR = 'upper limit of 20 has been reached'

export const updateTemplateData =
  (workspaceId: number, data: API.TemplateProps): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateTemplateData(commonParams(getState), workspaceId, data)
      .then(() => dispatch(updateTemplateDataSuccess()))
      .catch((res: AxiosError<{ message: string }>) => {
        const errorMessage = res.response?.data?.message
        const errorCode = makeErrorMessage(res)

        if (errorCode === CONFLICT_ERROR_STATUS_CODE && errorMessage === TEMPLATE_LIMIT_ERROR) {
          return dispatch(
            apiFailure({ errorMessage: '登録可能なテンプレート数を超えているため､登録できませんでした｡' })
          )
        }
        if (errorCode === CONFLICT_ERROR_STATUS_CODE && errorMessage === SAME_TEMPLATE_NAME_ERROR) {
          return dispatch(
            apiFailure({
              errorMessage: 'すでにこの名前のテンプレートは存在しています。',
            })
          )
        }
        if (errorCode === BAD_REQUEST_ERROR_STATUS_CODE && errorMessage === TEMPLATE_NAME_LENGTH_ERROR) {
          return dispatch(
            apiFailure({ errorMessage: 'テンプレート名の文字数が不正です｡1文字以上､20文字以下で登録してください｡' })
          )
        }
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const selectTemplateStatus = (state: RootState) => ({ ...state.template })

export default templateSlice.reducer
