import axios from 'axios'

import type { CommonParams } from 'slices/utils'

import type { PartialTenantData, TenantData } from './tenants'
import type { CreateResponse, DeleteResponse, UpdateResponse } from './types'

export type PartialOfficialDutiesListData = {
  id: number
  name: string
  workDate: string
  revision: number
  updatedAt: string
  updatedByName: string
}

export type OfficialDutiesListResponse = {
  partialTenant: PartialTenantData
  partialOfficialDutiesList: PartialOfficialDutiesListData[]
}

export type OfficialDutiesData = PartialOfficialDutiesListData & {
  averageHourlyWage: number
}

export type OfficialDutiesDetailResponse = {
  workDate: string
  tenant: TenantData
  officialDuties: OfficialDutiesData
}

export type OfficialDutiesEditData = {
  name: string
  averageHourlyWage: number
}

export const getOfficialDutiesList = (params: CommonParams): Promise<OfficialDutiesListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/official-duties`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getOfficialDuties = (params: CommonParams, id: number): Promise<OfficialDutiesDetailResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/official-duties/${id}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createOfficialDuties = (params: CommonParams, data: OfficialDutiesEditData): Promise<CreateResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/official-duties`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const updateOfficialDuties = (
  params: CommonParams,
  id: number,
  data: OfficialDutiesEditData
): Promise<UpdateResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/official-duties/${id}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const deleteOfficialDuties = (params: CommonParams, id: number): Promise<DeleteResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/official-duties/${id}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .delete(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
