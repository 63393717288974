import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Row } from 'reactstrap'

import { getTenantList, selectTenantsStatus } from 'slices/tenantsSlice'

import { NavMenu, Pagination, Table } from 'components/common'
import type { TableHeaderType, TableCellType } from 'components/common/types'

const PER_PAGE_NUMBER = 20

const header: TableHeaderType[] = [
  { value: 'テナント ID', width: '10%' },
  { value: '企業名', width: '20%', className: 'border-end' },
  { value: '事業所名', width: '30%' },
  { value: 'ご利用状況', width: '10%' },
  { value: '担当者', width: '30%' },
]

const Tenants: React.FC = () => {
  const [page, setPage] = React.useState(1)
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(getTenantList())
  }, [dispatch])

  const { partialTenants } = useSelector(selectTenantsStatus, shallowEqual)

  const tableData: TableCellType[][] = React.useMemo(() => {
    return partialTenants.map(tenant => [
      { value: tenant.id.toString() },
      { value: tenant.name, className: 'border-end' },
      { value: tenant.salesOfficeName || '' },
      {
        value: tenant.isActive ? '利用中' : '利用停止中',
        className: tenant.isActive ? 'text-success' : 'text-danger',
      },
      { value: tenant.personInChargeName || '' },
    ])
  }, [partialTenants])

  const tableItems: TableCellType[][] = React.useMemo(() => {
    return tableData.slice((page - 1) * PER_PAGE_NUMBER, page * PER_PAGE_NUMBER)
  }, [page, tableData])
  const totalPages: number = React.useMemo(() => {
    return Math.ceil(tableData.length / PER_PAGE_NUMBER)
  }, [tableData])

  const navigate = useNavigate()
  const onNewTenantClick = () => navigate('/tenant-create')
  const onRowClick = (index: number) => {
    const tenantId = tableItems[index][0].value
    navigate(`/tenants/${tenantId}/detail`)
  }

  return (
    <NavMenu>
      <div className="mt-3 mx-3">
        <div className="d-flex justify-content-between">
          <div className="font-x-large fw-bold align-self-center">テナント一覧</div>
          <div className="bg-white rounded">
            <Button color="primary" className="d-flex align-items-center" onClick={onNewTenantClick}>
              <i className="icf-plus pe-2 font-large" />
              <div className="ps-1">テナント新規登録</div>
            </Button>
          </div>
        </div>
        <Row className="py-3">
          <div className="bg-white p-0">
            <Table header={header} body={tableItems} onRowClick={onRowClick} />
          </div>
          <div className="my-2 mx-auto d-flex justify-content-center">
            <Pagination totalPages={totalPages} currentPage={page} onClick={setPage} />
          </div>
        </Row>
      </div>
    </NavMenu>
  )
}

export default Tenants
