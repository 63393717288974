import * as React from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Card, CardBody, Col, Row, FormGroup, Label } from 'reactstrap'

import { showError, showSuccess } from 'slices/notificationSlice'
import { getPostcodeList } from 'slices/postcodeSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { clearErrorMessage, selectTenantsStatus, updateTenant } from 'slices/tenantsSlice'

import EditChangesDiscardDialog from 'components/EditChangesDiscardDialog/EditChangesDiscardDialog'
import {
  NavMenu,
  InputFormat,
  PostalCodeInputForm,
  SelectBoxFormat,
  TimeSelect,
  CardSubmitFooter,
} from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import { prefectureItems, ColumnSizes } from 'components/common/utils'

import useTenant from 'hooks/useTenant'

const CompanyEdit: React.FC = () => {
  const [submitted, setSubmitted] = React.useState(false)
  const [openEditChangesDiscardDialog, setOpenEditChangesDiscardDialog] = React.useState(false)
  const { isRequesting, errorMessage } = useSelector(selectTenantsStatus, shallowEqual)

  const { user } = useSelector(selectSessionStatus, shallowEqual)
  const tenantId = user.userHasTenants[0].id
  const dispatch = useDispatch()
  const {
    initEditData,
    editData,
    targetPostalCode,
    businessTimeValidity,
    unchanged,
    disabled,
    setEditData,
    setMunicipalityValidity,
    setNameValidity,
    setOtherAddressValidity,
    setPhoneNumberValidity,
    setPostalCodeValidity,
    setPrefectureValidity,
    setPersonInChargeValidity,
  } = useTenant(tenantId)

  React.useEffect(() => {
    dispatch(getPostcodeList())
  }, [dispatch, tenantId])

  const onSubmit = () => {
    setSubmitted(true)
    dispatch(updateTenant(tenantId, editData))
  }

  // オーナー管理画面でテナント情報編集時は担当者を変更できないため、バリデーションをtrueにする
  React.useEffect(() => {
    setPersonInChargeValidity(true)
  }, [setPersonInChargeValidity])

  const onAddressAutoInput = () => {
    setEditData({
      ...editData,
      prefecture: targetPostalCode?.address1 || '',
      municipality: targetPostalCode?.address2 || '',
      otherAddress: targetPostalCode?.address3 || '',
    })
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
    } else {
      dispatch(showError())
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch])

  const handleEditChangesDiscard = () => {
    setOpenEditChangesDiscardDialog(false)
    setEditData(initEditData)
  }

  return (
    <>
      <NavMenu>
        <div className="m-3">
          <Row className="g-0 mb-3">
            <Col className="font-x-large fw-bold">ご登録企業情報</Col>
          </Row>
          <Card>
            <CardBody>
              <Row>
                <Col md={8}>
                  <div className="d-flex justify-content-between py-1">
                    <div className="font-middle fw-bold">ご登録企業の詳細</div>
                    <small>※必須項目</small>
                  </div>
                  <div className="mt-2 mb-4">
                    ご登録時に設定いただいた組織情報が設定されております。変更があった際には下記で編集をお願いいたします。
                  </div>

                  <InputFormat
                    label="企業名※"
                    placeholder="企業名を入力"
                    maxLength={200}
                    value={editData.name}
                    onChange={value => setEditData({ ...editData, name: value })}
                    validations={[Rules.Required]}
                    onValidate={setNameValidity}
                    className="mb-3"
                  />
                  <InputFormat
                    label="事業所名"
                    placeholder="事業所名を入力"
                    value={editData.salesOfficeName ?? ''}
                    maxLength={100}
                    onChange={value => setEditData({ ...editData, salesOfficeName: value === '' ? null : value })}
                    className="mb-3"
                  />
                  <InputFormat
                    label="電話番号※"
                    placeholder="電話番号を入力"
                    value={editData.phoneNumber}
                    size={ColumnSizes.short}
                    maxLength={20}
                    onChange={value => setEditData({ ...editData, phoneNumber: value })}
                    validations={[Rules.Required, Rules.PhoneNumber]}
                    onValidate={setPhoneNumberValidity}
                  />

                  <div className="font-middle fw-bold mt-5 mb-3">事業所住所</div>
                  <PostalCodeInputForm
                    label="郵便番号※"
                    value={editData.postalCode}
                    onChange={value => setEditData({ ...editData, postalCode: value })}
                    validations={[Rules.Required, Rules.PostalCode]}
                    onValidate={setPostalCodeValidity}
                    onAddressAutoInput={onAddressAutoInput}
                    disabledAddressAutoInput={!targetPostalCode}
                  />
                  <SelectBoxFormat
                    label="都道府県※"
                    placeholder="都道府県を選択"
                    value={editData.prefecture}
                    size={ColumnSizes.short}
                    items={prefectureItems}
                    onChange={e => setEditData({ ...editData, prefecture: e.value })}
                    validations={[Rules.Required]}
                    onValidate={setPrefectureValidity}
                    className="mb-3"
                  />
                  <InputFormat
                    label="市区町村※"
                    placeholder="市区町村を入力"
                    value={editData.municipality}
                    maxLength={100}
                    onChange={value => setEditData({ ...editData, municipality: value })}
                    validations={[Rules.Required]}
                    onValidate={setMunicipalityValidity}
                    className="mb-3"
                  />
                  <InputFormat
                    label="それ以降の住所※"
                    value={editData.otherAddress}
                    maxLength={100}
                    onChange={value => setEditData({ ...editData, otherAddress: value })}
                    validations={[Rules.Required]}
                    onValidate={setOtherAddressValidity}
                  />

                  <div className="font-middle fw-bold mt-5 mb-3">営業時間</div>
                  <div className="mt-2 mb-4">
                    事業所の営業時間を設定してください。設定した営業時間内で予定を設定する事ができます。早朝の出勤や残業なども考慮して設定していただく事をお勧めします。
                  </div>
                  <FormGroup row>
                    <Label md={4}>営業開始時間※</Label>
                    <Col md={8}>
                      <TimeSelect
                        hour={editData.businessStartHour}
                        minute={editData.businessStartMinute}
                        label="から"
                        onChange={(hour, minute) =>
                          setEditData({ ...editData, businessStartHour: hour, businessStartMinute: minute })
                        }
                        menuPosition="top"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={4}>営業終了時間※</Label>
                    <Col md={8}>
                      <TimeSelect
                        hour={editData.businessEndHour}
                        minute={editData.businessEndMinute}
                        label="まで"
                        onChange={(hour, minute) =>
                          setEditData({ ...editData, businessEndHour: hour, businessEndMinute: minute })
                        }
                        menuPosition="top"
                      />
                      <div className={`invalid-feedback ${!businessTimeValidity && 'd-block'}`}>
                        営業終了時間は営業開始時間より後の時間を設定してください
                      </div>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>

            <CardSubmitFooter
              onCancel={() => setOpenEditChangesDiscardDialog(true)}
              onSubmit={onSubmit}
              cancelDisabled={unchanged}
              submitDisabled={unchanged || disabled}
            />
          </Card>
        </div>
      </NavMenu>

      <EditChangesDiscardDialog
        isOpen={openEditChangesDiscardDialog}
        onCancel={() => setOpenEditChangesDiscardDialog(false)}
        onDiscard={handleEditChangesDiscard}
      />
    </>
  )
}

export default CompanyEdit
