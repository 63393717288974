import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Button, Card, CardBody, CardTitle, Col, Row } from 'reactstrap'

import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'

import { showError, showSuccess } from 'slices/notificationSlice'
import { updateSkill, selectSkillsStatus, getSkillList, getSkill } from 'slices/skillsSlice'
import { getWorkspaceList } from 'slices/workspacesSlice'

import { InputFormat, List, CardSubmitFooter, CustomButton, NavMenu } from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import type { ListItem } from 'components/common/types'
import { ColumnSizes } from 'components/common/utils'

import placeholder from 'images/allEmpty.svg'

import SkillCreate from './SkillCreate'
import SkillDelete from './SkillDelete'

import styles from './Skills.module.scss'

const Skills: React.FC = () => {
  const [skillNameValidity, setSkillNameValidity] = React.useState(false)
  const [skillName, setSkillName] = React.useState<string | undefined>(undefined)
  const [selectedSkillId, setSelectedSkillId] = React.useState<number | undefined>(undefined)
  const [openCreate, setOpenCreate] = React.useState(false)
  const [openDelete, setOpenDelete] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const [skillItems, setSkillItems] = React.useState<ListItem[]>([])
  const hasSkillItems = React.useMemo(() => !_.isEmpty(skillItems), [skillItems])

  const dispatch = useDispatch()

  const { skills, skill, isRequesting, errorMessage } = useSelector(selectSkillsStatus, shallowEqual)

  React.useEffect(() => {
    dispatch(getSkillList())
    dispatch(getWorkspaceList())
  }, [dispatch])

  React.useEffect(() => {
    if (!selectedSkillId) {
      return
    }
    // selectedSkillIdが変化した時、個別データを取得する
    dispatch(getSkill(selectedSkillId))
  }, [selectedSkillId, dispatch])

  // skillから、フォームに値を設定する
  React.useEffect(() => {
    if (!skill) {
      return
    }
    setSkillName(skill.name)
  }, [skill])

  const initSkillItems = React.useCallback(() => {
    setSkillItems(
      skills.map(s => ({
        id: s.id,
        title: s.name || '',
      }))
    )
  }, [skills])

  // skillsのレスポンス更新時にskillItemsを初期化する
  React.useEffect(() => {
    initSkillItems()
  }, [initSkillItems])

  React.useEffect(() => {
    setSelectedSkillId(prev => {
      // 0件の場合、undefinedで未登録画面を表示させる
      if (_.isEmpty(skillItems)) {
        return undefined
      }
      // 初期化時とアイテム削除時
      if (prev === undefined || !skillItems.some(item => item.id === prev)) {
        return Number(skillItems[0].id)
      }
      return prev
    })
  }, [skillItems])

  const disabled = React.useMemo(() => !(skillName && skillNameValidity), [skillName, skillNameValidity])
  const unchanged = React.useMemo(() => skillName === skill?.name, [skill, skillName])

  const handleCreate = () => {
    setOpenCreate(false)
    dispatch(showSuccess())
  }

  const handleDelete = () => {
    setOpenDelete(false)
    dispatch(showSuccess())
  }

  const onCancel = React.useCallback(() => {
    initSkillItems()
    setSelectedSkillId(skill?.id)
    setSkillName(skill?.name)
  }, [skill, initSkillItems])

  const onSubmit = React.useCallback(() => {
    if (selectedSkillId && skillName) {
      // 更新
      dispatch(updateSkill(selectedSkillId, skillName))
      setSubmitted(true)
    } else {
      dispatch(showError())
    }
  }, [selectedSkillId, skillName, dispatch])

  const onDetailClick = () => {
    window.open('https://help.smileboard.jp/skills', '_blank')
  }

  React.useEffect(() => {
    if (isRequesting || !submitted) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
    } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
      dispatch(showError())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch])

  return (
    <NavMenu>
      <div className="mt-3 mx-3">
        <div className="d-flex justify-content-between mb-3">
          <div className="font-x-large fw-bold align-self-center">スキル管理</div>
          <CustomButton icon="plus" outline onClick={() => setOpenCreate(true)}>
            スキル追加
          </CustomButton>
        </div>

        <Row className={styles.row}>
          <Col md={4} className="h-100">
            <Card className={`h-100 ${styles.list}`}>
              {hasSkillItems ? (
                <List
                  items={skillItems}
                  selectedId={selectedSkillId}
                  onAction={((skillId: number) => setSelectedSkillId(skillId)) as (s: string | number) => void}
                />
              ) : (
                <CardBody className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img className={`mx-auto d-block w-100 ${styles.placeholderImage}`} src={placeholder} alt="" />
                    <div className="font-middle fw-bold py-4">スキルがまだ登録されていません</div>
                    <div>まずは最初のスキルを登録してみましょう。</div>
                    <Button className="mx-auto d-block m-4" size="sm" outline onClick={onDetailClick}>
                      スキルについてもっと詳しく
                    </Button>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
          <Col md={8} className="h-100">
            <Card className="h-100 d-flex flex-column">
              {hasSkillItems ? (
                <>
                  <CardBody className="flex-grow-0">
                    <CardTitle className="font-large fw-bold">スキル情報</CardTitle>
                    <InputFormat
                      label="名前※"
                      placeholder="名前を入力"
                      value={skillName}
                      validations={[Rules.Required]}
                      maxLength={100}
                      size={ColumnSizes.middle}
                      onChange={value => setSkillName(value)}
                      onValidate={setSkillNameValidity}
                    />
                  </CardBody>

                  <CardBody className="flex-grow-1">
                    <CardTitle className="font-large fw-bold">スキルの削除</CardTitle>
                    <Button outline color="danger" className="my-3" onClick={() => setOpenDelete(true)}>
                      このスキルを削除
                    </Button>
                  </CardBody>
                  <div className="flex-grow-0">
                    <CardSubmitFooter
                      onCancel={onCancel}
                      onSubmit={onSubmit}
                      updatedBy={skill?.updatedByName}
                      updatedAt={skill?.updatedAt}
                      cancelDisabled={unchanged}
                      submitDisabled={unchanged || disabled}
                    />
                  </div>
                </>
              ) : (
                <CardBody className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholder} alt="" />
                    <div className="font-middle fw-bold py-4">スキルが選択されていません</div>
                    <div>スキルを選択して、詳細情報を編集しましょう。</div>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>

        <SkillCreate isOpen={openCreate} onSuccess={handleCreate} onCancel={() => setOpenCreate(false)} />

        {selectedSkillId && (
          <SkillDelete
            isOpen={openDelete}
            skillId={selectedSkillId}
            onSuccess={handleDelete}
            onCancel={() => setOpenDelete(false)}
          />
        )}
      </div>
    </NavMenu>
  )
}

export default Skills
