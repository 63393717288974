import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import type { ScheduleTypeEditDataType, ScheduleTypeEditData } from 'api/schedule_types'

import { selectTenantsStatus } from 'slices/tenantsSlice'

import { connectionTypes, ColorTypes } from 'components/common/utils'

// dataConnectionInfoを利用しているため、一度getDataConnectionInfo()を実行する必要あり
const useScheduleType = () => {
  const initScheduleTypeEditData = React.useMemo(
    () => ({
      name: undefined,
      color: ColorTypes.Silver,
      requiredSkills: [],
      connectionType: connectionTypes.None,
      dataConnection: false,
      targetDatabase: undefined,
      targetColumn: undefined,
      unit: undefined,
      filters: [],
      isKey: false,
      isCostless: false,
    }),
    []
  )

  const [editData, setEditData] = React.useState<ScheduleTypeEditData>(initScheduleTypeEditData)
  const [nameValidity, setNameValidity] = React.useState(false)
  const [targetDatabaseValidity, setTargetDatabaseValidity] = React.useState(false)
  const [targetColumnValidity, setTargetColumnValidity] = React.useState(false)
  const [unitValidity, setUnitValidity] = React.useState(false)
  const [defaultProductivityIndexValidity, setDefaultProductivityIndexValidity] = React.useState(false)

  const { dataConnectionInfo } = useSelector(selectTenantsStatus, shallowEqual)

  const disabled = React.useMemo(() => {
    if (!editData) {
      return true
    }

    if (editData.connectionType === connectionTypes.Manual) {
      return !(
        editData.name &&
        nameValidity &&
        editData.unit &&
        unitValidity &&
        editData.defaultProductivity &&
        defaultProductivityIndexValidity
      )
    }

    if (editData.connectionType === connectionTypes.Auto) {
      return !(
        editData.name &&
        nameValidity &&
        editData.targetDatabase &&
        targetDatabaseValidity &&
        editData.targetColumn &&
        targetColumnValidity &&
        editData.unit &&
        unitValidity &&
        editData.defaultProductivity &&
        defaultProductivityIndexValidity
      )
    }

    return !(editData.name && nameValidity)
  }, [
    editData,
    nameValidity,
    targetDatabaseValidity,
    targetColumnValidity,
    unitValidity,
    defaultProductivityIndexValidity,
  ])

  const scheduleTypeUpdateData = React.useMemo(() => {
    if (!editData) {
      return undefined
    }
    // key, valueが-の値を除外する
    const newFilter = editData.filters
      .map(parent => ({
        ...parent,
        data: parent.data.filter(row => row.key !== '-' && row.value !== '-'),
      }))
      .filter(parent => parent.data.length !== 0)
    const { dataConnection } = editData

    const data: ScheduleTypeEditDataType = {
      name: editData.name || '',
      color: editData.color,
      requiredSkillIds: editData.requiredSkills.map(skill => skill.id),
      dataConnection,
      connectionType: editData.connectionType,
      targetDatabase: (dataConnection && editData.targetDatabase) || null,
      targetColumn: (dataConnection && editData.targetColumn) || null,
      unit: (dataConnection && editData.unit) || null,
      filters: dataConnection ? newFilter : [],
      defaultProductivity: dataConnection ? Number(editData?.defaultProductivity) : null,
      isKey: editData.isKey,
      isCostless: editData.isCostless,
    }
    return data
  }, [editData])
  const targetDatabases = React.useMemo(() => {
    if (!dataConnectionInfo) {
      return []
    }
    return dataConnectionInfo.map(info => ({
      key: info.targetDatabase.databaseName,
      value: info.targetDatabase.displayName,
    }))
  }, [dataConnectionInfo])
  const targetColumns = React.useMemo(() => {
    if (!dataConnectionInfo || !editData?.targetDatabase) {
      return []
    }
    return dataConnectionInfo
      .filter(info => info.targetDatabase.databaseName === editData?.targetDatabase)
      .flatMap(info =>
        info.targetColumns.map(targetColumn => ({
          key: targetColumn.columnName,
          value: targetColumn.displayName,
        }))
      )
  }, [dataConnectionInfo, editData?.targetDatabase])
  const filterColumns = React.useMemo(() => {
    if (!dataConnectionInfo || !editData?.targetDatabase) {
      return []
    }
    return dataConnectionInfo
      .filter(info => info.targetDatabase.databaseName === editData?.targetDatabase)
      .flatMap(info => info.filterColumns)
  }, [dataConnectionInfo, editData?.targetDatabase])
  return {
    setNameValidity,
    setTargetDatabaseValidity,
    setTargetColumnValidity,
    setUnitValidity,
    setDefaultProductivityIndexValidity,
    disabled,
    editData,
    setEditData,
    scheduleTypeUpdateData,
    targetDatabases,
    targetColumns,
    filterColumns,
    initScheduleTypeEditData,
  }
}

export default useScheduleType
