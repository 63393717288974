import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { matchPath, useNavigate, useLocation } from 'react-router-dom'

import { notificationClear, selectNotificationStatus } from 'slices/notificationSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { selectTenantsStatus } from 'slices/tenantsSlice'

import { noHeaderPages } from 'components/App/pages'
import { Drawer, Notification } from 'components/common'

import useAuthority from 'hooks/useAuthority'
import useLogout from 'hooks/useLogout'

import HeaderNavbar from './HeaderNavbar'
import { DrawerItems, DropdownItems, DrawerLinks, TeamDrawerItems, TeamDropdownItems } from './menuItems'

const Header: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isProcessAdmin, isAdmin, isTenantAdmin } = useAuthority()
  const [open, setOpen] = React.useState(false)
  const { pathname } = useLocation()
  const { logout } = useLogout()

  const { loggedIn, user, team } = useSelector(selectSessionStatus, shallowEqual)
  const { tenant } = useSelector(selectTenantsStatus, shallowEqual)
  const { showError, showSuccess, showWarning, errorMessage, successMessage, warningMessage } = useSelector(
    selectNotificationStatus,
    shallowEqual
  )

  React.useEffect(() => {
    dispatch(notificationClear())
  }, [dispatch, pathname])

  const isNoHeader = React.useMemo(() => noHeaderPages.some(page => matchPath(page.path, pathname)), [pathname])

  // https://reactjs.org/docs/hooks-rules.html
  // 各種フックは条件分岐より前に書く必要がある

  if (!loggedIn || isNoHeader) {
    return null
  }

  const [drawerItems, drawerColumnSize, dropdownMenu, drawerLinks] =
    process.env.REACT_APP_TEAM_MODE === 'true' && (isAdmin || isProcessAdmin)
      ? [TeamDrawerItems(), 2, TeamDropdownItems(logout, navigate, team), undefined]
      : [DrawerItems(user), 3, DropdownItems(logout, navigate, user, isTenantAdmin, tenant), DrawerLinks()]

  const onNavbarTogglerClick = () => setOpen(!open)
  const items = drawerLinks ? drawerLinks.concat(drawerItems) : drawerItems
  const label = items.find(({ match }) => match.test(pathname))?.label

  return (
    <>
      <Drawer
        open={open}
        items={drawerItems}
        columnSize={drawerColumnSize}
        links={drawerLinks}
        onBackdropClick={onNavbarTogglerClick}
      />
      <HeaderNavbar label={label} dropdownMenu={dropdownMenu} onNavbarTogglerClick={onNavbarTogglerClick} />
      <Notification
        success={showSuccess}
        error={showError}
        warning={showWarning}
        errorMessage={errorMessage}
        successMessage={successMessage}
        warningMessage={warningMessage}
        hide={() => dispatch(notificationClear())}
      />
    </>
  )
}

export default Header
