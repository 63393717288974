import axios from 'axios'

import type { CommonParams } from 'slices/utils'

import type { PartialTenantData, TenantData } from './tenants'
import type { CreateResponse, UpdateResponse, DeleteResponse } from './types'

export type SkillResponseV1 = {
  skillId: number
  name: string
  updatedBy: string
  updatedAt: string
}

export type SkillData = {
  id: number
  workDate: string
  revision: number
  name: string
  updatedAt: string
  updatedByName: string
}

export type SkillResponse = {
  tenant: TenantData
  skill: SkillData
}

export type SkillListResponse = {
  partialTenant: PartialTenantData
  skills: SkillData[]
}

export const getSkillList = (params: CommonParams): Promise<SkillListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/skills`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getSkill = (params: CommonParams, skillId: number): Promise<SkillResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/skills/${skillId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createSkill = (params: CommonParams, name: string): Promise<CreateResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/skills`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, { name }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const updateSkill = (params: CommonParams, skillId: number, name: string): Promise<UpdateResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/skills/${skillId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, { name }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const deleteSkill = (params: CommonParams, skillId: number): Promise<DeleteResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/skills/${skillId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .delete(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
