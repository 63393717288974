import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'

import { deleteScheduleType, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'

import { CustomModal } from 'components/common'

type Props = {
  isOpen: boolean
  workspaceId: number
  scheduleTypeId: number | undefined
  onSuccess: () => void
  onCancel: () => void
}

const ScheduleTypeDelete: React.FC<Props> = ({ isOpen, workspaceId, scheduleTypeId, onSuccess, onCancel }) => {
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = React.useState(false)
  const dispatch = useDispatch()
  const { isRequesting, errorMessage } = useSelector(selectScheduleTypesStatus, shallowEqual)

  const handleDeleteClick = () => {
    if (scheduleTypeId) {
      setSubmitted(true)
      dispatch(deleteScheduleType(workspaceId, scheduleTypeId))
    }
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      onSuccess()
    } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
      setModalErrorMessage('削除できませんでした。')
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, onSuccess])

  const handleCancelClick = () => {
    setModalErrorMessage(undefined)
    setSubmitted(false)
    onCancel()
  }

  return (
    <CustomModal
      isOpen={isOpen}
      title="作業の削除"
      approveColor="danger"
      approveLabel="削除"
      errorMessage={modalErrorMessage}
      onCancel={handleCancelClick}
      onApprove={handleDeleteClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <div className="pb-4">この作業を本当に削除しますか？この操作は取り消せません。</div>
    </CustomModal>
  )
}

export default ScheduleTypeDelete
