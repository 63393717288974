import * as React from 'react'

import styles from './styles.module.scss'

export type Props = {
  label: string
  color?: string
  className?: string
  isFill?: boolean
  isDashBorder?: boolean
}

const BadgeLabel: React.FC<Props> = ({ label, color, className, isFill = true, isDashBorder = false }) => {
  const bgColor = isFill ? `bg-${color}` : ''
  const square = isDashBorder ? `${styles.dashedSquare} border-${color}` : styles.square

  return (
    <div className={`d-flex mw-100 user-select-none ${styles.toggleButtonDisabled} ${className}`}>
      {color && <div className={`${square} ${bgColor} me-1 align-self-center`}></div>}
      <div className="text-truncate">{label}</div>
    </div>
  )
}

export default BadgeLabel
