import _ from 'lodash'
import * as React from 'react'

import type { RoleType, UserHasTenantManagedWorkspace } from 'api/users'

export type EditAuthorizationProps = {
  role: RoleType
  canViewBOP: boolean
  canManageBOP: boolean
}

export type EditPermissionProps = {
  role: RoleType
  canViewBOP: boolean
  canManageBOP: boolean
  managedWorkspaces: UserHasTenantManagedWorkspace[]
}

const useUser = () => {
  const [editPermissionData, setEditPermissionData] = React.useState<EditPermissionProps | undefined>()

  const disabled = React.useMemo(() => !editPermissionData, [editPermissionData])

  const managedWorkspaceCheckBoxItems = React.useMemo(
    () =>
      _.sortBy(editPermissionData?.managedWorkspaces, 'name').map(workspace => ({
        id: workspace.id,
        label: workspace.name,
        checked: workspace.isManagement,
      })),
    [editPermissionData]
  )

  const bopManagementChangeBoxItems = React.useMemo(() => {
    if (!editPermissionData) {
      return []
    }

    return [
      {
        id: 'bopView',
        label: '収支閲覧',
        checked: editPermissionData.canViewBOP,
      },
      {
        id: 'bopManage',
        label: '収支管理',
        checked: editPermissionData.canManageBOP,
      },
    ]
  }, [editPermissionData])

  return {
    editPermissionData,
    setEditPermissionData,
    disabled,
    managedWorkspaceCheckBoxItems,
    bopManagementChangeBoxItems,
  }
}
export default useUser
