import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Button, Card, CardBody, CardText } from 'reactstrap'

import type { HourlyProductivities } from 'api/workers'

import { showSuccess } from 'slices/notificationSlice'
import { selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import { InputGroupFormat } from 'components/common/FormFormat/FormFormat'
import { ColumnSizes, connectionTypes } from 'components/common/utils'

export type Props = {
  workerId?: number
  forecastColorScheduleTypeIds?: number[]
  onFocus?: (forecastColorScheduleTypeIds: number[]) => void
  hourlyProductivities: HourlyProductivities[]
  onChange: (hourlyProductivities: HourlyProductivities[]) => void
}

const HourlyProductivitiesInput: React.FC<Props> = ({
  workerId,
  forecastColorScheduleTypeIds,
  onFocus,
  hourlyProductivities,
  onChange,
}) => {
  const dispatch = useDispatch()
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { allScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)

  const averageProductivitiesDisabled = React.useMemo(() => !workerId, [workerId])

  // 自動実績のみ自動入力が可能なので、自動実績が存在するかどうかを判定
  const connectionTypeAutoExistsInTenant = React.useMemo(
    () => allScheduleTypes.some(s => s.connectionType === connectionTypes.Auto),
    [allScheduleTypes]
  )

  const getBackgroundColor = React.useCallback(
    (scheduleTypeId: number) =>
      forecastColorScheduleTypeIds?.includes(scheduleTypeId) ? 'rgba(0,117,227, 0.1)' : undefined,
    [forecastColorScheduleTypeIds]
  )

  const onSetAverageProductivities = () => {
    // 自動入力したinputの背景色を変更
    const changedValue =
      hourlyProductivities.filter(p => p.average !== null && p.value !== p.average).flatMap(p => p.scheduleTypeId) || []
    onFocus && onFocus(changedValue)

    onChange(hourlyProductivities.map(p => (p.average === null ? p : { ...p, value: p.average })))
    dispatch(showSuccess({ successMessage: '過去実績を自動入力しました。' }))
  }

  const handleChange = (value: string, scheduleTypeId: number) => {
    onChange(
      allHourlyProductivities
        .map(p => {
          if (p.scheduleTypeId !== scheduleTypeId) {
            return p
          }
          return {
            ...p,
            value: value === '' ? null : Number(value) || p.value,
          }
        })
        .filter(productivity => productivity.value !== null)
    )
  }

  const handleFocus = (scheduleTypeId: number) => {
    if (onFocus && forecastColorScheduleTypeIds) {
      onFocus(forecastColorScheduleTypeIds.filter(id => id !== scheduleTypeId))
    }
  }

  const allHourlyProductivities = React.useMemo(
    () =>
      allScheduleTypes.map(
        st =>
          hourlyProductivities?.find(w => w.scheduleTypeId === st.id) ?? {
            scheduleTypeId: st.id,
            value: null,
            average: null,
          }
      ),
    [allScheduleTypes, hourlyProductivities]
  )

  const listItem = React.useMemo(() => {
    if (!partialWorkspaces || allScheduleTypes.length === 0) {
      return []
    }
    return partialWorkspaces
      .map(pw => {
        const target = allScheduleTypes
          .filter(st => pw.id === st.workspaceId)
          .map(st => ({ ...st, hourlyProductivities: allHourlyProductivities.find(p => p.scheduleTypeId === st.id) }))

        const targetScheduleTypesSortedByName = _.sortBy(target, 'name')

        return {
          ...pw,
          targetScheduleTypesSortedByName,
        }
      })
      .filter(w => w.targetScheduleTypesSortedByName.length > 0)
  }, [allScheduleTypes, partialWorkspaces, allHourlyProductivities])

  const averageProductivitiesDisabledAutoInput = React.useMemo(
    () => allHourlyProductivities.every(p => p.average === null),
    [allHourlyProductivities]
  )

  return (
    <Card>
      {connectionTypeAutoExistsInTenant && !averageProductivitiesDisabled && (
        <CardBody className="d-flex">
          <div className="flex-grow-1 align-self-center">過去実績最大30日の平均値を自動入力</div>
          <Button outline onClick={onSetAverageProductivities} disabled={averageProductivitiesDisabledAutoInput}>
            過去実績から自動入力
          </Button>
        </CardBody>
      )}

      {listItem.map(w => (
        <div key={w.id}>
          <hr className="m-0" />
          <CardBody>
            <CardText className="fw-bold">{w.name}</CardText>
            {w?.targetScheduleTypesSortedByName.map((s, i) => (
              <InputGroupFormat
                key={s.id}
                value={s.hourlyProductivities?.value?.toString() || ''}
                maxLength={8}
                labelSize={ColumnSizes.middle}
                size={ColumnSizes.middle}
                label={s.name}
                addonText={`${s.unit || '-'}/時間`}
                onChange={value => handleChange(value, s.id)}
                backgroundColor={getBackgroundColor(s.id)}
                onFocus={() => handleFocus(s.id)}
                className={i === w.targetScheduleTypesSortedByName.length - 1 ? '' : 'mb-3'}
              />
            ))}
          </CardBody>
        </div>
      ))}
    </Card>
  )
}

export default HourlyProductivitiesInput
