import * as React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import { allocationTypes } from 'api/allocations'
import type { EditAllocationProps, EditKeyScheduleType } from 'api/allocations'
import type { PartialScheduleTypeData } from 'api/schedule_types'

import { selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'

type SelectItemType = {
  key: string
  value: string
}

const useWorkspaceAllocation = () => {
  const NEW_ALLOCATION_ID = 0
  const NEW_KEY_SCHEDULE_ID = 0

  const allocationTypeData = [
    { key: allocationTypes.Manual, value: '手動按分' },
    { key: allocationTypes.Auto, value: '自動按分' },
  ]

  const [editData, setEditData] = React.useState<EditAllocationProps | undefined>()
  const [allocationNameValidity, setAllocationNameValidity] = React.useState(false)
  const [targetScheduleTypeValidity, setTargetScheduleTypeValidity] = React.useState(false)
  const [allocationTypeValidity, setAllocationTypeValidity] = React.useState(false)
  const [allocationRatioTypeValidity, setAllocationRatioTypeValidity] = React.useState(true)
  const [remainderScheduleTypeValidity, setRemainderScheduleTypeValidity] = React.useState(true)

  const { partialScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)

  const disabled = React.useMemo(() => {
    if (!editData || editData.keys.some(item => !item.scheduleTypeId)) {
      return true
    }

    // 手動按分の按分割合の合計チェック
    if (editData.type === allocationTypes.Manual) {
      if (editData.keys.some(item => Number(item.ratio) <= 0)) {
        return true
      }

      const sumRatio = editData.keys.reduce((acc: number, cur: EditKeyScheduleType) => {
        return acc + Number(cur.ratio)
      }, 0)

      if (sumRatio !== 100) {
        return true
      }
    }

    return !(
      editData.name &&
      allocationNameValidity &&
      targetScheduleTypeValidity &&
      allocationTypeValidity &&
      (editData.type === allocationTypes.Auto ? true : allocationRatioTypeValidity) &&
      remainderScheduleTypeValidity
    )
  }, [
    editData,
    allocationNameValidity,
    targetScheduleTypeValidity,
    allocationTypeValidity,
    allocationRatioTypeValidity,
    remainderScheduleTypeValidity,
  ])

  const keyScheduleTypes = React.useMemo(
    () => partialScheduleTypes?.filter(s => s.isKey).map(key => ({ key: key.id.toString(), value: key.name })) || [],
    [partialScheduleTypes]
  )

  const targetKeyScheduleTypes = React.useCallback(
    (selectedId: number | undefined) => {
      if (!partialScheduleTypes) {
        return []
      }

      return partialScheduleTypes.reduce((acc: SelectItemType[], cur: PartialScheduleTypeData): SelectItemType[] => {
        if (!cur.isKey) {
          return acc
        }

        // キー作業のプルダウンメニューで選択済みのキー作業であるかの判定
        // trueの場合はプルダウンメニューでの選択対象として表示されない
        // ただし該当プルダウンメニューで選択中のものは除く(表示対象となる)
        const isSelectedByOthers = editData?.keys.some(item => {
          const isNotSelectedId = item.scheduleTypeId !== selectedId
          const isIncludeKeyScheduleId = item.scheduleTypeId === cur.id

          return isNotSelectedId && isIncludeKeyScheduleId
        })

        if (isSelectedByOthers) {
          return acc
        }

        acc.push({ key: cur.id.toString(), value: cur.name })
        return acc
      }, [])
    },
    [partialScheduleTypes, editData]
  )

  const remainKeyScheduleTypes = React.useMemo(() => {
    if (!editData) {
      return []
    }

    return editData.keys.reduce((acc: SelectItemType[], cur: EditKeyScheduleType): SelectItemType[] => {
      const scheduleTypeData = partialScheduleTypes.find(s => s.id === cur.scheduleTypeId)
      if (!scheduleTypeData) {
        return acc
      }

      acc.push({ key: cur.scheduleTypeId!.toString(), value: scheduleTypeData.name })
      return acc
    }, [])
  }, [editData, partialScheduleTypes])

  const allocationTypeText = React.useMemo(() => {
    return editData && editData.type === 'auto'
      ? '選択された作業の費用からキー作業の売上（実績数x単価）に応じて決められた割合をキー作業の費用に含みます。'
      : '選択された作業の費用から設定された割合をキー作業の費用に含みます。'
  }, [editData])

  const distributionRatioValidation = React.useCallback((value: string | undefined): string => {
    if (
      typeof value === 'undefined' ||
      (/^([1-9][0-9]{0,2}|0)(\.[0-9]{1,2})?$/.test(value) && Number(value) > 0 && Number(value) <= 100)
    ) {
      return ''
    }

    return '0より大きいかつ100以下の数値を小数点第2位までで入力してください'
  }, [])

  const deletedKeyScheduleTypeValidation = React.useCallback((value: string | undefined): string => {
    if (typeof value === 'undefined' || value.length > 0) {
      return ''
    }
    return '正しく設定されていません'
  }, [])

  return {
    NEW_ALLOCATION_ID,
    NEW_KEY_SCHEDULE_ID,
    allocationTypeData,
    disabled,
    editData,
    setEditData,
    keyScheduleTypes,
    targetKeyScheduleTypes,
    remainKeyScheduleTypes,
    allocationTypeText,
    setAllocationNameValidity,
    setTargetScheduleTypeValidity,
    setAllocationTypeValidity,
    setRemainderScheduleTypeValidity,
    setAllocationRatioTypeValidity,
    distributionRatioValidation,
    deletedKeyScheduleTypeValidation,
  }
}
export default useWorkspaceAllocation
