import dayjs from 'dayjs'
import * as React from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { showError } from 'slices/notificationSlice'
import { selectTenantsStatus } from 'slices/tenantsSlice'

const useDashboard = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { tenant } = useSelector(selectTenantsStatus, shallowEqual)
  const { pathname } = useLocation()

  const onDateChange = React.useCallback(
    (newDate: string) => {
      if (!tenant) {
        return
      }
      if (dayjs(newDate).isBefore(tenant.createdAt, 'day')) {
        dispatch(showError({ errorMessage: 'テナント作成日以前の日付は選択できません' }))
        return
      }
      navigate(`${pathname}?date=${newDate}`)
    },
    [dispatch, navigate, tenant, pathname]
  )

  return { onDateChange }
}
export default useDashboard
