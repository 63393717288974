import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import type { EditTemplateProps } from 'api/template'

import { updateTemplateData, getTemplateData, getTemplateList, selectTemplateStatus } from 'slices/templateSlice'
import { selectWorkspacesStatus, getWorkspace } from 'slices/workspacesSlice'

import ListDetailView from 'components/common/ListDetailView/ListDetailVIew'

import useAuthority from 'hooks/useAuthority'

import TemplateDelete from './TemplateDelete'
import TemplateDetailView from './TemplateDetailView'

const initEditTemplateData = {
  id: null,
  ids: Array(96).fill(null),
  name: '',
}
// templateIDが1から始まるため､新規追加アイテムのIDは0とする
const NEW_TEMPLATE_ID = 0
const MAX_ITEMS = 100

const handleDetailClick = () => window.open('https://help.smileboard.jp/use_templates', '_blank')

const TemplateEdit: React.FC = () => {
  const params = useParams<'workspaceId'>()
  const workspaceId = React.useMemo(() => Number(params.workspaceId), [params.workspaceId])

  const [templateNameValidity, setTemplateNameValidity] = React.useState(false)
  const [editData, setEditData] = React.useState<EditTemplateProps>(initEditTemplateData)
  const [selectedTemplateId, setSelectedTemplateId] = React.useState<number>()
  const [openDelete, setOpenDelete] = React.useState(false)

  const dispatch = useDispatch()

  const { workspace } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { templateList, template, templateWorkspaceId, isRequesting, errorMessage } = useSelector(
    selectTemplateStatus,
    shallowEqual
  )

  const { isReadOnlyWorkspace } = useAuthority(workspaceId)

  React.useEffect(() => {
    dispatch(getWorkspace(workspaceId))
  }, [dispatch, workspaceId])

  React.useEffect(() => {
    template && setEditData(template)
  }, [template])

  const listItems = React.useMemo(
    () =>
      // 前WSのtemplateListが残っているため、workspaceIdとtemplateWorkspaceIdが不一致の間はsetScheduleTypeListItemsしない
      workspaceId === templateWorkspaceId
        ? templateList.map(({ id, name }) => ({
            id,
            title: name,
            data: `ID: ${id}`,
          }))
        : [],
    [templateList, templateWorkspaceId, workspaceId]
  )

  const newListItem = React.useMemo(() => ({ title: '未保存の項目', id: NEW_TEMPLATE_ID, data: 'ID: 未保存' }), [])

  const disabled = React.useMemo(() => !(editData && templateNameValidity), [editData, templateNameValidity])

  const unchanged = React.useMemo(
    () => editData.name === template?.name && _.isEqual(editData.ids, template?.ids),
    [template, editData]
  )

  const handleSubmit = React.useCallback(() => {
    const id = editData.id === NEW_TEMPLATE_ID ? null : editData.id
    const schedules = editData.ids.map(i => (i === null || i.isSupport ? null : i.id))
    const workspaces = editData.ids.map(i => (i === null ? null : i.isSupport ? i.id : workspaceId))
    dispatch(
      updateTemplateData(workspaceId, {
        schedules,
        workspaces,
        name: editData.name,
        id,
      })
    )
  }, [dispatch, editData, workspaceId])

  const handleCancel = React.useCallback(() => template && setEditData(template), [template])

  const handleAddNewItem = React.useCallback(() => {
    setEditData({
      ...initEditTemplateData,
      id: NEW_TEMPLATE_ID,
    })
  }, [])

  const listFunc = React.useCallback(
    (callback: (newList: { id: number; name: string }[]) => void) => getTemplateList(workspaceId, callback),
    [workspaceId]
  )

  const detailFunc = React.useCallback((id: number) => getTemplateData(workspaceId, id), [workspaceId])

  const deleteDialog = React.useCallback(
    (onSuccess: () => void) => (
      <TemplateDelete
        isOpen={openDelete}
        workspaceId={workspaceId}
        templateId={selectedTemplateId}
        templateName={editData.name}
        onSuccess={() => {
          onSuccess()
          setOpenDelete(false)
        }}
        onCancel={() => setOpenDelete(false)}
      />
    ),
    [editData.name, openDelete, selectedTemplateId, workspaceId]
  )

  const handleSelectItem = React.useCallback(
    (id: number) => dispatch(getTemplateData(workspaceId, id)),
    [dispatch, workspaceId]
  )

  return (
    <>
      <ListDetailView
        title="予定テンプレート管理"
        badgeLabel={workspace?.name}
        addButton={{ onClick: handleAddNewItem, label: '予定テンプレートの追加' }}
        maxItems={MAX_ITEMS}
        listItems={listItems}
        onSelectItem={handleSelectItem}
        newListItem={newListItem}
        emptyList={{
          title: '予定テンプレートがまだ登録されていません',
          text: ' 作業計画の予定テンプレートを作成できます。予定テンプレートは個人の1日の予定単位で作成が可能です。作業計画画面で複数人選択して、一括で予定テンプレートを入力する事も可能です。',
          buttonLabel: '予定テンプレートについてもっと詳しく',
          onClick: handleDetailClick,
        }}
        emptyDetail={{
          title: '予定テンプレートが選択されていません',
          text: '予定テンプレートを選択して、詳細情報を編集しましょう。',
        }}
        footer={{
          onCancel: handleCancel,
          onSubmit: handleSubmit,
          updatedAt: template?.updatedAt,
          updatedBy: template?.updatedByName,
          cancelDisabled: unchanged,
          submitDisabled: disabled || unchanged || isReadOnlyWorkspace,
        }}
        isRequesting={isRequesting}
        errorMessage={errorMessage}
        selectedId={selectedTemplateId}
        setSelectedId={setSelectedTemplateId}
        newItemId={NEW_TEMPLATE_ID}
        getListFunc={listFunc}
        getDetailFunc={detailFunc}
        deleteDialog={deleteDialog}
        storeId={template?.id}
      >
        <TemplateDetailView
          editData={editData}
          setEditData={setEditData}
          setTemplateNameValidity={setTemplateNameValidity}
          setOpenDelete={setOpenDelete}
          disabledDeleteButton={selectedTemplateId === NEW_TEMPLATE_ID || isReadOnlyWorkspace}
        ></TemplateDetailView>
      </ListDetailView>
    </>
  )
}

export default TemplateEdit
