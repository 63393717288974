import AccountInformationEdit from 'components/AccountInformationEdit/AccountInformationEdit'
import Assignment from 'components/Assignment/Assignment'
import BalanceOfPayments from 'components/BalanceOfPayments/BalanceOfPayments'
import BopReportsBop from 'components/BopReports/BopReportsBop'
import BopReportsProfitAndLoss from 'components/BopReports/BopReportsProfitAndLoss'
import CompanyEdit from 'components/CompanyEdit/CompanyEdit'
import BopMonitoring from 'components/Dashboard/BopMonitoring'
import IndividualSummary from 'components/Dashboard/IndividualSummary'
import TotalSummary from 'components/Dashboard/TotalSummary'
import GroupEdit from 'components/Groups/GroupEdit'
import HourlyWage from 'components/HourlyWage/HourlyWage'
import Login from 'components/Login/Login'
import PasswordReset from 'components/PasswordReset/PasswordReset'
import PasswordResetComplete from 'components/PasswordReset/PasswordResetComplete'
import PlanAccuracyDetail from 'components/Reports/PlanAccuracyDetail'
import Reports from 'components/Reports/Reports'
import Schedules from 'components/Schedules/Schedules'
import WorkPlan from 'components/Schedules/WorkPlan/WorkPlan'
import Activate from 'components/Signup/Activate'
import Signup from 'components/Signup/Signup'
import Suspension from 'components/Suspension/Suspension'
import TeamAssignment from 'components/TeamAssignment/TeamAssignment'
import TeamWorkerDetail from 'components/TeamAssignment/TeamWorkerDetail'
import TeamProgress from 'components/TeamProgress/TeamProgress'
import TeamSchedules from 'components/TeamSchedules/TeamSchedules'
import TeamWorkPlan from 'components/TeamSchedules/TeamWorkPlan/TeamWorkPlan'
import TeamSelectGroups from 'components/TeamSelect/TeamSelectGroups'
import TeamSelectWorkers from 'components/TeamSelect/TeamSelectWorkers'
import TeamSelectWorkspaces from 'components/TeamSelect/TeamSelectWorkspaces'
import TenantCreate from 'components/Tenants/TenantCreate'
import TenantDetail from 'components/Tenants/TenantDetail'
import TenantEdit from 'components/Tenants/TenantEdit'
import Tenants from 'components/Tenants/Tenants'
import Users from 'components/Users/Users'
import Shifts from 'components/Workers/Shifts'
import Skills from 'components/Workers/Skills'
import WorkerCreate from 'components/Workers/WorkerCreate'
import WorkerDetail from 'components/Workers/WorkerDetail'
import WorkerList from 'components/Workers/WorkerList'
import TemplateEdit from 'components/Workspaces/Template/TemplateEdit'
import WorkspaceAllocation from 'components/Workspaces/WorkspaceAllocation'
import WorkspaceCreate from 'components/Workspaces/WorkspaceCreate'
import WorkspaceDetail from 'components/Workspaces/WorkspaceDetail'
import WorkspaceEdit from 'components/Workspaces/WorkspaceEdit'
import Workspaces from 'components/Workspaces/Workspaces'

import type * as React from 'react'

type Page = {
  path: string
  component: React.FC
}

// ヘッダーが不要なページ
export const noHeaderPages: Page[] = [
  { path: '/login', component: Login },
  { path: '/signup', component: Signup },
  { path: '/activate', component: Activate },
  { path: '/password-reset', component: PasswordReset },
  { path: '/password-reset-complete', component: PasswordResetComplete },
  { path: '/tenant-create', component: TenantCreate },
  { path: '/tenants/:tenantId/edit', component: TenantEdit },
  { path: '/worker-create', component: WorkerCreate },
  { path: '/workspace-create', component: WorkspaceCreate },
  { path: '/workers/:workerId', component: WorkerDetail },

  // グループ管理画面
  { path: '/team-select-workspaces', component: TeamSelectWorkspaces },
  { path: '/team-select-groups', component: TeamSelectGroups },
  { path: '/team-select-workers', component: TeamSelectWorkers },
  { path: '/team-worker/:workerId', component: TeamWorkerDetail },
]

// ヘッダーが必要なページ
export const headerPages: Page[] = [
  { path: '/tenants', component: Tenants },
  { path: '/tenants/:tenantId/detail', component: TenantDetail },
  { path: '/tenants/:tenantId/users', component: TenantDetail },
  { path: '/tenants/:tenantId/services', component: TenantDetail },
  { path: '/users', component: Users },
  { path: '/dashboard/balance-of-payments', component: BopMonitoring },
  { path: '/dashboard', component: TotalSummary },
  { path: '/dashboard/:workspaceId', component: IndividualSummary },
  { path: '/dashboard/:workspaceId/workspace', component: IndividualSummary },
  { path: '/dashboard/:workspaceId/performance-list', component: IndividualSummary },
  { path: '/dashboard/:workspaceId/performance-graph/workspace', component: IndividualSummary },
  { path: '/dashboard/:workspaceId/performance-graph/groups/:groupId', component: IndividualSummary },
  { path: '/dashboard/:workspaceId/performance-graph/workers', component: IndividualSummary },
  { path: '/dashboard/:workspaceId/performance-graph/workers/:workerId', component: IndividualSummary },
  { path: '/assignment', component: Assignment },
  { path: '/assignment/:workspaceId', component: Assignment },
  { path: '/workers', component: WorkerList },
  { path: '/shifts', component: Shifts },
  { path: '/skills', component: Skills },
  { path: '/workspaces', component: Workspaces },
  { path: '/workspaces/:workspaceId', component: WorkspaceDetail },
  { path: '/workspaces/:workspaceId/edit', component: WorkspaceEdit },
  { path: '/workspaces/:workspaceId/groups', component: GroupEdit },
  { path: '/workspaces/:workspaceId/allocation', component: WorkspaceAllocation },
  { path: '/workspaces/:workspaceId/templates', component: TemplateEdit },
  { path: '/schedules', component: Schedules },
  { path: '/schedules/:workspaceId', component: Schedules },
  { path: '/schedules/:workspaceId/:workDate', component: WorkPlan },
  { path: '/reports', component: Reports },
  { path: '/reports/:workspaceId', component: Reports },
  { path: '/reports/:workspaceId/:date', component: PlanAccuracyDetail },
  { path: '/bop-reports/bop', component: BopReportsBop },
  { path: '/bop-reports/profit-and-loss', component: BopReportsProfitAndLoss },
  { path: '/account/edit', component: AccountInformationEdit },
  { path: '/company/edit', component: CompanyEdit },
  { path: '/suspension', component: Suspension },
  { path: '/balance-of-payments', component: BalanceOfPayments },
  { path: '/hourly-wage', component: HourlyWage },

  // グループ管理画面
  { path: '/team-assignment', component: TeamAssignment },
  { path: '/team-progress', component: TeamProgress },
  { path: '/team-schedules', component: TeamSchedules },
  { path: '/team-schedules/:date', component: TeamWorkPlan },
]
