import axios from 'axios'

import type { CommonParams } from 'slices/utils'

import type { PartialTenantData, TenantData } from './tenants'

export const Role = {
  Root: 'root',
  TenantAdmin: 'tenantAdmin',
  Admin: 'admin',
  ProcessAdmin: 'processAdmin',
  TeamAdmin: 'teamAdmin',
} as const
export type RoleType = (typeof Role)[keyof typeof Role]

export type UserListResponse = {
  partialTenants: PartialTenantData[]
  partialUsers: PartialUserData[]
}

export type UserResponse = {
  workDate: string
  tenants: TenantData[]
  user: UserData
}

export type PartialUserHasTenantsData = {
  revision: number
  id: number
  nickname: string | null
  isActive: boolean
  role: RoleType
  updatedAt: string
  updatedByName: string | null
}

export type PartialUserData = {
  userId: string
  isServiceManagementRole: boolean
  partialUserHasTenants: PartialUserHasTenantsData[]
}

export type UserHasTenantManagedWorkspace = {
  id: number
  name: string
  isManagement: boolean
}

export type FilteredValueScheduleType = {
  id: number
  isFilteredInSummary: boolean
  isFilteredInEachWorkspace: boolean
}

export type FilteredValue = {
  workspaceId: number
  scheduleTypes: FilteredValueScheduleType[]
}

export type UserHasTenantsType = PartialUserHasTenantsData & {
  filteredValue: FilteredValue
  language: string
  canViewBOP: boolean
  canManageBOP: boolean
  managedWorkspaces: UserHasTenantManagedWorkspace[]
}

export type UserData = {
  userId: string
  email: string
  isServiceManagementRole: boolean
  userHasTenants: UserHasTenantsType[]
  createdAt: string
  updatedAt: string | null
}

type EditDashboardFilterScheduleTypeProps = {
  id: number
  isFilteredInSummary?: boolean
  isFilteredInEachWorkspace?: boolean
}

type DashboardFilterScheduleTypeProps = {
  id: number
  isFilteredInSummary?: boolean
  isFilteredInEachWorkspace?: boolean
}

type DashboardFilterProps = {
  workspaceData: {
    id: number
    scheduleTypeData: DashboardFilterScheduleTypeProps[]
  }[]
}

type EditDashboardFilterProps = {
  workspaceData: {
    id: number
    scheduleTypeData: EditDashboardFilterScheduleTypeProps[]
  }[]
}

type BOPDashboardFilterTypeProps = {
  id: number
  isFilteredInSummary: boolean
  scheduleTypeData: {
    id: number
    isFilteredInUnitCost: boolean
  }[]
}

type BOPDashboardFilterProps = {
  workspaceData: BOPDashboardFilterTypeProps[]
}

type BOPReportTypeProps = {
  id: number
  isFilteredInSummary: boolean
}

type BOPReportProps = {
  workspaceData: BOPReportTypeProps[]
}

export type DisplayFilterResponse = {
  revision: number
  dashboard: DashboardFilterProps
  bopDashboard: BOPDashboardFilterProps
  bopReport: BOPReportProps
  updatedAt: string
  updatedByName: string
}

export type CreateUserProps = {
  email: string
}

export type UpdateUserProps = {
  email?: string
  isServiceManagementRole?: boolean
  nickname?: string
}

export type UpdateUserTenantProps = {
  role?: RoleType
  nickName?: string
  isActive: boolean
}

export type CreateTenantUserProps = {
  email: string
  role?: RoleType
}

export type UpdateTenantUserProps = {
  email?: string
  nickname?: string
  language?: string
}

type EditUserManagedWorkspaces = {
  id: number
  isManagement: boolean
}

export type UpdateUserPermissionProps = {
  role?: RoleType
  canViewBOP?: boolean
  canManageBOP?: boolean
  managedWorkspaces?: EditUserManagedWorkspaces[]
}

export type UpdateDisplayFilterProps = {
  dashboard?: EditDashboardFilterProps
  bopDashboard?: BOPDashboardFilterProps
  bopReport?: BOPReportProps
}

export const getUserList = (params: CommonParams): Promise<UserListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/users`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createUser = (params: CommonParams, data: CreateUserProps): Promise<{ userId: string }> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/users`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getUser = (params: CommonParams, userId: string): Promise<UserResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/users/${userId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const updateUser = (params: CommonParams, data: UpdateUserProps): Promise<UserResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/users/${params.userId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const deleteUser = (params: CommonParams, userId: string): Promise<{ userId: string }> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/users/${userId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .delete(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateUserTenant = (
  params: CommonParams,
  userId: string,
  tenantId: number,
  data: UpdateUserTenantProps
): Promise<UserResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/users/${userId}/tenants/${tenantId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getTenantUserList = (params: CommonParams, tenantId: number): Promise<UserListResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${tenantId}/users`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const createTenantUser = (
  params: CommonParams,
  tenantId: number,
  data: CreateTenantUserProps
): Promise<{ userId: string }> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${tenantId}/users`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getTenantUser = (params: CommonParams, userId: string): Promise<UserResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/users/${userId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }

    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateTenantUser = (params: CommonParams, data: UpdateTenantUserProps): Promise<UserResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/users/${params.userId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const deleteTenantUser = (params: CommonParams, userId: string, tenantId: number): Promise<{ userId: string }> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${tenantId}/users/${userId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .delete(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateTenantUserPermission = (
  params: CommonParams,
  userId: string,
  data: UpdateUserPermissionProps
): Promise<UserResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/users/${userId}/permission`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }

    axios
      .patch(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getDisplayFilter = (params: CommonParams): Promise<DisplayFilterResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/users/${params.userId}/display-filter`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateDisplayFilter = (
  params: CommonParams,
  data: UpdateDisplayFilterProps
): Promise<DisplayFilterResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/users/${params.userId}/display-filter`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
