import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import type { WorkResultsType } from 'api/work_results'

import { selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import { TimeScale } from 'components/common'

import ManualInputRow from './ManualInputRow'

import styles from './ManualInputDialog.module.scss'

type Props = {
  value: WorkResultsType[]
  onChange: React.Dispatch<React.SetStateAction<WorkResultsType[]>>
}

const UNCHANGED_VALUE = -1

const ManualInputEditTable: React.FC<Props> = ({ value, onChange }) => {
  const params = useParams<'workspaceId'>()
  const [open, setOpen] = React.useState<number[]>([])
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { partialScheduleTypes, allScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)

  React.useEffect(() => {
    if (params.workspaceId) {
      setOpen([Number(params.workspaceId)])
    }
  }, [params.workspaceId])

  const data = React.useMemo(() => {
    const targetScheduleTypes = params.workspaceId ? partialScheduleTypes : allScheduleTypes
    return partialWorkspaces
      .map(w => {
        const scheduleTypes = targetScheduleTypes
          .filter(s => s.workspaceId === w.id)
          .filter(s => value.some(v => v.scheduleTypeId === s.id))
          .map(s => {
            const target = value?.find(wr => wr.scheduleTypeId === s.id)
            const hourlyValue = target?.hourlyValue || []
            const sumHourlyValue = hourlyValue.reduce((acc, cur) => (cur === UNCHANGED_VALUE ? acc : acc + cur), 0)
            return { ...s, hourlyValue, sumHourlyValue }
          })

        return {
          ...w,
          scheduleTypes,
        }
      })
      .filter(w => !_.isEmpty(w.scheduleTypes))
  }, [value, params, partialWorkspaces, partialScheduleTypes, allScheduleTypes])

  const handleWorkspaceRowClick = (workspaceId: number) => {
    setOpen(prev => {
      if (prev.includes(workspaceId)) {
        return prev.filter(id => id !== workspaceId)
      }
      return prev.concat(workspaceId)
    })
  }

  const handleManualInputChange = (newValue: number[], scheduleTypeId: number) => {
    onChange(prev =>
      prev ? [...prev.map(p => (p.scheduleTypeId === scheduleTypeId ? { ...p, hourlyValue: newValue } : p))] : []
    )
  }

  return (
    <table className="border-bottom border-secondary-pale">
      <thead>
        <tr className="sticky-top">
          <td className={`ps-4 ${styles.header}`}>作業</td>
          <td>
            <TimeScale round />
          </td>
          <td className={styles.sumHeader}>合計</td>
        </tr>
      </thead>

      {data.map(({ id, name, scheduleTypes }, index) => (
        <tbody key={`group-${id}-${index}`}>
          <tr className={styles.workspaceRow}>
            <td className={styles.workspaceCol}>
              <div className="d-flex align-items-center px-4" onClick={() => handleWorkspaceRowClick(id)}>
                <i className={`icf-carot_${open.includes(id) ? 'down' : 'right'} me-1`} />
                <div className="text-truncate">{name}</div>
              </div>
            </td>
          </tr>
          {open.includes(id) &&
            _.sortBy(scheduleTypes, 'scheduleTypeId').map((scheduleType, i) => (
              <tr key={`worker-${scheduleType.id}-${i}`} className={styles.scheduleRow}>
                <td className={styles.scheduleCol}>
                  <div className="d-flex align-items-center">
                    <div className="text-truncate me-auto">{scheduleType.name}</div>
                  </div>
                </td>

                <td className={styles.scheduleEdit}>
                  <ManualInputRow
                    id={scheduleType.id.toString()}
                    items={scheduleType.hourlyValue}
                    onChange={val => handleManualInputChange(val, scheduleType.id)}
                  />
                </td>
                <td className={styles.scheduleSumCol}>{scheduleType.sumHourlyValue}</td>
              </tr>
            ))}
        </tbody>
      ))}
    </table>
  )
}

export default ManualInputEditTable
