import axios from 'axios'

import type { CommonParams } from 'slices/utils'

import type { PartialTenantData, TenantData } from './tenants'
import type { PartialWorkspaceData, WorkspaceData } from './workspaces'

export const allocationTypes = {
  Manual: 'manual',
  Auto: 'auto',
} as const

export type AllocationType = (typeof allocationTypes)[keyof typeof allocationTypes]

export type AllocationResponse = {
  workDate: string
  tenant: TenantData
  workspace: WorkspaceData
  allocation: AllocationData
}

export type AllocationListResponse = {
  partialTenant: PartialTenantData
  partialWorkspace: PartialWorkspaceData
  partialAllocations: PartialAllocationData[]
}

export type EditAllocationProps = {
  name?: string
  scheduleTypeId: number | null
  type: string
  keys: EditKeyScheduleType[]
  remainderScheduleTypeId: number | null
}

export type UpdateAllocationProps = {
  workDate?: string
  name?: string
  scheduleTypeId?: number | null
  type?: string
  keys?: _.Omit<KeyScheduleType, 'scheduleTypeName'>[] // 自動按分設定の場合はratioが不要
  remainderScheduleTypeId?: number | null
}

export type PartialAllocationData = {
  id: number
  name: string
  workDate: string
  revision: number
  scheduleTypeId: number
  keys: KeyScheduleType[]
  isError: boolean
  updatedAt: string
  updatedByName: string | null
}

export type AllocationData = PartialAllocationData & {
  scheduleTypeName: string
  type: AllocationType
  remainderScheduleTypeId: number | null
}

type KeyScheduleType = {
  scheduleTypeId: number
  scheduleTypeName: string
  ratio?: number
}

export type EditKeyScheduleType = {
  scheduleTypeId: number
  ratio: string
}

export const getAllocationList = (params: CommonParams, workspaceId: number): Promise<AllocationListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces/${workspaceId}/allocations`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getAllocation = (
  params: CommonParams,
  workspaceId: number,
  allocationId: number
): Promise<AllocationResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces/${workspaceId}/allocations/${allocationId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createAllocation = (
  params: CommonParams,
  workspaceId: number,
  data: UpdateAllocationProps
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces/${workspaceId}/allocations`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const updateAllocation = (
  params: CommonParams,
  workspaceId: number,
  allocationId: number,
  data: UpdateAllocationProps
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces/${workspaceId}/allocations/${allocationId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const deleteAllocation = (params: CommonParams, workspaceId: number, allocationId: number): Promise<void> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces/${workspaceId}/allocations/${allocationId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .delete(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
