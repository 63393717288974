import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Card, CardText } from 'reactstrap'

import { getGroup, selectGroupsStatus } from 'slices/groupsSlice'
import { selectSessionStatus, setTeamWorker } from 'slices/sessionSlice'
import { getWorkerList, selectWorkersStatus } from 'slices/workersSlice'

import { WorkerIcon } from 'components/common'
import { ColorTypes } from 'components/common/utils'

import useLogout from 'hooks/useLogout'

import styles from './styles.module.scss'

export const NO_GROUP_ID = 0

const TeamSelectWorkers: React.FC = () => {
  const dispatch = useDispatch()
  const {
    team: { workspaceId, groupId, groupName },
  } = useSelector(selectSessionStatus, shallowEqual)
  const { logout } = useLogout()

  React.useEffect(() => {
    dispatch(getWorkerList())
  }, [dispatch])

  React.useEffect(() => {
    // getGroupで必要な情報はグループカラーだけなので、未所属の場合は呼び出さない
    if (groupId === NO_GROUP_ID) {
      return
    }
    dispatch(getGroup(workspaceId, groupId))
  }, [dispatch, workspaceId, groupId])

  const { group } = useSelector(selectGroupsStatus, shallowEqual)
  // groupに所属するworkerを取得する必要がある。
  const { partialWorkers } = useSelector(selectWorkersStatus, shallowEqual)

  const sortedWorkers = React.useMemo(() => {
    if (!partialWorkers) {
      return []
    }

    if (groupId === NO_GROUP_ID) {
      return partialWorkers.filter(worker => worker.workspaceId === workspaceId && worker.groupId === null)
    }
    return partialWorkers.filter(worker => worker.groupId === groupId)
  }, [partialWorkers, groupId, workspaceId])

  const navigate = useNavigate()
  const moveToAssignment = (workerId: number, workerName: string) => {
    dispatch(setTeamWorker({ workerId, workerName }))
    navigate('/team-assignment')
  }

  const groupColor = React.useMemo(() => {
    return groupId === NO_GROUP_ID ? ColorTypes.Silver : group?.color || ColorTypes.Silver
  }, [groupId, group])

  return (
    <>
      <div className={styles.header}>
        <i className="icf-chevron_left font-x-large" onClick={() => navigate('/team-select-groups')} />
        <div className="font-x-large fw-bold">操作メンバー選択</div>
        <div onClick={() => logout()}>ログアウト</div>
      </div>
      <div className={`position-sticky ${styles.list}`}>
        {sortedWorkers.map(worker => (
          <Card className="p-3 mb-2" key={worker.id} onClick={() => moveToAssignment(worker.id, worker.name)}>
            <div className="d-flex justify-content-start align-items-center">
              <WorkerIcon name={worker.name} groupColor={groupColor} />
              <div className="px-3 overflow-hidden">
                <CardText className="m-0 text-truncate">{worker.name}</CardText>
                <CardText className="m-0 text-truncate fw-light text-black-50">{groupName}</CardText>
              </div>
              <i className="ms-auto icf-chevron_right font-large" />
            </div>
          </Card>
        ))}
      </div>
    </>
  )
}

export default TeamSelectWorkers
