import axios from 'axios'

import type { CreateResponse, DeleteResponse, UpdateResponse } from 'api/types'

import type { CommonParams } from 'slices/utils'

import type { ColorType } from 'components/common/types'

import type { PartialTenantData, TenantData } from './tenants'

export type PartialWorkspaceData = {
  id: number
  workDate: string
  revision: number
  name: string
  updatedAt: string
  updatedByName: string | null
  scheduleTypeNames: string[]
}

export type WorkspaceData = PartialWorkspaceData & {
  autoArchiveTime: string | null
  memberIds: string[]
  relatedWorkspaceIds: number[]
}

export type WorkspaceListResponse = {
  partialTenant: PartialTenantData
  partialWorkspaces: PartialWorkspaceData[]
}

export type WorkspaceResponse = {
  workDate: string
  tenant: TenantData
  workspace: WorkspaceData
}

export type WorkerCountsResponse = {
  workspaceId: number
  workspaceName: string
  date: string
  data: ScheduleTypeWithWorkerCounts[]
}

export type ScheduleTypeWithWorkerCounts = {
  scheduleTypeId: number
  scheduleTypeName: string
  scheduleTypeColor: ColorType
  totalCount: number
  data: WorkerCount[]
}

export type WorkerCount = {
  time: string
  workerCount: number
}

export type EditWorkspaceProps = {
  workDate?: string
  name: string
  memberIds: string[]
  relatedWorkspaceIds: number[]
  autoArchiveTime: string
}

// idがnullの場合は新規追加
// schedulesがnullの場合は削除
export type EditTemplateProps = {
  id: number | null
  schedules: (number | null)[] | null // 24時間を15分ごとに分割した96の配列にscheduleTypeIdを格納する
  name?: string
}

export type TemplateListResponse = {
  templates: {
    id: number
    name: string
  }[]
}

export type TemplateDataResponse = {
  id: number
  name: string
  schedules: (number | null)[] // 24時間を15分ごとに分割した96の配列にscheduleTypeIdを格納する
  updatedAt: string
  updatedByName: string
}

type UploadTargetsResponse = {
  updateData: {
    revision: number | null
    workDate: string
    scheduleTypeId: number | null
    targetValue: number | null
    errorInfo?: string
  }[]
}

export const getWorkspacesList = (params: CommonParams, workDate?: string): Promise<WorkspaceListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    const paramData = workDate ? { 'work-date': workDate } : {}
    axios
      .get(url, { headers, params: paramData })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getWorkspace = (
  params: CommonParams,
  workspaceId: number,
  workDate?: string
): Promise<WorkspaceResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces/${workspaceId}`

    const paramData = workDate ? { workDate } : {}
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }

    axios
      .get(url, { headers, params: paramData })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createWorkspace = (params: CommonParams, data: EditWorkspaceProps): Promise<CreateResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateWorkspace = (
  params: CommonParams,
  workspaceId: number,
  data: EditWorkspaceProps
): Promise<UpdateResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces/${workspaceId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const deleteWorkspace = (params: CommonParams, workspaceId: number): Promise<DeleteResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces/${workspaceId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .delete(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const uploadTargets = (
  params: CommonParams,
  workspaceId: string,
  fileData: FormData
): Promise<UploadTargetsResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces/${workspaceId}/target-value-with-file`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
      'Content-Type': 'multipart/form-data',
    }
    axios
      .post(url, fileData, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
