import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { Role } from 'api/users'

import { selectSessionStatus } from 'slices/sessionSlice'
import { getTenantUserList, selectUsersStatus } from 'slices/usersSlice'

import { Suggestion } from 'components/common'
import type { SuggestionItem } from 'components/common/types'

import styles from './WorkspaceAdminEdit.module.scss'

const UNREGISTERED_USER = '招待済み未登録ユーザー'

type Props = {
  admins: SuggestionItem[]
  members: SuggestionItem[]
  onChange: (admins: SuggestionItem[], members: SuggestionItem[]) => void
}

const WorkspaceAdminEdit: React.FC<Props> = props => {
  const { admins, members, onChange } = props

  const {
    user: { userHasTenants },
  } = useSelector(selectSessionStatus, shallowEqual)
  const { selectedTenantUsers } = useSelector(selectUsersStatus, shallowEqual)

  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(getTenantUserList(userHasTenants[0].id))
  }, [dispatch, userHasTenants])

  React.useEffect(() => {
    // Admin権限のユーザーを変更できない値として追加しておく
    const newAdmins: SuggestionItem[] = []
    selectedTenantUsers
      .filter(
        tenantUser =>
          tenantUser.partialUserHasTenants[0].role === Role.Admin &&
          tenantUser.partialUserHasTenants[0].nickname !== UNREGISTERED_USER
      )
      .forEach(adminUser => {
        newAdmins.push({ id: adminUser.userId, value: adminUser.partialUserHasTenants[0]?.nickname ?? '' })
      })
    if (!_.isEqual(admins, newAdmins)) {
      onChange(newAdmins, members)
    }
  }, [selectedTenantUsers, admins, members, onChange])
  const processAdminUsers = React.useMemo(
    () =>
      selectedTenantUsers.filter(
        tenantUser =>
          tenantUser.partialUserHasTenants[0].role === Role.ProcessAdmin &&
          tenantUser.partialUserHasTenants[0].nickname !== UNREGISTERED_USER
      ),
    [selectedTenantUsers]
  )

  const onSelectAdminUser = (item: SuggestionItem) => {
    const newMembers = [...members, { id: item.id.toString(), value: item.value }]
    onChange(admins, newMembers)
  }

  const removeAdminUser = (item: SuggestionItem) => {
    const newMembers = members.filter(member => member.id !== item.id.toString())
    onChange(admins, newMembers)
  }

  const remainingAdminUsers = React.useMemo(
    () =>
      processAdminUsers.reduce<SuggestionItem[]>((acc, cur) => {
        if (members.every(member => member.id !== cur.userId) && cur.partialUserHasTenants[0].nickname) {
          acc.push({ id: cur.userId, value: cur.partialUserHasTenants[0].nickname })
        }
        return acc
      }, []),
    [processAdminUsers, members]
  )

  return (
    <>
      <div className={styles.badgeContainer}>
        {admins.concat(members).map(member => {
          const isAdmin = selectedTenantUsers.some(
            u => u.userId === member.id && u.partialUserHasTenants[0].role === Role.Admin
          )
          const color = isAdmin ? 'light-gray' : 'primary'
          return (
            <div
              key={member.id}
              className={`badge rounded-pill ${isAdmin && 'text-black'} bg-${color} fw-normal m-2 font-small`}
              style={{ maxWidth: '90%' }}
            >
              <div className="d-flex align-items-center">
                <div className="text-truncate">{member.value}</div>
                {!isAdmin && <i className="icf-close ms-1" role="button" onClick={() => removeAdminUser(member)} />}
              </div>
            </div>
          )
        })}
      </div>
      <div className="mt-3  text-muted">ワークスペース管理者を追加</div>
      <div className="d-flex">
        <Suggestion
          className="w-50"
          suggestionList={remainingAdminUsers}
          onSelect={onSelectAdminUser}
          placeholder="ユーザー名で検索"
        />
      </div>
    </>
  )
}

export default WorkspaceAdminEdit
