import dayjs from 'dayjs'
import _ from 'lodash'
import moment from 'moment/moment'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import type { WorkersPlan, UpdatePlanSchedule, WorkerScheduleData } from 'api/plans'

import { getGroupList } from 'slices/groupsSlice'
import { getPlanByDate, selectPlansStatus } from 'slices/plansSlice'
import { selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getSkillList } from 'slices/skillsSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import { PREFIX_SCHEDULE_TYPE_ID, PREFIX_WORKSPACE_ID } from 'components/Assignment/DroppingWorkerCards'
import type { DropdownScheduleType } from 'components/Assignment/WorkerCard'
import type { ScheduleEditType } from 'components/TeamAssignment/TeamWorkerReassign'
import type { EditSchedule } from 'components/common/types'
import {
  roundedMoment,
  SHIFT_SCHEDULE_TYPE_ID,
  SUPPORT_SCHEDULE_TYPE_ID,
  getUpdateWorkerSchedules,
  UNSELECTED_SCHEDULE_TYPE_ID,
  ColorTypes,
} from 'components/common/utils'

import useBusinessTime from './useBusinessTime'
import usePlans from './usePlans'
import useQuery from './useQuery'
import useWorkNotification from './useWorkNotification'

import type { Moment } from 'moment/moment'

const useAssignment = () => {
  const [startHour, setStartHour] = React.useState<string>(roundedMoment(false).hour)
  const [startMinute, setStartMinute] = React.useState<string>(roundedMoment(false).minute)
  const [openMoment, setOpenMoment] = React.useState<Moment>(moment())

  const dispatch = useDispatch()
  const params = useParams<'workspaceId'>()

  const {
    team,
    team: { groupName },
  } = useSelector(selectSessionStatus, shallowEqual)
  const { plans, isRequesting, errorMessage } = useSelector(selectPlansStatus, shallowEqual)
  const { partialScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  const query = useQuery()
  const { setSubmitted, submitted } = useWorkNotification()
  const { planWorkspaceId, planStartDateTime, getEditSchedulesFromWorkPlan } = usePlans()
  const { businessStartTime, getWorkDate } = useBusinessTime()

  const workspaceId = React.useMemo(() => {
    if (process.env.REACT_APP_TEAM_MODE === 'true') {
      return team.workspaceId
    }
    return Number(params.workspaceId)
  }, [params, team])

  React.useEffect(() => {
    const qHour = query.get('hour')
    const qMinute = query.get('minute')

    if (!qHour || !qMinute) {
      const { hour, minute } = roundedMoment(false)
      setStartHour(hour)
      setStartMinute(minute)
      return
    }
    setStartHour(qHour)
    setStartMinute(qMinute)
    // クエリがない時、TimeSelectの時刻を15分間隔で更新するためにopenMomentを追加
  }, [query, openMoment])

  React.useEffect(() => {
    dispatch(getSkillList())
  }, [dispatch])

  React.useEffect(() => {
    if (!workspaceId) {
      return
    }
    dispatch(getPlanByDate(workspaceId, getWorkDate(dayjs().format('YYYY-MM-DD'))))
    dispatch(getGroupList(workspaceId))
  }, [dispatch, getWorkDate, workspaceId])

  React.useEffect(() => {
    if (startMinute && startHour) {
      setOpenMoment(moment(`${startHour}:${startMinute}`, 'HH:mm'))
    }
  }, [startHour, startMinute])

  const isBetween = React.useCallback(
    (startAt: string, duration: number): boolean => {
      const start = dayjs(startAt).local().format()
      const end = dayjs(startAt).add(duration, 'seconds').local().format()

      return openMoment.isBetween(start, end, 'minute', '[)')
    },
    [openMoment]
  )

  React.useEffect(() => {
    if (!workspaceId) {
      return
    }

    // 15 分毎に openMoment を書き換えることで API を定期的に呼ぶ
    const timerId = setTimeout(
      () => {
        dispatch(getPlanByDate(workspaceId, getWorkDate(dayjs().format('YYYY-MM-DD'))))
        setOpenMoment(moment(`${startHour}:${startMinute}`, 'HH:mm'))
      },
      15 * 60 * 1000
    )
    return () => clearTimeout(timerId)
  }, [dispatch, workspaceId, startHour, startMinute, getWorkDate])

  React.useEffect(() => {
    if (!submitted || isRequesting || !workspaceId) {
      return
    }

    if (errorMessage === '') {
      dispatch(getPlanByDate(workspaceId, getWorkDate(dayjs().format('YYYY-MM-DD'))))
    }
  }, [submitted, isRequesting, errorMessage, dispatch, workspaceId, getWorkDate])

  const findConsecutiveIndices = React.useCallback(
    (arr: number[], index: number) => {
      const targetValue = arr[index]

      const findConsecutiveStartIndex = (i: number) => {
        // 前方チェック
        if (i > 0 && arr[i - 1] === targetValue) {
          return findConsecutiveStartIndex(i - 1)
        }
        return i
      }

      const findConsecutiveEndIndex = (i: number) => {
        // 後方チェック
        if (i < arr.length - 1 && arr[i + 1] === targetValue) {
          return findConsecutiveEndIndex(i + 1) // 後方チェック
        }
        return i
      }

      const startIndex = findConsecutiveStartIndex(index)
      const endIndex = findConsecutiveEndIndex(index)

      const duration = (endIndex - startIndex + 1) * 300
      const startAt = moment(businessStartTime, 'HH:mm')
        .add(startIndex * 5, 'm')
        .toISOString()

      return {
        startAt,
        duration,
      }
    },
    [businessStartTime]
  )

  const getSelectionTimeSchedule = React.useCallback(
    (workType: number[], workShift: (number | null)[]) => {
      const openMomentIndex = openMoment.diff(moment(businessStartTime, 'HH:mm'), 'm') / 5
      if (!openMomentIndex) {
        return
      }

      const selectedWorkType = workType[openMomentIndex]
      const selectedWorkShift = workShift[openMomentIndex]

      // 表示時間にシフトがない場合､何も返さない
      if (!selectedWorkShift) {
        return
      }
      const workConsecutiveIndices = findConsecutiveIndices(workType, openMomentIndex)
      const shiftConsecutiveIndices = findConsecutiveIndices(workShift as number[], openMomentIndex)

      return {
        workStartAt: workConsecutiveIndices.startAt,
        workDuration: workConsecutiveIndices.duration,
        shiftStartAt: shiftConsecutiveIndices.startAt,
        shiftDuration: shiftConsecutiveIndices.duration,
        scheduleTypeId: selectedWorkType,
        shiftWorkspaceId: selectedWorkShift,
      }
    },
    [businessStartTime, openMoment, findConsecutiveIndices]
  )

  const getSubmitWorkerPlanFromWorker = (
    worker: WorkerScheduleData,
    targetId: string | number,
    isSupport: boolean
  ): WorkersPlan => {
    const originalSchedules: EditSchedule[] =
      plans?.groups.flatMap(g =>
        g.workersPlan
          .filter(w => w.workerId.toString() === worker.workerId.toString())
          .flatMap(w => getEditSchedulesFromWorkPlan(w, isSupport))
      ) || []
    const scheduleTypeId = isSupport ? SUPPORT_SCHEDULE_TYPE_ID : Number(targetId)
    const supportWorkspaceId = isSupport ? Number(targetId) : null
    const updateSchedules = getUpdateWorkerSchedules(
      worker.startAt,
      worker.duration,
      scheduleTypeId,
      supportWorkspaceId,
      null,
      originalSchedules
    )

    const deleteData: UpdatePlanSchedule[] = originalSchedules
      .filter(org => !updateSchedules.find(s => s.scheduleId === org.scheduleId))
      .map(org => ({ scheduleId: org.scheduleId, schedule: null }))

    const updateData: UpdatePlanSchedule[] = updateSchedules.map(s => ({
      scheduleId: s.scheduleId,
      schedule: {
        scheduleTypeId: s.scheduleTypeId,
        supportWorkspaceId: s.supportWorkspaceId,
        startAt: s.startAt,
        duration: s.duration,
        workerId: Number(worker.workerId),
        groupId: null,
      },
    }))

    const workerPlan: WorkersPlan = {
      workerId: Number(worker.workerId),
      workShifts: new Array<number>(288).fill(0),
      workScheduleTypes: new Array<number>(288).fill(0),
    }

    const originalWorkerPlan = plans?.groups
      .flatMap(g => g.workersPlan)
      .find(wp => wp.workerId.toString() === worker.workerId.toString())
    if (originalWorkerPlan) {
      for (let i = 0; i < originalWorkerPlan.workShifts.length; i++) {
        if (originalWorkerPlan.workShifts[i] && originalWorkerPlan.workShifts[i] !== planWorkspaceId && isSupport) {
          workerPlan.workShifts[i] = originalWorkerPlan.workShifts[i]
          workerPlan.workScheduleTypes[i] = originalWorkerPlan.workScheduleTypes[i]
        }
      }
    }

    for (const data of updateData) {
      if (data.schedule?.scheduleTypeId) {
        const startAt = data.schedule.startAt
        const duration = data.schedule.duration / 300 // 300秒(5分)区切りに計算

        const startDateTime = new Date(startAt)
        const diffTimeMin = (startDateTime.getTime() - planStartDateTime.getTime()) / (60 * 1000) // 差分を分単位にする
        const startIndex = diffTimeMin / 5 // 5分区切りに計算
        const endIndex = startIndex + duration

        if (data.schedule?.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID) {
          for (let i = startIndex; i < endIndex; i++) {
            if (workerPlan.workShifts[i] === 0) {
              workerPlan.workShifts[i] = planWorkspaceId
            }
          }
        } else if (data.schedule?.scheduleTypeId === SUPPORT_SCHEDULE_TYPE_ID) {
          for (let i = startIndex; i < endIndex; i++) {
            workerPlan.workShifts[i] = data.schedule.supportWorkspaceId!
            if (originalWorkerPlan) {
              workerPlan.workScheduleTypes[i] = originalWorkerPlan.workScheduleTypes[i]
            }
          }
        } else {
          for (let i = startIndex; i < endIndex; i++) {
            workerPlan.workShifts[i] = planWorkspaceId
            workerPlan.workScheduleTypes[i] = data.schedule.scheduleTypeId
          }
        }
      }
    }

    if (deleteData.length > 0) {
      for (const data of deleteData) {
        if (data.schedule?.supportWorkspaceId) {
          const startAt = data.schedule.startAt
          const duration = data.schedule.duration / 300 // 300秒(5分)区切りに計算

          const startDateTime = new Date(startAt)
          const diffTimeMin = (startDateTime.getTime() - planStartDateTime.getTime()) / (60 * 1000) // 差分を分単位にする
          const startIndex = diffTimeMin / 5 // 5分区切りに計算
          const endIndex = startIndex + duration
          for (let i = startIndex; i < endIndex; i++) {
            workerPlan.workShifts[i] = planWorkspaceId
            workerPlan.workScheduleTypes[i] = 0
          }
        }
      }
    }

    return workerPlan
  }

  const schedules = React.useMemo(() => {
    const allWorkers =
      plans?.groups
        .flatMap(group =>
          group.workersPlan.map(worker => ({
            ...worker,
            groupName: group.groupName ?? '未所属',
            groupColor: group.groupColor ?? ColorTypes.Silver,
            target: getSelectionTimeSchedule(worker.workScheduleTypes, worker.workShifts),
            isSupportGroup: group.isSupported,
          }))
        )
        .filter(w => {
          // 応援先かつ､選択時刻のシフトが表示中のworkspaceIdではない場合､作業者を表示しない
          const shouldHideWorker = w.isSupportGroup && w.target?.shiftWorkspaceId !== workspaceId
          return w.target && !shouldHideWorker
        }) || []

    const groupedWorkers = _.groupBy(allWorkers, o => {
      // 応援ワークスペースに表示されるのは､シフトが自分のworkspaceIdではなく､応援先でない場合
      if (o.target!.shiftWorkspaceId !== workspaceId && !o.isSupportGroup) {
        return [PREFIX_WORKSPACE_ID, o.target?.shiftWorkspaceId].join('-')
      }

      if (o.target?.scheduleTypeId) {
        return [PREFIX_SCHEDULE_TYPE_ID, o.target.scheduleTypeId].join('-')
      }

      return UNSELECTED_SCHEDULE_TYPE_ID.toString()
    })

    const allCards = partialScheduleTypes
      .map(
        (s): DropdownScheduleType => ({
          type: [PREFIX_SCHEDULE_TYPE_ID, s.id].join('-'),
          label: s.name,
          color: s.color,
          skillIds: s.requiredSkillIds,
        })
      )
      .concat(
        partialWorkspaces
          .filter(w => w.id !== workspaceId)
          .map(w => ({ type: [PREFIX_WORKSPACE_ID, w.id].join('-'), label: w.name }))
      )
      .concat({
        type: UNSELECTED_SCHEDULE_TYPE_ID.toString(),
        color: ColorTypes.Silver,
        label: '予定未入力',
      })

    const allTeamCards = partialScheduleTypes
      .map((s): Omit<ScheduleEditType, 'workers'> & { type: string } => ({
        type: [PREFIX_SCHEDULE_TYPE_ID, s.id].join('-'),
        scheduleTypeId: s.id,
        name: s.name,
        color: s.color,
        requiredSkills: s.requiredSkillIds ?? [],
      }))
      .concat(
        partialWorkspaces
          .filter(w => w.id !== workspaceId)
          .map(w => ({
            type: [PREFIX_WORKSPACE_ID, w.id].join('-'),
            scheduleTypeId: w.id,
            name: w.name,
            requiredSkills: [],
          }))
      )
      .concat({
        type: UNSELECTED_SCHEDULE_TYPE_ID.toString(),
        scheduleTypeId: UNSELECTED_SCHEDULE_TYPE_ID,
        name: '予定未入力',
        color: ColorTypes.Silver,
        requiredSkills: [],
      })

    const getIndices = (
      type: string,
      target: { shiftStartAt: string; shiftDuration: number; workStartAt: string; workDuration: number }
    ) => {
      // 作業のときは作業を返す
      if (type.startsWith(PREFIX_SCHEDULE_TYPE_ID)) {
        return { startAt: target.workStartAt, duration: target.workDuration }
      }
      //  応援と予定未入力のときはシフトを返す
      return { startAt: target.shiftStartAt, duration: target.shiftDuration }
    }

    const initSchedules = allCards.map(c => {
      const workers =
        groupedWorkers[c.type]?.map(w => ({
          ...w,
          ...getIndices(c.type, w.target!),
          workerId: w.workerId.toString(),
          scheduleId: null,
          name: w.workerName,
          supporter: w.isSupportGroup,
          visible: true,
        })) || []

      return {
        ...c,
        workers,
      }
    })

    const initTeamSchedules = allTeamCards.map(c => {
      const workers =
        groupedWorkers[c.type]
          ?.filter(g => g.groupName === groupName)
          .map(w => ({
            ...w,
            ...getIndices(c.type, w.target!),
            scheduleId: null,
          })) || []

      return {
        ...c,
        workers,
      }
    })

    return { initSchedules, initTeamSchedules }
  }, [getSelectionTimeSchedule, groupName, partialScheduleTypes, partialWorkspaces, plans?.groups, workspaceId])

  return {
    startHour,
    startMinute,
    openMoment,
    workspaceId,
    isBetween,
    setSubmitted,
    getSubmitWorkerPlanFromWorker,
    ...schedules,
  }
}

export default useAssignment
