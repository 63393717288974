import dayjs from 'dayjs'
import * as React from 'react'
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { DropdownItem, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'

import { CustomButton } from 'components/common/CustomButton/CustomButton'

import usePlans from 'hooks/usePlans'
import useQuery from 'hooks/useQuery'

const DASHBOARD_PATH = '/dashboard/:workspaceId/*'
const ASSIGNMENT_PATH = '/assignment/:workspaceId'
const WORK_PLAN_PATH = '/schedules/:workspaceId/:workDate'

type Props = {
  className?: string
}

const MoveDropdown = ({ className }: Props) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)

  const { pathname } = useLocation()

  const { workDate, workspaceId } = useParams<'workspaceId' | 'workDate'>()
  const query = useQuery()

  const date = React.useMemo(() => query.get('date'), [query])

  const { hasWorkersWithShifts, planWorkDate } = usePlans()

  const navigate = useNavigate()

  const isToday = React.useMemo(() => {
    if (date) {
      return dayjs(date).isSame(dayjs(), 'day')
    }
    return dayjs(workDate).isSame(dayjs(), 'day')
  }, [date, workDate])

  const isPast = React.useMemo(() => {
    if (date) {
      return dayjs(date).isBefore(dayjs(), 'day')
    }

    return dayjs(workDate).isBefore(dayjs(), 'day')
  }, [date, workDate])

  const isPathMatching = React.useCallback((path: string) => !!matchPath({ path }, pathname), [pathname])

  const isDashboard = React.useMemo(() => isPathMatching(DASHBOARD_PATH), [isPathMatching])
  const isWorkPlan = React.useMemo(() => isPathMatching(WORK_PLAN_PATH), [isPathMatching])

  const items = React.useMemo(() => {
    const paths = []
    if (!isDashboard) {
      paths.push({
        label: 'ダッシュボード',
        onClick: () => navigate(`/dashboard/${workspaceId}?date=${date || planWorkDate}`),
      })
    }
    if (!isWorkPlan && hasWorkersWithShifts) {
      paths.push({ label: '作業計画', onClick: () => navigate(`/schedules/${workspaceId}/${date || planWorkDate}`) })
    }
    if (!isPathMatching(ASSIGNMENT_PATH) && isToday) {
      paths.push({
        label: '人員配置',
        onClick: () => navigate(`/assignment/${workspaceId}`),
      })
    }
    if (!isPast) {
      paths.push({
        label: 'シフト管理',
        onClick: () => navigate(`/shifts?id=${workspaceId}&date=${date || planWorkDate}`),
      })
    }
    return paths
  }, [
    isDashboard,
    isWorkPlan,
    hasWorkersWithShifts,
    isPathMatching,
    isToday,
    isPast,
    navigate,
    workspaceId,
    date,
    planWorkDate,
  ])

  const name = React.useMemo(() => {
    if (isWorkPlan) {
      return 'work-plan-move-dropdown'
    }
    if (isDashboard) {
      return 'individual-summary-move-dropdown'
    }

    return 'assignment-move-dropdown'
  }, [isDashboard, isWorkPlan])

  return items.length > 0 ? (
    <div className={className}>
      <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} name={name}>
        <DropdownToggle tag="div">
          <CustomButton icon="carot_down" iconPosition="right" outline>
            移動
          </CustomButton>
        </DropdownToggle>
        <DropdownMenu>
          {items.map(item => (
            <React.Fragment key={item.label}>
              <DropdownItem onClick={item.onClick}>{item.label}</DropdownItem>
            </React.Fragment>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  ) : (
    <></>
  )
}

export default MoveDropdown
