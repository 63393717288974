import { init as initApm } from '@elastic/apm-rum'
import { ApmRoutes } from '@elastic/apm-rum-react'
import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { HashRouter as Router, Route } from 'react-router-dom'

import { selectSessionStatus } from 'slices/sessionSlice'
import { getTenant } from 'slices/tenantsSlice'

import NetworkErrorDialog from 'components/ErrorDialog/NetworkErrorDialog'
import SessionTimeoutDialog from 'components/ErrorDialog/SessionTimeoutDialog'
import Header from 'components/Header/Header'
import NotFoundPage from 'components/NotFoundPage/NotFoundPage'
import Spinner from 'components/Spinner/Spinner'

import useAuthority from 'hooks/useAuthority'

import { noHeaderPages, headerPages } from './pages'

const apm = initApm({
  serviceName: 'sbc-pc',
  serverUrl: process.env.REACT_APP_APM_SERVER,
  serviceVersion: '',
  environment: process.env.REACT_APP_ENVIRONMENT,
})

const App = () => {
  const { loggedIn, activated, team, user } = useSelector(selectSessionStatus, shallowEqual)
  const { isTenantAdmin } = useAuthority()
  const dispatch = useDispatch()

  React.useEffect(() => {
    apm.setCustomContext({ team, user })
  }, [team, user])

  React.useEffect(() => {
    activated && loggedIn && !isTenantAdmin && dispatch(getTenant(user.userHasTenants[0].id))
  }, [dispatch, user, isTenantAdmin, loggedIn, activated])

  return (
    <Router>
      <Spinner />
      <NetworkErrorDialog />
      <SessionTimeoutDialog />
      <Header />
      <ApmRoutes>
        {[...noHeaderPages, ...headerPages].map(page => (
          <Route key={page.path} path={page.path} element={<page.component />} />
        ))}
        <Route path="*" Component={NotFoundPage} />
      </ApmRoutes>
    </Router>
  )
}

export default App
