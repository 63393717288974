import dayjs from 'dayjs'
import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { selectTenantsStatus } from 'slices/tenantsSlice'

// tenantsから営業時間を取得するため、このhooksを使用する前に一度getTenantsを実行する必要がある
// 複数回リクエストされないようにhooks内でリクエストは行わない
const useBusinessTime = () => {
  const { tenant } = useSelector(selectTenantsStatus, shallowEqual)

  // 日付が無い文字列をパースする
  const parseDate = React.useCallback((date: string, format: string) => {
    const dateFormat = 'YYYYMMDD'
    return dayjs(`${dayjs().format(dateFormat)}${date}`, `${dateFormat}${format}`)
  }, [])

  const businessTime = React.useMemo(() => {
    const start = tenant?.businessStartTime || '06:00'
    const end = tenant?.businessEndTime || '20:00'

    const startDate = parseDate(start, 'HH:mm')
    const businessStartIndex = startDate.diff(startDate.startOf('d'), 'm') / 15

    return {
      businessStartTime: start,
      businessEndTime: end,
      businessDuration: parseDate(end, 'HH:mm').diff(parseDate(start, 'HH:mm'), 'm') / 15,
      businessStartIndex,
    }
  }, [parseDate, tenant?.businessEndTime, tenant?.businessStartTime])

  const { businessHourBlocks, business15MinBlocks } = React.useMemo(() => {
    const beginningOfDay = moment().startOf('day')
    const start = moment(businessTime.businessStartTime, 'HH:mm')
    const end = moment(businessTime.businessEndTime, 'HH:mm')
    const hourStart = Math.floor((start.unix() - beginningOfDay.unix()) / 3600)
    const hourEnd = Math.ceil((end.unix() - beginningOfDay.unix()) / 3600)
    const minOffset = Math.ceil((start.unix() - beginningOfDay.unix()) / 900)
    const minBlocks = Math.ceil((end.unix() - start.unix()) / 900)
    return {
      businessHourBlocks: [...Array(hourEnd - hourStart)].map((_, index) => hourStart + index),
      business15MinBlocks: [...Array(minBlocks)].map((_, index) => minOffset + index),
    }
  }, [businessTime])

  const getShiftBarXbyStartTime = React.useCallback(
    (startTime: string) => {
      const splitTime = businessTime.businessStartTime.split(':') as [string, string]
      const businessStart = moment(startTime)
        .local()
        .hours(Number(splitTime[0]))
        .minutes(Number(splitTime[1]))
        .seconds(0)
      const start = moment(startTime).local()
      const delta = start.unix() - businessStart.unix() // businessStartTimeからの経過時間(秒)
      return delta / 900
    },
    [businessTime]
  )

  const getTimesByShiftBarX = React.useCallback(
    (x: number) => {
      const splitTime = businessTime.businessStartTime.split(':') as [string, string]
      const time = moment()
        .hours(Number(splitTime[0]))
        .minutes(Number(splitTime[1]))
        .seconds(0)
        .add(15 * x, 'minutes')
      return { hours: time.hours(), minutes: time.minutes() }
    },
    [businessTime]
  )

  // workDateが営業時間前の場合かのチェック
  const getWorkDate = React.useCallback(
    (workDate: string) => {
      const current = dayjs()

      // 現在日時とworkDateが一致しない場合はworkDateをそのまま返す
      if (workDate !== current.format('YYYY-MM-DD')) {
        return workDate
      }

      const startDateTime = dayjs(`${workDate} ${businessTime.businessStartTime}`, 'YYYY-MM-DD HH:mm')
      const isBefore = current.isBefore(startDateTime)

      // 現在日時が営業時間前の場合はworkDateの1日前の日時を返す
      return isBefore ? current.subtract(1, 'day').format('YYYY-MM-DD') : current.format('YYYY-MM-DD')
    },
    [businessTime]
  )

  return {
    ...businessTime,
    businessHourBlocks,
    business15MinBlocks,
    getShiftBarXbyStartTime,
    getTimesByShiftBarX,
    getWorkDate,
  }
}

export default useBusinessTime
