import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Container, Col, Row, Button } from 'reactstrap'

import { UNREACHABLE_ERROR_STATUS_CODE } from 'api/utils'

import { requestPasswordReset, selectSessionStatus } from 'slices/sessionSlice'

import { InputFormat, SinglePageCard } from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import { ColumnSizes } from 'components/common/utils'

import styles from './styles.module.scss'

type Props = {
  onEnterEmail: (enteredEmail: string) => void
}

const ResetRequestSend: React.FC<Props> = (props: Props) => {
  const [email, setEmail] = React.useState<string | undefined>(undefined)
  const [emailValidity, setEmailValidity] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const dispatch = useDispatch()
  const usersStatus = useSelector(selectSessionStatus, shallowEqual)
  const onSubmitEmail = (e: React.MouseEvent) => {
    e.preventDefault()
    if (email) {
      setSubmitted(true)
      dispatch(requestPasswordReset({ email }))
    }
  }

  React.useEffect(() => {
    if (!submitted || usersStatus.isRequesting || usersStatus.errorMessage === UNREACHABLE_ERROR_STATUS_CODE) {
      return
    }
    props.onEnterEmail(email!)
    setSubmitted(false)
  }, [submitted, usersStatus.isRequesting, props, email, usersStatus.errorMessage])

  const disabled = React.useMemo(() => !(email && emailValidity), [email, emailValidity])

  return (
    <SinglePageCard subtitle="パスワード再設定">
      <Container>
        <Row>
          <Col className="text-center font-x-small">
            アカウント登録時に使用したメールアドレスを入力してください。パスワード再設定の手順を送信します。誤ったメールアドレスを入力した場合、確認メールが届かないことがあります。
          </Col>
        </Row>

        <Row>
          <Col className="text-left mt-3">
            <InputFormat
              label="メールアドレス"
              placeholder="メールアドレス"
              className="mt-4"
              value={email}
              size={ColumnSizes.middle}
              maxLength={100}
              onChange={value => setEmail(value)}
              validations={[Rules.Required]}
              onValidate={setEmailValidity}
            />
          </Col>
        </Row>

        <Row>
          <Col className="text-center mt-3">
            <Button disabled={disabled} color="primary" className={styles.button} onClick={onSubmitEmail}>
              メールを送信
            </Button>
          </Col>
        </Row>

        <Row>
          <Col className="text-center mt-3">
            <Link className="text-decoration-none" to="/login">
              ログインに戻る
            </Link>
          </Col>
        </Row>
      </Container>
    </SinglePageCard>
  )
}

export default ResetRequestSend
