import axios from 'axios'

import type { CommonParams } from 'slices/utils'

import type { ColorType, ConnectionType } from 'components/common/types'

export type TenantSummaryResponse = {
  workspaceData: TenantWorkspaceData[]
  hourlyWorkData: TenantHourlyWorkData[]
}

export type TenantWorkspaceData = {
  workspaceId: number
  workspaceName: string
  data: TenantScheduleTypeRow[]
}

export type TenantScheduleTypeRow = {
  scheduleTypeId: number
  scheduleTypeName: string
  unit: string
  targetCount: number | null
  planCount: number | null
  planCountQuarterHourAgo: number | null
  planningHour: number | null
  todayPlanningHour: number | null
  recordCount: number | null
  recordCountQuarterHourAgo: number | null
  todayPlanCount: number | null
  lastImportedAt: string
}

export type TenantHourlyWorkData = {
  workspaceName: string
  scheduleTypeId: number
  scheduleTypeName: string
  scheduleTypeColor: ColorType
  unit: string
  connectionType: ConnectionType
  lastImportedAt: string
  data: TenantHourlyWorkRow[]
}

export type TenantHourlyWorkRow = {
  time: string
  planCount: number
  recordCount: number | null
  cumulativePlanCount: number
  cumulativeRecordCount: number | null
}

export type WorkspaceSummaryResponse = {
  workspaceData: WorkspaceSummaryData
  relatedWorkspaceData: WorkspaceSummaryData[]
}

export type WorkspaceSummaryData = {
  workspaceId: number
  workspaceName: string
  targets: WorkspaceSummaryTarget[]
  groups: WorkspaceSummaryGroup[]
}

export type WorkspaceSummaryTarget = {
  scheduleTypeId: number
  scheduleTypeName: string
  scheduleTypeColor: ColorType
  unit: string
  targetCount: number | null
}

export type WorkspaceSummaryGroup = {
  supportedWorkspaceId: number | null
  supportedWorkspaceName: string | null
  groupId: number | null
  groupName: string | null
  workers: WorkspaceSummaryWorker[]
}

export type WorkspaceSummaryWorker = {
  workerId: number
  workerName: string
  workerWmsMemberId: string
  hourlyWorkerData: WorkspaceSummaryHourlyWorkerData[]
}

export type WorkspaceSummaryHourlyWorkerData = {
  scheduleTypeId: number
  scheduleTypeName: string
  scheduleTypeColor: ColorType
  unit: string
  lastImportedAt: string
  data: HourlyWorkerRow[]
}

export type HourlyWorkerRow = {
  time: string
  planCount: number | null
  recordCount: number | null
}

export type TenantSummaryFilteringData = {
  dashboardFilter?: boolean
  workspaceId?: number
  scheduleTypeId?: number
}

export type TenantBopMonitoringResponse = {
  estimate: BopData
  actual: BopData
  updatedAt: string
  updatedByName: string
}

type BopTotalWorkspaceData = {
  totalSales: number
  sales: number
  otherSales: number
  totalCosts: number
  costOfGoodsSold: number
  fixedCosts: number
  otherCosts: number
  profit: number
  profitRatio: number
}

export type BopWorkspaceDataUnit = {
  sales: number
  otherSales: number
  materialCosts: number
  variableDirectLaborCosts: number
  variableIndirectLaborCosts: number
  indirectLaborCosts: number
  managementCosts: number
  workspaceCosts: number
  tenantCostsWithWorkspace: number
}

export type BopWorkspaceData = {
  id: number
  name: string
  data: BopWorkspaceDataUnit
}

type BopKeyScheduleTypeData = {
  id: number
  name: string
  data: {
    materialCosts: number
    variableDirectLaborCosts: number
    variableIndirectLaborCosts: number
    hourlyWorkerUnitCosts: number
    amountUnitCosts: number
  }
}

type BopData = {
  totalWorkspaceData: BopTotalWorkspaceData
  workspaces: BopWorkspaceData[]
  keyScheduleTypes: BopKeyScheduleTypeData[]
}

export type TenantBopMonitoringParam = {
  displayFilter?: boolean
  getWorkspaces?: string
  getKeyScheduleTypes?: number[]
  detail?: boolean
}

export type ScheduleTypeSummaryResponse = {
  hourlyWorkerData: HourlyWorkerRow[]
}

export type BopProfitAndLossResponse = TenantBopMonitoringResponse

export type WorkerCountWorkspaceData = {
  workspaceId: number
  workspaceName: string
  planSummary: ScheduleTypeWithWorkerCounts[]
}

export type WorkerCountsResponse = {
  workspaceData: WorkerCountWorkspaceData[]
}
export type ScheduleTypeWithWorkerCounts = {
  scheduleTypeId: number
  scheduleTypeName: string
  scheduleTypeColor: ColorType
  totalCount: number
  data: WorkerCount[]
}
export type WorkerCount = {
  time: string
  workerCount: number
}

export const getTenantSummary = (
  params: CommonParams,
  date: string,
  data: TenantSummaryFilteringData
): Promise<TenantSummaryResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/work-date/${date}/summary`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers, params: data })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getWorkspaceSummary = (
  params: CommonParams,
  workspaceId: number,
  date: string
): Promise<WorkspaceSummaryResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces/${workspaceId}/work-date/${date}/workspace-summary`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getBopMonitoring = (
  params: CommonParams,
  date: string,
  data: TenantBopMonitoringParam
): Promise<TenantBopMonitoringResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/work-date/${date}/bop`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers, params: data })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getBopProfitAndLoss = (params: CommonParams, date: string): Promise<BopProfitAndLossResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/work-date/${date}/bop?detail=true`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getWorkerCounts = (
  params: CommonParams,
  date: string,
  workspaceId?: number
): Promise<WorkerCountsResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/work-date/${date}/worker-counts`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    const header = workspaceId ? { headers, params: { workspaceId } } : { headers }

    axios
      .get(url, header)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getScheduleTypeSummary = (
  params: CommonParams,
  workspaceId: number,
  date: string,
  scheduleTypeId: number
): Promise<ScheduleTypeSummaryResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces/${workspaceId}/work-date/${date}/schedule-types/${scheduleTypeId}/summary`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
