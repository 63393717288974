import * as React from 'react'
import { Button, Col, FormGroup, Input, Label, FormFeedback } from 'reactstrap'

import { getUUID } from 'components/common/utils'

type InputFormProps = {
  label: string | JSX.Element
  disabledAddressAutoInput: boolean
  onChange: (value: string) => void
  onAddressAutoInput: () => void
  value?: string
  validations?: Array<(s: string | undefined) => string>
  onValidate?: (b: boolean) => void
}

export const PostalCodeInputForm: React.FC<InputFormProps> = ({
  label,
  disabledAddressAutoInput,
  onChange,
  onAddressAutoInput,
  value,
  validations,
  onValidate,
}) => {
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>('')

  const invalid = React.useCallback(
    (inputValue: string | undefined) => validations?.find(validation => validation(inputValue))?.(inputValue),
    [validations]
  )

  React.useEffect(() => {
    setErrorMessage(invalid(value))
  }, [invalid, value])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) =>
    onChange(e.target.value)

  const id = React.useMemo(() => `id-${getUUID()}`, [])

  // 直接onValidateを実行すると親のrender中に子コンポーネントから状態を変えることになりwarningが出る
  React.useEffect(() => onValidate?.(!errorMessage), [onValidate, value, errorMessage])
  return (
    <>
      <FormGroup row>
        <Label for={id} md={4}>
          {label}
        </Label>
        <Col md={4} className="align-self-center">
          <Input
            value={value || ''}
            invalid={!!errorMessage}
            maxLength={7}
            id={id}
            placeholder="郵便番号を入力"
            onChange={handleChange}
            onBlur={handleChange}
          />
          <FormFeedback hidden={!errorMessage}>{errorMessage}</FormFeedback>
          <div className="text-muted">ハイフンなしで入力してください</div>
        </Col>
        <Col>
          <Button outline color="dark" disabled={disabledAddressAutoInput} onClick={onAddressAutoInput}>
            住所検索
          </Button>
        </Col>
      </FormGroup>
    </>
  )
}
