import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'

import { importShift, selectPlansStatus } from 'slices/plansSlice'

import { CustomModal, FileInput } from 'components/common'

type Props = {
  isOpen: boolean
  onSuccess: () => void
  onCancel: () => void
}

const ShiftImport: React.FC<Props> = ({ isOpen, onSuccess, onCancel }) => {
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string | undefined>(undefined)
  const [file, setFile] = React.useState<File | null>(null)
  const [submitted, setSubmitted] = React.useState(false)
  const dispatch = useDispatch()
  const { isRequesting, errorMessage } = useSelector(selectPlansStatus, shallowEqual)

  const disabled = React.useMemo(() => !(file && /\.csv$/i.test(file.name)), [file])

  const handleSaveClick = () => {
    if (!file) {
      return
    }

    const reader = new FileReader()
    reader.addEventListener('load', () => {
      const content = reader.result
      if (content) {
        dispatch(importShift(file.name, content.toString()))
        setSubmitted(true)
      } else {
        setModalErrorMessage('ファイルが空です。')
      }
    })
    reader.addEventListener('error', () => setModalErrorMessage('ファイルの読み込みに失敗しました。'))
    reader.readAsText(file)
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      setModalErrorMessage(undefined)
      onSuccess()
    } else {
      if (ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
        setModalErrorMessage(undefined)
      } else {
        setModalErrorMessage('保存できませんでした。')
      }
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, onSuccess, dispatch])

  const handleCancelClick = () => {
    setModalErrorMessage(undefined)
    setSubmitted(false)
    onCancel()
  }

  return (
    <CustomModal
      isOpen={isOpen}
      title="シフトインポート"
      approveDisabled={disabled}
      errorMessage={modalErrorMessage}
      onCancel={handleCancelClick}
      onApprove={handleSaveClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
      submitName="shift-import-submit"
    >
      <div className="pb-4">
        <div className="mb-3">
          インポートフォーマットに適したCSVファイルを選択してください。
          フォーマットが異なる場合は正しく取り込みできません。
        </div>
        <FileInput id="shiftFile" accept=".csv" onChange={f => setFile(f)} />
      </div>
    </CustomModal>
  )
}

export default ShiftImport
