import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import * as API from 'api/allocations'
import { makeErrorMessage, UNREACHABLE_ERROR_STATUS_CODE, UNAUTHORIZED_ERROR_STATUS_CODE } from 'api/utils'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import { validateToken } from 'slices/sessionSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import * as Spinner from 'slices/spinnerSlice'
import { commonParams } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type AllocationState = {
  isRequesting: boolean
  errorMessage: string
  allocation?: API.AllocationData
  partialAllocations: API.PartialAllocationData[]
  workspaceId: number | null
}

const initialState: AllocationState = {
  isRequesting: false,
  errorMessage: '',
  allocation: undefined,
  partialAllocations: [],
  workspaceId: null,
}

export const allocationSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },

    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getAllocationListSuccess: (state, action: PayloadAction<API.AllocationListResponse>) => {
      state.isRequesting = false
      state.partialAllocations = _.sortBy(action.payload.partialAllocations, 'name')
    },
    getAllocationSuccess: (state, action: PayloadAction<API.AllocationResponse>) => {
      state.isRequesting = false
      state.allocation = action.payload.allocation
    },
    createAllocationSuccess: state => {
      state.isRequesting = false
    },
    updateAllocationSuccess: state => {
      state.isRequesting = false
    },
    deleteAllocationSuccess: state => {
      state.isRequesting = false
    },
    storeWorkspaceId: (state, action: PayloadAction<number>) => {
      state.workspaceId = action.payload
    },
  },
})

export const {
  startRequest,
  apiFailure,
  getAllocationListSuccess,
  getAllocationSuccess,
  createAllocationSuccess,
  updateAllocationSuccess,
  deleteAllocationSuccess,
  storeWorkspaceId,
} = allocationSlice.actions

export const getAllocationList =
  (workspaceId: number, callback?: (allocationList: API.PartialAllocationData[]) => void): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getAllocationList(commonParams(getState), workspaceId)
      .then((res: API.AllocationListResponse) => {
        callback?.(res.partialAllocations)
        dispatch(getAllocationListSuccess(res))
        dispatch(storeWorkspaceId(workspaceId))
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getAllocation =
  (workspaceId: number, allocationId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getAllocation(commonParams(getState), workspaceId, allocationId)
      .then((res: API.AllocationResponse) => dispatch(getAllocationSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const createAllocation =
  (workspaceId: number, data: API.UpdateAllocationProps): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.createAllocation(commonParams(getState), workspaceId, data)
      .then(() => dispatch(createAllocationSuccess()))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateAllocation =
  (workspaceId: number, allocationId: number, data: API.UpdateAllocationProps): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateAllocation(commonParams(getState), workspaceId, allocationId, data)
      .then(() => dispatch(updateAllocationSuccess()))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const deleteAllocation =
  (workspaceId: number, allocationId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.deleteAllocation(commonParams(getState), workspaceId, allocationId)
      .then(() => dispatch(deleteAllocationSuccess()))
      .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
      .finally(() => dispatch(Spinner.stop()))
  }

export const selectAllocationStatus = (state: RootState) => ({ ...state.allocations })

export default allocationSlice.reducer
