import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import type { RoleType } from 'api/users'
import { Role } from 'api/users'

import { selectSessionStatus } from 'slices/sessionSlice'
import { createUser, selectUsersStatus, clearErrorMessage, createTenantUser } from 'slices/usersSlice'

import { CardRadioButton, CustomModal, InputFormat } from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import { accountTypeName, ColumnSizes } from 'components/common/utils'

type Props = {
  isOpen: boolean
  tenantId?: number
  onClose: () => void
  onSuccess: () => void
}

const UserInvitation: React.FC<Props> = props => {
  const { isOpen, onClose, onSuccess } = props
  const [email, setEmail] = React.useState<string | undefined>()
  const [role, setRole] = React.useState<RoleType>(Role.Admin)
  const [emailValidity, setEmailValidity] = React.useState(false)
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = React.useState(false)
  const dispatch = useDispatch()
  const { user } = useSelector(selectSessionStatus, shallowEqual)
  const { isRequesting, errorMessage } = useSelector(selectUsersStatus, shallowEqual)
  const isAdmin = user.userHasTenants[0].role === Role.ProcessAdmin || user.userHasTenants[0].role === Role.Admin
  const tenantId = props.tenantId || user.userHasTenants[0].id

  React.useEffect(() => {
    setRole(isAdmin || props.tenantId ? Role.Admin : Role.TenantAdmin)
  }, [isAdmin, setRole, props])

  const handleInviteClick = () => {
    if (typeof email === 'undefined') {
      return
    }
    setSubmitted(true)
    if (tenantId && (isAdmin || props.tenantId)) {
      dispatch(createTenantUser(tenantId, { email, role }))
    } else {
      dispatch(createUser({ email }))
    }
  }

  const handleCancelClick = React.useCallback(() => {
    setEmail(undefined)
    setModalErrorMessage(undefined)
    setRole(isAdmin ? Role.Admin : Role.TenantAdmin)
    onClose()
  }, [isAdmin, onClose])

  const radioButtonItems = [
    {
      value: Role.Admin,
      label: accountTypeName(Role.Admin),
      subtitle: '全てのワークスペースの管理、ユーザーの招待/削除が行える管理者アカウント',
    },
    {
      value: Role.ProcessAdmin,
      label: accountTypeName(Role.ProcessAdmin),
      subtitle: '特定のワークスペースの管理が行える利用者アカウント',
    },
  ]

  const disabled = React.useMemo(() => !(email && emailValidity), [email, emailValidity])

  const onRadioButtonClick = (value: string) => {
    setRole(value === Role.Admin ? Role.Admin : Role.ProcessAdmin)
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      onSuccess()
      handleCancelClick()
    } else {
      setModalErrorMessage('送信できませんでした。')
    }
    setSubmitted(false)
    dispatch(clearErrorMessage())
  }, [submitted, onSuccess, handleCancelClick, dispatch, isRequesting, errorMessage])

  return (
    <CustomModal
      isOpen={isOpen}
      title="ユーザーの追加"
      approveLabel={isAdmin ? '招待を送信' : '送信'}
      approveDisabled={disabled}
      errorMessage={modalErrorMessage}
      onCancel={handleCancelClick}
      onApprove={handleInviteClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <div>
        <div className="mb-3">入力したメールアドレスに招待が送られます。</div>
        <InputFormat
          label="メールアドレス"
          placeholder="メールアドレス"
          value={email}
          size={ColumnSizes.middle}
          onChange={value => setEmail(value)}
          validations={[Rules.Required, Rules.Email]}
          onValidate={setEmailValidity}
          maxLength={100}
        />
      </div>

      {isAdmin && (
        <div className="mt-5">
          <div className="mb-3">招待を送るユーザーの管理権限を設定してください。</div>
          <CardRadioButton
            items={radioButtonItems}
            name="userAuthorityDialog"
            selected={role}
            onClick={onRadioButtonClick}
          />
        </div>
      )}
    </CustomModal>
  )
}

export default UserInvitation
