import axios from 'axios'

import type { CommonParams } from 'slices/utils'

import type { SkillData } from './skills'
import type { PartialTenantData, TenantData } from './tenants'
import type { CreateResponse, UpdateResponse, DeleteResponse } from './types'
import type { PartialWorkspaceData } from './workspaces'

export type PartialHourlyProductivities = {
  scheduleTypeId: number
  value: number | null
}

export type HourlyProductivities = PartialHourlyProductivities & {
  average: number | null
}

export type WorkersUploadResponse = {
  uploadUrl: string
}

export type PartialWorkerData = {
  id: number
  workDate: string
  revision: number
  name: string
  wmsMemberId: string | null
  workspaceId: number | null
  groupId: number | null
  groupLeader: boolean
  skillIds: number[]
  partialHourlyProductivities: PartialHourlyProductivities[]
  updatedAt: string
  updatedByName: string | null
}

export type WorkerListResponse = {
  partialTenant: PartialTenantData
  partialWorkspaces: PartialWorkspaceData[]
  partialWorkers: PartialWorkerData[]
}

export type WorkerData = Omit<PartialWorkerData, 'partialHourlyProductivities'> & {
  officialDutyId: number
  hiredAt: string
  hourlyProductivities: HourlyProductivities[]
}

export type WorkerResponse = {
  tenant: TenantData
  partialWorkspaces: PartialWorkspaceData[]
  worker: WorkerData
}

export type WorkerScheduleUpdateType = {
  scheduleTypeId: number | null
  startAt: string
  duration: number
}

export type UpdateWorkerEditDataType = {
  workDate?: string
  name?: string
  officialDutyId?: number | null
  workspaceId?: number
  groupId?: number | null
  groupLeader?: boolean
  hiredAt?: string | null
  skillIds?: number[]
  hourlyProductivities?: PartialHourlyProductivities[]
}

export type CreateWorkerEditDataType = {
  workDate?: string
  name: string
  wmsMemberId: string
  officialDutyId: number | null
  workspaceId: number | null
  groupId: number | null
  groupLeader: boolean
  hiredAt: string | null
  skillIds: number[]
  hourlyProductivities: PartialHourlyProductivities[]
}

export type CheckExportDataReadyResponse = {
  downloadUrl: string
}

export type WorkerEditType = {
  name: string | undefined
  wmsMemberId: string | null | undefined
  officialDuties: { key: number; value: string } | undefined
  workspace: { key: number; value: string } | undefined
  groupId: number | undefined
  groupLeader: boolean
  hiredAt: string | undefined
  skills: SkillData[]
  hourlyProductivities: HourlyProductivities[]
}

export const getWorkerList = (params: CommonParams): Promise<WorkerListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workers`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getWorker = (params: CommonParams, workerId: number): Promise<WorkerResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workers/${workerId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createWorker = (params: CommonParams, data: CreateWorkerEditDataType): Promise<CreateResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workers`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, { ...data }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateWorker = (
  params: CommonParams,
  workerId: number,
  data: UpdateWorkerEditDataType
): Promise<UpdateResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workers/${workerId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, { ...data }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const deleteWorker = (params: CommonParams, workerId: number): Promise<DeleteResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workers/${workerId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .delete(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const workersUploadUrl = (params: CommonParams, fileName: string): Promise<WorkersUploadResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workers/upload-url`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, { fileName }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const putUploadUrl = (url: string, csvContent: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const headers = {
      'Content-Type': 'text/csv',
    }
    axios
      .put(url, csvContent, { headers })
      .then(() => resolve())
      .catch(error => reject(error))
  })
}

export const exportWorkers = (
  params: CommonParams,
  targetWorkspaceIds: number[],
  replaceAverageProductivity: boolean,
  includeUnaffiliatedWorker: boolean
): Promise<CheckExportDataReadyResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workers/data-export`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, { targetWorkspaceIds, replaceAverageProductivity, includeUnaffiliatedWorker }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
