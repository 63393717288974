import moment from 'moment'
import * as React from 'react'

import { DateChangeButton } from 'components/common'

import useDateQuery from 'hooks/useDateQuery'

type Props = {
  onChange: (date: string) => void
}
const DashboardDateChangeButton: React.FC<Props> = ({ onChange }) => {
  const date = useDateQuery()

  const filterDate = React.useCallback((newDate: Date) => {
    const targetDate = newDate.getTime()
    const date425DaysAgo = new Date().getTime() - 425 * 24 * 60 * 60 * 1000
    return targetDate > date425DaysAgo
  }, [])

  const onChangeDate = React.useCallback((newDate: Date) => onChange(moment(newDate).format('YYYY-MM-DD')), [onChange])

  return (
    <div>
      <DateChangeButton date={date} popupPosition="bottom right" filterDate={filterDate} onChange={onChangeDate} />
    </div>
  )
}
export default DashboardDateChangeButton
