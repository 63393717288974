import * as React from 'react'
import { Button, ButtonGroup } from 'reactstrap'

type GroupRadioButtonItem = {
  id: string
  label: string
}

export type Props = {
  items: GroupRadioButtonItem[]
  initSelectedId?: string
  onChange: (selectedId: string) => void
}

const GroupRadioButton: React.FC<Props> = ({ items, initSelectedId = '', onChange }) => {
  const [selectedId, setSelectedId] = React.useState(initSelectedId)

  React.useEffect(() => {
    setSelectedId(prev => prev || items[0].id)
  }, [items])

  const onChangeToggle = React.useCallback(
    (id: string) => {
      setSelectedId(id)
      onChange(id)
    },
    [onChange]
  )

  return (
    <ButtonGroup>
      {items.map((item: GroupRadioButtonItem) => {
        return (
          <Button
            key={`groupRadioButton-${item.id}`}
            color="primary"
            outline
            onClick={() => onChangeToggle(item.id)}
            active={selectedId === item.id}
          >
            {item.label}
          </Button>
        )
      })}
    </ButtonGroup>
  )
}

export default GroupRadioButton
