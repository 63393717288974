import moment from 'moment'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import App from 'components/App/App'

import * as serviceWorker from 'serviceWorker'
import { store } from 'store'
import 'bootstrap.scss'
import 'fonts/sbc-icon.scss'
import 'index.scss'

moment.locale('ja', { weekdays: ['日', '月', '火', '水', '木', '金', '土'] })

const startApp = async () => {
  if (process.env.REACT_APP_ENABLE_MOCKS === 'true') {
    const { worker } = await import('./__mocks__/browser')
    await worker.start({
      onUnhandledRequest: 'bypass', // モックデータが存在しない場合はAPIコールする
      quiet: false, // ログ出力を有効にする
    })
  }

  const container = document.getElementById('root')
  const root = createRoot(container!)
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  )
}

startApp()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
