import _ from 'lodash'
import * as React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { selectSessionStatus } from 'slices/sessionSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'

/*
必ずworkspaceIdが選択されなければならないコンポーネントで使用する
workspaceIdが選択されていない場合(pathにworkspaceIdが存在しないとき)は、
user.userHasTenants[0].managedWorkspacesを用いて以下の要領で選択する
・managedWorkspacesが存在するとき、managedWorkspacesの1件目を選択する（＝管理中ワークスペースの1件目となる）
・managedWorkspacesが存在しないとき、partialWorkspacesの1件目を選択する（＝その他ワークスペースの1件目となる）
*/

const useWorkspaceSideBar = (path: string) => {
  const navigate = useNavigate()
  const { workspaceId } = useParams<'workspaceId'>()
  const dispatch = useDispatch()

  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { user } = useSelector(selectSessionStatus, shallowEqual)

  React.useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  React.useEffect(() => {
    if (_.isEmpty(partialWorkspaces) || workspaceId) {
      return
    }

    const managedWorkspaces = user.userHasTenants[0].managedWorkspaces.filter(w => w.isManagement)
    const sortedManagedWorkspaces = _.sortBy(managedWorkspaces, 'name')

    if (!_.isEmpty(sortedManagedWorkspaces)) {
      navigate(`${path}/${sortedManagedWorkspaces[0].id}`, { replace: true })
      return
    }

    const sortedEtcWorkspaces = _.sortBy(partialWorkspaces, 'name')
    if (!_.isEmpty(sortedEtcWorkspaces)) {
      navigate(`${path}/${sortedEtcWorkspaces[0].id}`, { replace: true })
    }
  }, [workspaceId, navigate, partialWorkspaces, path, user.userHasTenants])

  const workspace = React.useMemo(
    () => partialWorkspaces.find(w => w.id === Number(workspaceId)),
    [partialWorkspaces, workspaceId]
  )

  const workspaceName = React.useMemo(() => workspace?.name || '', [workspace])

  return { workspaceName, workspaceId, workspace }
}
export default useWorkspaceSideBar
