import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'

import type { HourlyProductivities, WorkerEditType } from 'api/workers'

import { getRelatedScheduleType, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'

const initData: WorkerEditType = {
  name: undefined,
  wmsMemberId: undefined,
  officialDuties: undefined,
  workspace: undefined,
  groupId: undefined,
  groupLeader: false,
  hiredAt: undefined,
  skills: [],
  hourlyProductivities: [],
}
const useWorker = () => {
  const [editData, setEditData] = React.useState<WorkerEditType>(initData)
  const [nameValidity, setNameValidity] = React.useState(false)
  const dispatch = useDispatch()

  const { allScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)

  React.useEffect(() => {
    dispatch(getRelatedScheduleType())
  }, [dispatch])

  const setHourlyProductivities = React.useCallback(
    (value: HourlyProductivities[]) => setEditData(prev => ({ ...prev, hourlyProductivities: value })),
    []
  )

  const showHourlyProductivities = React.useMemo(() => !_.isEmpty(allScheduleTypes), [allScheduleTypes])

  const disabled = React.useMemo(() => !nameValidity, [nameValidity])

  return {
    setHourlyProductivities,
    showHourlyProductivities,
    disabled,
    editData,
    setEditData,
    setNameValidity,
    initData,
  }
}
export default useWorker
