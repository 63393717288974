import { createSlice } from '@reduxjs/toolkit'

import * as API from 'api/skills'
import { makeErrorMessage, UNREACHABLE_ERROR_STATUS_CODE, UNAUTHORIZED_ERROR_STATUS_CODE } from 'api/utils'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import { validateToken } from 'slices/sessionSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import * as Spinner from 'slices/spinnerSlice'
import { commonParams } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type SkillState = {
  isRequesting: boolean
  errorMessage: string
  skill?: API.SkillData
  skills: API.SkillData[]
}

const initialState: SkillState = {
  isRequesting: false,
  errorMessage: '',
  skill: undefined,
  skills: [],
}

export const skillsSlice = createSlice({
  name: 'skills',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getSkillListSuccess: (state, action: PayloadAction<API.SkillListResponse>) => {
      state.isRequesting = false
      state.skills = action.payload.skills
    },
    getSkillSuccess: (state, action: PayloadAction<API.SkillResponse>) => {
      state.isRequesting = false
      state.skill = action.payload.skill
    },
    createSkillSuccess: state => {
      state.isRequesting = false
    },
    updateSkillSuccess: state => {
      state.isRequesting = false
    },
    deleteSkillSuccess: state => {
      state.isRequesting = false
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getSkillListSuccess,
  getSkillSuccess,
  createSkillSuccess,
  updateSkillSuccess,
  deleteSkillSuccess,
} = skillsSlice.actions

export const getSkillList = (): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.getSkillList(commonParams(getState))
    .then((res: API.SkillListResponse) => dispatch(getSkillListSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const getSkill =
  (skillId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getSkill(commonParams(getState), skillId)
      .then((res: API.SkillResponse) => dispatch(getSkillSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const createSkill =
  (name: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.createSkill(commonParams(getState), name)
      .then(() => {
        dispatch(createSkillSuccess())
        dispatch(getSkillList())
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateSkill =
  (skillId: number, name: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateSkill(commonParams(getState), skillId, name)
      .then(() => {
        dispatch(updateSkillSuccess())
        dispatch(getSkillList())
        dispatch(getSkill(skillId))
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const deleteSkill =
  (skillId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.deleteSkill(commonParams(getState), skillId)
      .then(() => {
        dispatch(deleteSkillSuccess())
        dispatch(getSkillList())
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const selectSkillsStatus = (state: RootState) => ({ ...state.skills })

export default skillsSlice.reducer
