import dayjs from 'dayjs'
import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Card, CardBody, CardText, CardTitle, Col, FormGroup, Input, Label, Row } from 'reactstrap'

import { CONFLICT_ERROR_STATUS_CODE, ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'
import type { PartialWorkerData, WorkerEditType } from 'api/workers'

import { getGroupList, selectGroupsStatus } from 'slices/groupsSlice'
import { showError, showSuccess } from 'slices/notificationSlice'
import { getOfficialDutiesList, selectOfficialDutiesStatus } from 'slices/officialDutiesSlice'
import { getSkillList, selectSkillsStatus } from 'slices/skillsSlice'
import { selectTenantsStatus } from 'slices/tenantsSlice'
import { updateWorker, selectWorkersStatus, getWorkerList, getWorker } from 'slices/workersSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'

import {
  CheckBoxFormat,
  DatePicker,
  InputFormat,
  List,
  SelectBoxFormat,
  CardSubmitFooter,
  CustomButton,
  ItemEdit,
  NavMenu,
} from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import type { ListItem, FilterItem, SuggestionItem } from 'components/common/types'
import { MAGIQANNEAL_APPLICATION_ID, ColumnSizes } from 'components/common/utils'

import useWorker from 'hooks/useWorker'

import placeholder from 'images/allEmpty.svg'

import HourlyProductivitiesInput from './PerformanceIndicesInput'
import WorkerDelete from './WorkerDelete'
import WorkerFilter from './WorkerFilter'
import WorkersExportDialog from './WorkersExportDialog'
import WorkersImportDialog from './WorkersImportDialog'
import WorkersOptEngineDialog from './WorkersOptEngineDialog'

import styles from './WorkerList.module.scss'

// WorkerEditTypeからWorkerEditDataTypeにキー名を変更するための関数
const transformWorkerEditKeys = (data: WorkerEditType) => {
  const workerEditDataKey = {
    officialDuties: 'officialDutyId',
    workspace: 'workspaceId',
    group: 'groupId',
    skills: 'skillIds',
  }
  return _.mapKeys(data, (_value, key) => _.get(workerEditDataKey, key) || key)
}

const NO_WORKSPACE_ASSIGNED = 0
export const OFFICIAL_DUTY_BLANK_ID = -1

const WorkerList: React.FC = () => {
  const [selectedWorkerId, setSelectedWorkerId] = React.useState<number | undefined>(undefined)
  const {
    showHourlyProductivities,
    disabled,
    editData,
    setEditData,
    setNameValidity,
    initData,
    setHourlyProductivities,
  } = useWorker()
  const [initEditData, setInitEditData] = React.useState<WorkerEditType>(initData)
  const [submitted, setSubmitted] = React.useState(false)
  const [openDelete, setOpenDelete] = React.useState(false)
  const [openWorkersOptEngineDialog, setOpenWorkersOptEngineDialog] = React.useState(false)
  const [openWorkersImportDialog, setOpenWorkersImportDialog] = React.useState(false)
  const [openWorkersExportDialog, setOpenWorkersExportDialog] = React.useState(false)
  const [filterItems, setFilterItems] = React.useState<FilterItem[]>([])
  const [filterWord, setFilterWord] = React.useState('')
  const [forecastColorScheduleTypeIds, setForecastColorScheduleTypeIds] = React.useState<number[]>([])

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isRequesting, errorMessage } = useSelector(selectWorkersStatus, shallowEqual)
  const { partialWorkers, worker } = useSelector(selectWorkersStatus, shallowEqual)
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { skills } = useSelector(selectSkillsStatus, shallowEqual)
  // apiKey を取得する
  const { groups } = useSelector(selectGroupsStatus, shallowEqual)
  const { tenant } = useSelector(selectTenantsStatus, shallowEqual)
  const { partialOfficialDutiesList } = useSelector(selectOfficialDutiesStatus, shallowEqual)

  React.useEffect(() => {
    dispatch(getWorkerList())
    dispatch(getWorkspaceList())
    dispatch(getSkillList())
    dispatch(getOfficialDutiesList())
  }, [dispatch])

  const { apiKey, magiQannealTenant, magiQannealLocations } = React.useMemo(() => {
    const application = tenant?.optionApplications.find(app => app.applicationId === MAGIQANNEAL_APPLICATION_ID)
    return {
      apiKey: application?.options.apiKey ?? '',
      magiQannealTenant: application?.options.tenant ?? '',
      magiQannealLocations: application?.options.relatedWorkspaceData?.map(rw => rw.location) ?? [],
    }
  }, [tenant])

  const listItems = React.useMemo(
    () =>
      partialWorkers.reduce((acc: ListItem[], cur: PartialWorkerData) => {
        const filtered = filterWord === '' || cur.name.includes(filterWord) || cur.wmsMemberId?.includes(filterWord)
        if (!filtered) {
          return acc
        }
        const data = filterItems
          .filter(f => f.checked)
          .find(item =>
            cur.workspaceId === null ? item.key === NO_WORKSPACE_ASSIGNED : item.key === cur.workspaceId
          )?.label
        if (data) {
          acc.push({
            id: cur.id,
            title: cur.name,
            data,
          })
        }
        return acc
      }, []),
    [partialWorkers, filterWord, filterItems]
  )

  const hasListItems = React.useMemo(() => !_.isEmpty(listItems), [listItems])

  React.useEffect(() => {
    setSelectedWorkerId(prev => {
      // 0件の場合、undefinedで未登録画面を表示させる
      if (_.isEmpty(listItems)) {
        return undefined
      }
      // 初期化時とアイテム削除時
      if (prev === undefined || !listItems.some(item => item.id === prev)) {
        return Number(listItems[0].id)
      }
      return prev
    })
  }, [listItems])

  React.useEffect(() => {
    if (!selectedWorkerId) {
      return
    }
    dispatch(getWorker(selectedWorkerId))
  }, [selectedWorkerId, dispatch])

  React.useEffect(() => {
    const initFilterItem = partialWorkspaces
      .map(workspace => ({
        key: workspace.id,
        label: workspace.name,
        checked: true,
      }))
      .concat([{ key: NO_WORKSPACE_ASSIGNED, label: 'ワークスペース未所属', checked: true }])
    setFilterItems(initFilterItem)
    setFilterWord('')
  }, [partialWorkspaces])

  // editData, initEditDataの初期化
  React.useEffect(() => {
    if (!worker) {
      return
    }

    const nextOfficialDuties = partialOfficialDutiesList?.find(
      officialDuties => officialDuties.id === worker.officialDutyId
    )
    const nextWorkspace = partialWorkspaces.find(({ id }) => id === worker.workspaceId)
    const nextSelectedSkills = skills.filter(skill => worker.skillIds.includes(skill.id))

    const data = {
      name: worker.name,
      wmsMemberId: worker.wmsMemberId,
      officialDuties: nextOfficialDuties
        ? { key: nextOfficialDuties.id, value: nextOfficialDuties.name ?? '' }
        : undefined,
      workspace: nextWorkspace ? { key: nextWorkspace.id, value: nextWorkspace.name } : undefined,
      groupId: worker.groupId ?? undefined,
      groupLeader: worker.groupLeader,
      hiredAt: worker.hiredAt,
      skills: nextSelectedSkills,
      hourlyProductivities: worker.hourlyProductivities,
    }
    setEditData(prev => (_.isEqual(prev, data) ? prev : data))
    setInitEditData(prev => (_.isEqual(prev, data) ? prev : data))
  }, [skills, worker, partialOfficialDutiesList, partialWorkspaces, setEditData])

  const onSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterWord(event.target.value)
  }

  const onSearchClick = (items: FilterItem[]) => {
    setFilterItems(items)
  }

  const handleWorkspaceIdListChange = (workerId: string | number) => {
    if (worker?.id !== workerId) {
      setSelectedWorkerId(Number(workerId))
    }
  }

  React.useEffect(() => {
    if (!editData?.workspace?.key) {
      return
    }
    dispatch(getGroupList(editData.workspace.key))
  }, [dispatch, editData?.workspace?.key])

  React.useEffect(() => {
    // groupsから選択中のgroupが見つからない場合はgroup選択状態リセット
    setEditData(prev => {
      if (prev.groupId && !groups.some(group => group.id === prev.groupId)) {
        return { ...prev, groupId: undefined, groupLeader: false }
      }
      return prev
    })
  }, [groups, setEditData])

  React.useEffect(() => {
    // dutiesから選択中のofficialDutiesが見つからない場合はofficialDuties選択状態リセット
    setEditData(prevEditData => {
      if (
        prevEditData.officialDuties &&
        !partialOfficialDutiesList.find(duty => duty.id === prevEditData.officialDuties?.key)
      ) {
        return { ...prevEditData, officialDuty: undefined }
      }
      return prevEditData
    })
  }, [partialOfficialDutiesList, setEditData])

  const workspaceSelectItems = React.useMemo(
    () =>
      partialWorkspaces.map(workspace => ({
        key: workspace.id,
        value: workspace.name,
      })),
    [partialWorkspaces]
  )

  const officialDutiesSelectItems = React.useMemo(() => {
    const items = partialOfficialDutiesList.map(officialDuties => ({
      key: officialDuties.id,
      value: officialDuties.name ?? '',
    }))

    items.unshift({ key: OFFICIAL_DUTY_BLANK_ID, value: '未設定' })
    return items
  }, [partialOfficialDutiesList])

  const groupSelectItems = React.useMemo(
    () => (editData.workspace?.key ? groups.map(group => ({ key: group.id, value: group.name })) : []),
    [editData.workspace?.key, groups]
  )

  const unchanged = React.useMemo(() => _.isEqual(editData, initEditData), [editData, initEditData])

  const showOptEngineButton = React.useMemo(() => !!apiKey, [apiKey])

  const onSubmit = () => {
    if (!worker) {
      dispatch(showError({ errorMessage: '不正なデータです。' }))
      return
    }

    const isWorkspaceChanged = !_.isEqual(editData.workspace?.key, initEditData.workspace?.key)

    const allData = {
      name: editData.name || '',
      officialDutyId:
        editData.officialDuties?.key === OFFICIAL_DUTY_BLANK_ID ? null : editData.officialDuties?.key ?? null,
      workspaceId: editData.workspace?.key || null,
      groupId: isWorkspaceChanged && !editData.groupId ? null : editData.groupId,
      groupLeader: isWorkspaceChanged && !editData.groupLeader ? false : editData.groupLeader,
      hiredAt: editData.hiredAt || null,
      skillIds: editData.skills.map(skill => skill.id),
      hourlyProductivities: editData.hourlyProductivities.map(productivity => _.omit(productivity, 'average')),
    }

    const transformedEditData = transformWorkerEditKeys(editData)
    const transformedInitEditData = transformWorkerEditKeys(initEditData)

    // initEditDataとEditDataを比較し、変更箇所のみを送信する。
    const data = _.pickBy(
      allData,
      (_value, key) => !_.isEqual(_.get(transformedEditData, key), _.get(transformedInitEditData, key))
    )

    if (_.isEmpty(data)) {
      dispatch(showError({ errorMessage: '不正なデータです。' }))
      return
    }

    setSubmitted(true)
    dispatch(updateWorker(worker.id, data))
  }

  const onCancel = () => {
    setEditData(initEditData)
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
      setForecastColorScheduleTypeIds([])
    } else {
      if (errorMessage === CONFLICT_ERROR_STATUS_CODE) {
        dispatch(showError({ errorMessage: 'ID・識別番号が重複しています。' }))
      } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
        dispatch(showError())
      }
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch])

  const onDeleteSuccess = () => {
    dispatch(showSuccess())
    setOpenDelete(false)
  }

  const handleChangeWorkspace = React.useCallback(
    (key: string | number | undefined) => {
      const workspaceId = Number(key)
      if (isNaN(workspaceId) || editData.workspace?.key === workspaceId) {
        return
      }
      const targetWorkspace = partialWorkspaces.find(({ id }) => id === workspaceId)
      const workspace = targetWorkspace ? { key: targetWorkspace.id, value: targetWorkspace.name } : undefined

      const isWorkerInWorkspace = worker && workspaceId === worker.workspaceId

      setEditData(prev => {
        // 元々ワーカーが所属しているワークスペースが再度選択された場合、所属グループとリーダー情報をリセットする
        const groupId = isWorkerInWorkspace ? worker.groupId ?? undefined : undefined
        const groupLeader = isWorkerInWorkspace ? worker.groupLeader : false
        return {
          ...prev,
          workspace,
          groupId,
          groupLeader,
        }
      })
      dispatch(getGroupList(workspaceId))
    },
    [editData.workspace?.key, partialWorkspaces, setEditData, dispatch, worker]
  )

  const handleWorkersImport = () => {
    setOpenWorkersImportDialog(false)
    dispatch(
      showSuccess({
        successMessage: 'メンバー情報のアップロードに成功しました。反映まで最大30分程度かかる場合があります。',
      })
    )
  }

  const handleSkillEdit = (items: SuggestionItem[]) => {
    const skillData = skills.filter(s => items.some(i => i.id === s.id))
    setEditData({ ...editData, skills: skillData })
  }

  return (
    <NavMenu>
      <div className="mt-3 mx-3">
        <div className="d-flex justify-content-between mb-3">
          <div className="font-x-large fw-bold align-self-center">メンバー一覧</div>
          <div className="d-flex">
            {showOptEngineButton && (
              <CustomButton className="me-2" outline onClick={() => setOpenWorkersOptEngineDialog(true)}>
                magiQannealと連携
              </CustomButton>
            )}
            <CustomButton className="me-2" outline onClick={() => setOpenWorkersImportDialog(true)}>
              インポート
            </CustomButton>
            <CustomButton className="me-2" outline onClick={() => setOpenWorkersExportDialog(true)}>
              エクスポート
            </CustomButton>
            <CustomButton icon="plus" onClick={() => navigate('/worker-create')}>
              メンバー追加
            </CustomButton>
          </div>
        </div>
        <Row className={styles.row}>
          <Col md={4} className="h-100">
            <Card className={`position-sticky h-100 ${styles.list}`}>
              <div className="d-flex">
                <Input placeholder="メンバー名もしくはID・識別番号で検索" onChange={onSearchInput}></Input>
                <div className="ms-2">
                  <WorkerFilter filterItems={filterItems} onChange={onSearchClick} />
                </div>
              </div>
              {hasListItems ? (
                <List
                  items={listItems}
                  selectedId={worker?.id}
                  onAction={
                    ((workerId: number) => handleWorkspaceIdListChange(workerId)) as (selected: string | number) => void
                  }
                />
              ) : (
                <CardBody className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img className={`mx-auto d-block w-100 ${styles.placeholderImage}`} src={placeholder} alt="" />
                    <div className="font-middle fw-bold py-4">メンバーがいません</div>
                    <div>メンバーを追加して、詳細情報を編集しましょう。</div>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
          <Col md={8} className="h-100">
            <Card className="h-100">
              {hasListItems ? (
                <>
                  <div className={styles.memberDetail}>
                    <CardBody>
                      <CardTitle className="font-large fw-bold">メンバー詳細</CardTitle>
                      <InputFormat
                        label="名前※"
                        placeholder="メンバー名を入力"
                        value={editData.name}
                        size={ColumnSizes.middle}
                        maxLength={100}
                        onChange={value => setEditData({ ...editData, name: value.trim() })}
                        validations={[Rules.Required]}
                        onValidate={setNameValidity}
                        className="mb-3"
                      />
                      <InputFormat
                        label="ID・識別番号"
                        value={editData.wmsMemberId || ''}
                        size={ColumnSizes.middle}
                        disabled={true}
                        className="mb-3"
                      />
                      <FormGroup row>
                        <Label for="hiredAt" md={4}>
                          入社日
                        </Label>
                        <Col md={4} className="align-self-center">
                          <DatePicker
                            value={editData.hiredAt}
                            placeholder="入社日を選択"
                            onChange={date => setEditData({ ...editData, hiredAt: dayjs(date).format('YYYY-MM-DD') })}
                          />
                        </Col>
                      </FormGroup>

                      {!_.isEmpty(partialOfficialDutiesList) && (
                        <SelectBoxFormat
                          label="職掌"
                          placeholder="職掌を選択"
                          formText="選択された職掌の平均時給を用いて費用を計算します。"
                          value={(editData.officialDuties?.key || OFFICIAL_DUTY_BLANK_ID).toString()}
                          size={ColumnSizes.middle}
                          items={officialDutiesSelectItems}
                          onChange={e =>
                            setEditData({
                              ...editData,
                              officialDuties: officialDutiesSelectItems.find(item => item.key === e.key),
                            })
                          }
                          className="mb-3"
                        />
                      )}

                      <SelectBoxFormat
                        label="所属ワークスペース"
                        placeholder="ワークスペースを選択"
                        formText="メンバーに予定を設定するためにはワークスペースに所属させてください。"
                        value={editData.workspace?.key.toString()}
                        size={ColumnSizes.middle}
                        items={workspaceSelectItems}
                        onChange={e => handleChangeWorkspace(e.key)}
                        className="mb-3"
                      />
                      {groupSelectItems.length > 0 && (
                        <>
                          <SelectBoxFormat
                            label="所属グループ"
                            placeholder="グループを選択"
                            value={groups.find(g => g.id === editData.groupId)?.id.toString()}
                            size={ColumnSizes.middle}
                            items={groupSelectItems}
                            onChange={e => {
                              const group = groupSelectItems.find(g => g.key.toString() === e.key?.toString())
                              setEditData(prev => ({ ...prev, groupId: group?.key }))
                            }}
                            className="mb-3"
                          />
                          {editData.groupId && (
                            <CheckBoxFormat
                              label="グループリーダー"
                              checkboxLabel="リーダー"
                              checked={editData.groupLeader}
                              onChange={e =>
                                setEditData({
                                  ...editData,
                                  groupLeader: e.target.checked,
                                })
                              }
                              className="mb-3"
                            />
                          )}
                        </>
                      )}
                    </CardBody>

                    <CardBody>
                      <CardTitle className="font-large fw-bold">スキル設定</CardTitle>
                      <CardText className="py-2">
                        メンバーにはスキルを設定することができます。新しいスキルは
                        <Link className="text-decoration-none" to="/skills">
                          スキル管理
                        </Link>
                        で登録してください。
                      </CardText>
                      <ItemEdit
                        items={skills.map(s => ({ id: s.id, value: s.name }))}
                        selectedItems={editData.skills.map(s => ({
                          id: s.id,
                          value: s.name,
                        }))}
                        label="メンバーにスキルを追加"
                        itemName="スキル"
                        onChange={handleSkillEdit}
                      />
                    </CardBody>
                    {showHourlyProductivities && (
                      <CardBody>
                        <CardTitle className="font-large fw-bold">人時生産性設定</CardTitle>
                        <CardText>
                          全てのワークスペースに登録されている作業に対する人時生産性を設定する事ができます。所属ワークスペース以外の作業についても人時生産性を設定しておく事でワークスペース間を移動して作業した際にも人時生産性を計算する事ができます。
                        </CardText>
                        <HourlyProductivitiesInput
                          workerId={worker?.id}
                          forecastColorScheduleTypeIds={forecastColorScheduleTypeIds}
                          onFocus={setForecastColorScheduleTypeIds}
                          hourlyProductivities={editData.hourlyProductivities}
                          onChange={setHourlyProductivities}
                        />
                      </CardBody>
                    )}

                    <CardBody>
                      <CardTitle className="font-large fw-bold">メンバーの削除</CardTitle>
                      <CardText>メンバーを削除すると、作業履歴情報などはすべて失われ、復旧できません。</CardText>
                      <Button outline color="danger" className="my-3" onClick={() => setOpenDelete(true)}>
                        このメンバーを削除
                      </Button>
                    </CardBody>
                  </div>
                  <CardSubmitFooter
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    updatedBy={worker?.updatedByName}
                    updatedAt={worker?.updatedAt}
                    cancelDisabled={unchanged}
                    submitDisabled={unchanged || disabled}
                    submitName="worker-list-submit"
                  />
                </>
              ) : (
                <CardBody className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholder} alt="" />
                    <div className="font-middle fw-bold py-4">メンバーが選択されていません</div>
                    <div>メンバーを選択して、詳細情報を編集しましょう。</div>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>

        {worker && (
          <WorkerDelete
            isOpen={openDelete}
            workerId={worker.id}
            onSuccess={onDeleteSuccess}
            onCancel={() => setOpenDelete(false)}
          />
        )}

        <WorkersOptEngineDialog
          isOpen={openWorkersOptEngineDialog}
          apiKey={apiKey}
          magiQannealTenant={magiQannealTenant}
          magiQannealLocations={magiQannealLocations}
          onCancel={() => setOpenWorkersOptEngineDialog(false)}
        />

        <WorkersImportDialog
          isOpen={openWorkersImportDialog}
          onSuccess={handleWorkersImport}
          onCancel={() => setOpenWorkersImportDialog(false)}
        />

        <WorkersExportDialog
          isOpen={openWorkersExportDialog}
          onSuccess={() => setOpenWorkersExportDialog(false)}
          onCancel={() => setOpenWorkersExportDialog(false)}
        />
      </div>
    </NavMenu>
  )
}

export default WorkerList
