import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import { makeErrorMessage, UNREACHABLE_ERROR_STATUS_CODE, UNAUTHORIZED_ERROR_STATUS_CODE } from 'api/utils'
import type { PartialWorkspaceData, WorkspaceData } from 'api/workspaces'
import * as API from 'api/workspaces'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import { validateToken } from 'slices/sessionSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import * as Spinner from 'slices/spinnerSlice'
import { commonParams } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type WorkspacesState = {
  isRequesting: boolean
  errorMessage: string
  partialWorkspaces: PartialWorkspaceData[]
  workspace?: WorkspaceData
  workspaceExists: boolean
}

const initialState: WorkspacesState = {
  isRequesting: false,
  errorMessage: '',
  partialWorkspaces: [],
  workspace: undefined,
  workspaceExists: false,
}

export const workspacesSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getWorkspaceListSuccess: (state, action: PayloadAction<API.WorkspaceListResponse>) => {
      state.isRequesting = false
      state.partialWorkspaces = _.sortBy(
        action.payload.partialWorkspaces.map(workspace => ({
          ...workspace,
          scheduleTypeNames: _.sortBy(workspace.scheduleTypeNames),
        })),
        'name'
      )
    },
    getWorkspaceSuccess: (state, action: PayloadAction<API.WorkspaceResponse>) => {
      state.isRequesting = false
      state.workspace = action.payload.workspace
    },
    workspaceExistsSuccess: state => {
      state.isRequesting = false
      state.workspaceExists = true
    },
    workspaceExistsError: state => {
      state.isRequesting = false
      state.workspaceExists = false
    },
    requestSuccess: state => {
      state.isRequesting = false
    },
    clearWorkspaceList: state => {
      state.partialWorkspaces = []
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getWorkspaceListSuccess,
  getWorkspaceSuccess,
  requestSuccess,
  clearWorkspaceList,
  workspaceExistsSuccess,
  workspaceExistsError,
} = workspacesSlice.actions

export const getWorkspaceList =
  (data?: { workDate?: string; tenantId?: number }): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }
    const tenantId = data ? data.tenantId : undefined
    const workDate = data ? data.workDate : undefined
    const params = tenantId ? { ...commonParams(getState), tenantId } : commonParams(getState)

    dispatch(Spinner.start())
    API.getWorkspacesList(params, workDate)
      .then((res: API.WorkspaceListResponse) => dispatch(getWorkspaceListSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getWorkspace =
  (workspaceId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getWorkspace(commonParams(getState), workspaceId)
      .then((res: API.WorkspaceResponse) => dispatch(getWorkspaceSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const checkWorkspaceExists =
  (workspaceId: number, date?: string, callback?: (workspaceExits: boolean) => void): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getWorkspace(commonParams(getState), workspaceId, date)
      .then(() => {
        dispatch(workspaceExistsSuccess())
        callback?.(true)
      })
      .catch(() => {
        dispatch(workspaceExistsError())
        callback?.(false)
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const createWorkspace =
  (data: API.EditWorkspaceProps): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.createWorkspace(commonParams(getState), data)
      .then(() => {
        dispatch(requestSuccess())
        dispatch(getWorkspaceList())
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateWorkspace =
  (workspaceId: number, data: API.EditWorkspaceProps): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateWorkspace(commonParams(getState), workspaceId, data)
      .then(() => {
        dispatch(requestSuccess())
        dispatch(getWorkspaceList())
        dispatch(getWorkspace(workspaceId))
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const deleteWorkspace =
  (workspaceId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.deleteWorkspace(commonParams(getState), workspaceId)
      .then(() => {
        dispatch(requestSuccess())
        dispatch(getWorkspaceList())
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const csvImportTargets =
  (workspaceId: string, fileData: FormData): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    try {
      await API.uploadTargets(commonParams(getState), workspaceId, fileData)
      dispatch(requestSuccess())
    } catch (res) {
      const errorCode = makeErrorMessage(res as AxiosError)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    } finally {
      dispatch(Spinner.stop())
    }
  }

export const selectWorkspacesStatus = (state: RootState) => ({ ...state.workspaces })

export default workspacesSlice.reducer
