import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import { selectDashboardStatus } from 'slices/dashboardSlice'
import { getScheduleTypeList, getRelatedScheduleType, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { getDisplayFilter } from 'slices/usersSlice'
import { getWorkspaceList } from 'slices/workspacesSlice'

import { BadgeLabel, DropdownButton, MoveDropdown, NavMenu } from 'components/common'
import { connectionTypes } from 'components/common/utils'

import useDateQuery from 'hooks/useDateQuery'

import CSVManualRecordsInputDialog from './CSVManualRecordsInputDialog'
import DashboardDateChangeButton from './DashboardDateChangeButton'
import ManualInputDialog from './ManualInputDialog/ManualInputDialog'

import type { Moment } from 'moment'

const MANUAL_INPUT_AVAILABLE_PERIOD = 32

type Props = {
  onInterval: () => void
  onDateChange: (newDate: string) => void
  children: React.ReactNode
}

const Dashboard: React.FC<Props> = ({ children, onInterval, onDateChange }) => {
  const [openMoment, setOpenMoment] = React.useState<Moment>(moment())
  const { workspaceId } = useParams<'workspaceId'>()
  const [isOpenManualInputDialog, setIsOpenManualInputDialog] = React.useState(false)
  const [isOpenCSVManualRecordsInputDialog, setIsOpenCSVManualRecordsInputDialog] = React.useState(false)
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const date = useDateQuery()

  const { tenantSummary } = useSelector(selectDashboardStatus, shallowEqual)
  const { allScheduleTypes, partialScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)

  const isBopMonitoring = React.useMemo(() => pathname === '/dashboard/balance-of-payments', [pathname])

  const workspaceName = React.useMemo(
    () => tenantSummary?.workspaceData.find(data => data.workspaceId === Number(workspaceId))?.workspaceName || '',
    [tenantSummary?.workspaceData, workspaceId]
  )

  React.useEffect(() => {
    dispatch(getDisplayFilter())
  }, [dispatch])

  React.useEffect(() => {
    // レスポンスはManualInputDialogとCSVManualRecordsInputDialogでも使用
    dispatch(getWorkspaceList({ workDate: date }))
  }, [dispatch, date])

  React.useEffect(() => {
    // レスポンスはManualInputDialogとCSVManualRecordsInputDialogでも使用
    if (!workspaceId) {
      dispatch(getRelatedScheduleType(date))
      return
    }
    dispatch(getScheduleTypeList(Number(workspaceId), date))
  }, [dispatch, workspaceId, date])

  React.useEffect(() => {
    const timerId = setTimeout(
      () => {
        onInterval()
        setOpenMoment(moment())
      },
      15 * 60 * 1000
    )
    return () => clearTimeout(timerId)
  }, [dispatch, openMoment, date, onInterval])

  const showManualInput = React.useMemo(() => {
    if (isBopMonitoring) {
      return false
    }

    const existManualInput = workspaceId
      ? partialScheduleTypes.some(type => type.connectionType === connectionTypes.Manual)
      : allScheduleTypes.some(type => type.connectionType === connectionTypes.Manual)
    const today = moment().startOf('d')
    const diff = moment(today).diff(date, 'd')
    const inPeriod = diff >= 0 && diff < MANUAL_INPUT_AVAILABLE_PERIOD

    return existManualInput && inPeriod
  }, [isBopMonitoring, date, allScheduleTypes, partialScheduleTypes, workspaceId])

  const handleManualInputSuccess = () => {
    setIsOpenManualInputDialog(false)
    onInterval()
  }
  const handleCSVManualRecordsInputSuccess = () => {
    setIsOpenCSVManualRecordsInputDialog(false)
    onInterval()
  }

  const badgeLabel = React.useMemo(() => {
    if (isBopMonitoring) {
      return '収支モニタリング'
    }
    if (workspaceId) {
      return workspaceName
    }
    return '業務モニタリング'
  }, [isBopMonitoring, workspaceId, workspaceName])

  return (
    <NavMenu>
      <div>
        <div className="d-flex m-3">
          <div className="d-flex align-items-center">
            <div className="font-x-large fw-bold pe-2">ダッシュボード</div>
            <BadgeLabel label={badgeLabel} />
          </div>
          <div className="d-flex ms-auto">
            {workspaceId && <MoveDropdown className="me-2" />}
            {showManualInput && (
              <DropdownButton
                buttonLabel="実績入力"
                onClickButton={() => setIsOpenManualInputDialog(true)}
                dropdownItems={[
                  { label: '実績CSV一括入力', onClick: () => setIsOpenCSVManualRecordsInputDialog(true) },
                ]}
                className="me-2"
              />
            )}
            <DashboardDateChangeButton onChange={onDateChange} />
          </div>
        </div>
        <div className="mt-3 mx-3">{children}</div>
        <ManualInputDialog
          isOpen={isOpenManualInputDialog}
          onCancel={() => setIsOpenManualInputDialog(false)}
          onSuccess={handleManualInputSuccess}
        />
        <CSVManualRecordsInputDialog
          isOpen={isOpenCSVManualRecordsInputDialog}
          onCancel={() => setIsOpenCSVManualRecordsInputDialog(false)}
          date={date}
          onSuccess={handleCSVManualRecordsInputSuccess}
        />
      </div>
    </NavMenu>
  )
}

export default Dashboard
