import _ from 'lodash'
import moment from 'moment/moment'
import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'

import type { BopExportDataType } from 'api/bop_reports'

import { resetExportDataUrl, getBopExportDataUrl, selectBopReportsStatus } from 'slices/bopReportsSlice'
import { showError } from 'slices/notificationSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import { CustomModal, SingleDateRangePicker, ItemEdit } from 'components/common'
import type { SuggestionItem } from 'components/common/types'
import { downloadByURL } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'

type Props = {
  open: boolean
  setOpen: (prop: boolean) => void
  selectedWorkspaceIds: number[]
  exportDataType: BopExportDataType
}

const DATE_PICKER_MAX_DAYS = 31

const CsvExportDialog: React.FC<Props> = ({ open, setOpen, selectedWorkspaceIds, exportDataType }) => {
  const [openRangeDatePicker, setOpenRangeDatePicker] = React.useState(false)
  const [selectedWorkspaces, setSelectedWorkspaces] = React.useState<SuggestionItem[]>([])
  const [period, setPeriod] = React.useState<{ start: Date; end: Date }>()
  const dispatch = useDispatch()
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { downloadUrl } = useSelector(selectBopReportsStatus, shallowEqual)
  const { getWorkDate } = useBusinessTime()

  const initExportSettings = React.useCallback(() => {
    if (_.isEmpty(selectedWorkspaceIds)) {
      return
    }

    const targetWorkspaces = partialWorkspaces
      .filter(workspace => selectedWorkspaceIds.includes(workspace.id))
      .map(target => ({ id: target.id, value: target.name }))
    setSelectedWorkspaces(targetWorkspaces)
    setPeriod(undefined)
  }, [partialWorkspaces, selectedWorkspaceIds])

  // ダイアログを開くたびに、設定を初期化する
  React.useEffect(() => {
    initExportSettings()
  }, [initExportSettings, open])

  const onApprove = () => {
    if (!period || _.isEmpty(selectedWorkspaces)) {
      return
    }
    const targetWorkspaces: number[] = selectedWorkspaces.map(w => (_.isNumber(w.id) ? w.id : Number(w.id)))

    dispatch(
      getBopExportDataUrl({
        startDate: moment(period.start).format('YYYY-MM-DD'),
        endDate: moment(period.end).format('YYYY-MM-DD'),
        targetWorkspaces,
        exportDataType,
      })
    )
  }

  const downloadCsv = React.useCallback(
    async (url: string, filename: string) => {
      try {
        await downloadByURL(url, filename)
      } catch {
        dispatch(showError())
      } finally {
        setOpen(false)
        dispatch(resetExportDataUrl())
      }
    },
    [setOpen, dispatch]
  )

  React.useEffect(() => {
    if (!downloadUrl) {
      return
    }

    const filename = `収支レポート-${moment().format('YYYY-MM-DD-HHmm')}.csv`
    downloadCsv(downloadUrl, filename)
  }, [downloadUrl, downloadCsv])

  const disabled = React.useMemo(() => !period || _.isEmpty(selectedWorkspaces), [period, selectedWorkspaces])

  const items = React.useMemo(() => partialWorkspaces.map(w => ({ id: w.id, value: w.name })), [partialWorkspaces])

  return (
    <div>
      <CustomModal
        isOpen={open}
        title="CSVエクスポート"
        onCancel={() => setOpen(false)}
        onHideNotification={() => {}}
        approveLabel="CSVエクスポート"
        onApprove={onApprove}
        approveDisabled={disabled}
        submitName="csv-export-dialog-submit"
      >
        <div className="ms-2">
          <div className="mb-4">
            <div className="my-2 fw-bold">ワークスペース選択</div>
            <div className="mb-2">他ワークスペースもまとめてエクスポートできます｡ワークスペースを選択してください｡</div>
            <ItemEdit
              items={items}
              selectedItems={selectedWorkspaces}
              label="ワークスペースを追加"
              itemName="ワークスペース"
              onChange={setSelectedWorkspaces}
            />
          </div>
          <div className="mb-4">
            <div className="my-2 fw-bold">エクスポート期間設定</div>
            <div className="mb-2">最大31日分エクスポートが可能です｡</div>
            <div className="d-flex">
              <Button outline onClick={() => setOpenRangeDatePicker(true)}>
                {period
                  ? `${moment(period.start).format('YYYY/MM/DD')} - ${moment(period.end).format('YYYY/MM/DD')}`
                  : '開始日と終了日を決定'}
              </Button>
              <SingleDateRangePicker
                isOpen={openRangeDatePicker}
                from={period && period.start}
                to={period && period.end}
                maxRange={DATE_PICKER_MAX_DAYS}
                maxDate={moment(getWorkDate(moment().format('YYYY-MM-DD')))
                  .subtract(1, 'days')
                  .toDate()}
                onCancel={() => setOpenRangeDatePicker(false)}
                onChange={(start, end) => {
                  setPeriod({ start, end })
                  setOpenRangeDatePicker(false)
                }}
              />
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  )
}

export default CsvExportDialog
