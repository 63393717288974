import _ from 'lodash'
import * as React from 'react'
import { Button, Input, Label } from 'reactstrap'

export type FilterItem<T extends number | string = number> = {
  key: T
  label: string
  checked: boolean
}

type Props<T extends number | string> = {
  filterItems: FilterItem<T>[]
  onChange: (filterItems: FilterItem<T>[]) => void
}

export const CheckboxGroup = <T extends number | string>({ filterItems, onChange }: Props<T>) => {
  const allSelected = React.useMemo(() => filterItems.every(item => item.checked), [filterItems])

  const handleCheckboxChange = (index: number, checked: boolean) => {
    const newFilterItems = _.cloneDeep(filterItems)
    newFilterItems.splice(index, 1, { ...filterItems[index], checked })
    onChange(newFilterItems)
  }

  const handleButtonClick = () => {
    const newFilterItems = filterItems.map(item => ({ ...item, checked: !allSelected }))
    onChange(newFilterItems)
  }

  return (
    <div className="p-2">
      <Button className="mb-2" color="primary" size="sm" block outline onClick={handleButtonClick}>
        {allSelected ? 'すべて解除' : 'すべて選択'}
      </Button>
      {filterItems.map((filterItem, index) => (
        <div className="form-check mb-0" key={`checkboxGroup-${filterItem.key}-${filterItem.label}`}>
          <Input
            key={`checkboxGroup-${filterItem.key}-${filterItem.label}`}
            id={`checkboxGroup-${filterItem.key}-${filterItem.label}`}
            type="checkbox"
            checked={filterItem.checked}
            onChange={e => handleCheckboxChange(index, e.target.checked)}
            className="form-check-input text-break"
          />
          <Label className="form-check-label mb-0" for={`checkboxGroup-${filterItem.key}-${filterItem.label}`}>
            {filterItem.label}
          </Label>
        </div>
      ))}
    </div>
  )
}

export default CheckboxGroup
